/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";

import Button from "../../components/forms/Button";
import { useAuth } from "../../utils/Contexts/V2/AuthenticationContext";
import CheckedIcon from "../../assets/icons/buy_a_tappi_bundle.svg";
import displayPicHandler from "../../utils/displayPicNameHandler";
import useDebounce from "../../utils/useDebounce";
import {
  API_PATHS,
  searchAccessibleMerchantAccounts,
} from "../../utils/requests-v2";

export default function SelectLoginAccountPage() {
  const navigate = useNavigate();

  const {
    accessibleMerchantAccounts,
    getRefreshToken,
    switchAccount,
    isAuthenticated,
    logout,
  } = useAuth();

  const [filteredMerchants, setFilteredMerchants] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);

  useEffect(() => {
    if (
      accessibleMerchantAccounts &&
      accessibleMerchantAccounts?.items?.length > 0
    ) {
      setSelectedAccount(accessibleMerchantAccounts.items[0].id);
      setFilteredMerchants(accessibleMerchantAccounts);
    }
  }, [accessibleMerchantAccounts]);

  const { register, control } = useForm({
    defaultValues: {
      searchString: "",
    },
  });

  const businessName = useWatch({ control, name: "searchString" });

  const debouncedBusinessName = useDebounce(businessName, 1000);

  const queryParams = new URLSearchParams({
    limit: 100,
    offset: 0,
    business_name: debouncedBusinessName,
  });

  const { isFetching } = useQuery(
    [
      API_PATHS.searchAccessibleMerchantAccounts,
      queryParams.toString(),
      debouncedBusinessName,
    ],
    async () => {
      try {
        const merchants = await searchAccessibleMerchantAccounts(
          debouncedBusinessName,
          100,
          0
        );
        setFilteredMerchants(merchants.data);
        return merchants;
      } catch (error) {
        return null;
      }
    },
    {
      enabled: !!debouncedBusinessName,
    }
  );

  useEffect(() => {
    if (!debouncedBusinessName) {
      setFilteredMerchants(accessibleMerchantAccounts);
    }
  }, [debouncedBusinessName]);

  const loginHandler = async (merchantId) => {
    if (!isAuthenticated) {
      logout();
      navigate("/join");
    }
    const refreshToken = getRefreshToken();
    try {
      await switchAccount({ id: merchantId, token: refreshToken });
      navigate("/");
    } catch (error) {
      console.error(error);
      toast.error("Could not switch account : ", error);
    }
  };

  return (
    <div className="h-screen min-w-screen px-2">
      <fieldset disabled={isFetching} aria-disabled={isFetching}>
        <div className="flex flex-col h-[inherit] py-4 md:container md:mx-auto">
          <div className="headerSection px-2 py-4">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => navigate("/")}
              className="mr-4 cursor-pointer"
            >
              <path
                d="M7.828 11H20V13H7.828L13.192 18.364L11.778 19.778L4 12L11.778 4.22198L13.192 5.63598L7.828 11Z"
                fill="#1A1A1A"
              />
            </svg>
          </div>
          <div className="headings px-2 py-4 space-y-2">
            <h2>Welcome Back!</h2>
            <p>
              To access your account, please choose the profile you would like
              to proceed with.
            </p>
          </div>

          <div className="searchForm my-4">
            <form className="relative" onSubmit={(e) => e.preventDefault()}>
              <input
                type="text"
                placeholder="Search..."
                className="border border-[#C3B4DA] rounded-lg px-2 w-full"
                {...register("searchString")}
              />
              {isFetching && (
                <div
                  className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full text-primary-50 absolute top-4 right-2"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </form>
          </div>

          <div className="accountSearchResults overflow-y-auto overflow-x-hidden mb-4">
            {filteredMerchants && filteredMerchants.items?.length >= 1 ? (
              <div className="py-4 grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-6 justify-center">
                {filteredMerchants.items.map((account) => (
                  <button
                    type="button"
                    value={account.id}
                    key={account.id}
                    className={`rounded-lg px-2 py-2 flex flex-col space-y-4 ${
                      selectedAccount === account.id
                        ? "border-4 border-[#FFAD03]"
                        : "border-2 border-[#D9D9D9]"
                    }`}
                    onClick={() => setSelectedAccount(account.id)}
                  >
                    <div className="flex justify-end">
                      {/* <p>Radio Button {selectedAccount === account.id ? "Selected" : "Not selected"}</p> */}
                      <div className="flex w-full flex-row items-center justify-end">
                        {selectedAccount === account.id ? (
                          <img
                            src={CheckedIcon}
                            alt="checked"
                            className="h-6 w-6 rounded-full"
                          />
                        ) : (
                          <div className="h-6 w-6 rounded-full border-[1px] border-[#D9D9D9]" />
                        )}
                      </div>
                    </div>
                    <div className="flex justify-center w-full">
                      {account.cover_image_url || account.profile_photo_url ? (
                        <img
                          src={
                            account.cover_image_url || account.profile_photo_url
                          }
                          alt={account.business_name}
                          className="w-12 h-12 rounded-full object-cover"
                        />
                      ) : (
                        <div className="h-12 w-12 rounded-full bg-slate-200 flex flex-col items-center justify-center">
                          <p className="text-primary font-semibold">
                            {displayPicHandler(account.business_name.trim())}
                          </p>
                        </div>
                      )}
                    </div>
                    <p className="text-center text-sm font-semibold">
                      {account.business_name}
                    </p>
                  </button>
                ))}
              </div>
            ) : null}
          </div>
          <Button
            onClick={async (e) => {
              console.log(
                "Proceeding to attempt to log in merchant ",
                selectedAccount
              );
              e.preventDefault();
              await loginHandler(selectedAccount);
            }}
          >
            Proceed To Account
          </Button>
        </div>
      </fieldset>
    </div>
  );
}
