import React from "react";
import PropTypes from "prop-types";
import { Transition } from "@headlessui/react";
import Footer from "./Footer";
import Header from "./Header";
import ShareDialogue from "../../V2/ShareDialogue";

function Layout({
  children,
  header,
  headerTitle,
  wallet,
  showDialogueHandler,
  operation,
  clickedPhoto,
  show,
  backTo,
}) {
  return (
    <div className="relative z-0 w-screen flex flex-col justify-between items-center">
      <Transition
        className="fixed -bottom-0 left-0 z-20 w-full"
        show={show}
        enterFrom=" translate-y-0"
        enterTo=" -translate-y-12"
        leaveFrom=" -translate-y-12"
        leaveTo=" translate-y-0"
      >
        <ShareDialogue
          operation={operation}
          showDialogueHandler={showDialogueHandler}
          clickedPhoto={clickedPhoto}
        />
      </Transition>
      <div className="fixed z-10 top-0 left-0 right-0">
        <Header
          header={header}
          headerTitle={headerTitle}
          wallet={wallet}
          backTo={backTo}
        />
      </div>
      {children}
      {!header && (
        <div className="fixed z-10 bottom-0 left-0 right-0">
          <Footer />
        </div>
      )}
    </div>
  );
}

Layout.defaultProps = {
  header: false,
  headerTitle: "",
  wallet: false,
  clickedPhoto: null,
  showDialogueHandler: null,
  operation: null,
  show: false,
  backTo: -1,
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  header: PropTypes.bool,
  headerTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  wallet: PropTypes.bool,
  showDialogueHandler: PropTypes.func,
  operation: PropTypes.string,
  clickedPhoto: PropTypes.string,
  show: PropTypes.bool,
  backTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Layout;
