import React from "react";
import Layout from "../../../components/layout/Layout";
import KeyCap1 from "../../../assets/connect-facebook/emojione-monotone_keycap-1.svg";
import Step1 from "../../../assets/connect-facebook/step1.png";
import KeyCap2 from "../../../assets/connect-facebook/emojione-monotone_keycap-2.svg";
import Step2 from "../../../assets/connect-facebook/step2.png";
import KeyCap3 from "../../../assets/connect-facebook/emojione-monotone_keycap-3.svg";
import Step3 from "../../../assets/connect-facebook/step3.png";
import KeyCap4 from "../../../assets/connect-facebook/emojione-monotone_keycap-4.svg";
import Step41 from "../../../assets/connect-facebook/step4-1.png";
import Step42 from "../../../assets/connect-facebook/step4-2.png";
import KeyCap5 from "../../../assets/connect-facebook/emojione-monotone_keycap-5.svg";

export default function FindingFacebookId() {
  return (
    <Layout
      header
      headerTitle="Connect to Facebook"
      backTo="/account/connected-accounts"
    >
      <div className="relative h-screen pt-20 max-w-screen-xl container mx-auto flex flex-col justify-between items-center">
        <div className="flex flex-col justify-start items-center h-full w-full px-4 space-y-[2.31rem]">
          <p className="text-center text-[0.875rem] text-[#1A1A1A]">
            Connecting your Facebook Page to tappi is a breeze! Follow these
            simple steps to find your Page ID and elevate your online presence.
          </p>
          <div className="flex flex-col justify-start items-center space-y-[3rem] w-full text-[0.875rem] pb-24">
            <div className="flex flex-col justify-start items-center space-y-[0.75rem] w-full">
              <div className="flex flex-row justify-start items-center space-x-[0.25rem] w-full">
                <img src={KeyCap1} alt="step one" />
                <p className="text-[1rem] font-semibold">
                  Open the Facebook App
                </p>
              </div>
              <p>{`Log in to your Facebook account, click the "☰" icon and select "Pages."`}</p>
              <img src={Step1} alt="step one" className="w-full" />
            </div>
            <div className="flex flex-col justify-start items-center space-y-[0.75rem] w-full">
              <div className="flex flex-row justify-start items-center space-x-[0.25rem] w-full">
                <img src={KeyCap2} alt="step two" />
                <p className="text-[1rem] font-semibold">Creating a new page</p>
              </div>
              <div>
                <p>{`If you already have a Facebook Page, move to step 4 but if you don't, follow this steps:`}</p>
                <ul className="list-disc pl-5">
                  <li>{`Click "Create"`}</li>
                  <li>Follow the prompts to set up your new Facebook Page.</li>
                </ul>
              </div>
              <img src={Step2} alt="step one" className="w-full" />
            </div>
            <div className="flex flex-col justify-start items-center space-y-[0.75rem] w-full">
              <div className="flex flex-row justify-start items-center space-x-[0.25rem] w-full">
                <img src={KeyCap3} alt="step three" />
                <p className="text-[1rem] font-semibold">
                  Navigate to Your Page
                </p>
              </div>
              <p>
                Once successful, click the profile on the top left of your page.
              </p>
              <img src={Step3} alt="step three" className="w-full" />
            </div>
            <div className="flex flex-col justify-start items-center space-y-[0.75rem] w-full">
              <div className="flex flex-row justify-start items-center space-x-[0.25rem] w-full">
                <img src={KeyCap4} alt="step four" />
                <p className="text-[1rem] font-semibold">
                  Finding Your Page ID
                </p>
              </div>
              <div>
                <p>From the selected Page:</p>
                <ul className="list-disc pl-5">
                  <li>{`Click the “About” tab below your Page's cover photo.`}</li>
                  <li>{`Then click "See your About info"`}</li>
                </ul>
              </div>
              <img src={Step41} alt="step four-1" className="w-full" />
              <p>
                Scroll down to “page transparency”, there you will find your
                unique 15-digit page ID. See a sample below.
              </p>
              <img src={Step42} alt="step four-2" className="w-full" />
            </div>
            <div className="flex flex-col justify-start items-center space-y-[0.75rem] w-full">
              <div className="flex flex-row justify-start items-center space-x-[0.25rem] w-full">
                <img src={KeyCap5} alt="step three" />
                <p className="text-[1rem] font-semibold">
                  Submit Your Page ID to tappi
                </p>
              </div>
              <div>
                <p>{`Now that you have your Facebook Page and Page ID, it's time to connect:`}</p>
                <ul className="list-disc pl-5">
                  <li>{`Go back to the "Connected Accounts" section.`}</li>
                  <li>{`Select "Connect Facebook Page" and enter your Facebook Page ID.`}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
