import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import Layout from "../../../components/layout/Layout";
import { useConnectAccount } from "../../../utils/Contexts/V2/ConnectAccountContext";

export default function ConfigureConnection() {
  const navigate = useNavigate();
  const { isLoadingAccounts, connectedAccounts } = useConnectAccount();
  return (
    <Layout header headerTitle="Connected Accounts" backTo="/account">
      {isLoadingAccounts ? (
        <div className="h-screen w-screen flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className="relative h-screen pt-20 max-w-screen-xl container mx-auto flex flex-col justify-between items-center">
          <div className="flex flex-col space-y-[1.5rem] items-center w-full px-4">
            <p className="text-[0.875rem] text-[#1A1A1A] text-center">
              Tap on any of the page(s) below to disconnect it from your tappi
              account.
            </p>
            <div className="flex flex-col justify-start items-start space-y-[1rem] w-full">
              <p className="text-[#1A1A1A] text-[0.875rem] font-semibold">
                Connected page(s)
              </p>
              <div className="flex flex-col justify-start items-start space-y-[1rem] w-full">
                {connectedAccounts?.map((account) => (
                  <ConnectionCard account={account} key={account?.id} />
                ))}
              </div>
            </div>
          </div>
          <div className="w-full px-4">
            <button
              onClick={() => navigate("/account/connected-accounts/facebook")}
              type="button"
              className="w-full h-12 text-white thryve:text-base text-base font-semibold rounded-xl bg-primary-50 mb-5"
            >
              Add new Facebook page
            </button>
          </div>
        </div>
      )}
    </Layout>
  );
}

function ConnectionCard({ account }) {
  return (
    <div className="w-full flex flex-row justify-between items-center rounded-[0.75rem] shadow-md py-[1.13rem] px-[1rem]">
      <div className="flex flex-row space-x-[0.5rem] items-center justify-start">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <g clipPath="url(#clip0_9100_14409)">
            <path
              d="M20 10C20 4.47719 15.5228 0 10 0C4.47719 0 0 4.47711 0 10C0 14.9913 3.65687 19.1284 8.4375 19.8785V12.8906H5.89844V10H8.4375V7.79688C8.4375 5.29063 9.93047 3.90625 12.2146 3.90625C13.3088 3.90625 14.4531 4.10156 14.4531 4.10156V6.5625H13.1922C11.9498 6.5625 11.5625 7.33336 11.5625 8.12422V10H14.3359L13.8926 12.8906H11.5625V19.8785C16.3431 19.1284 20 14.9913 20 10Z"
              fill="#1877F2"
            />
            <path
              d="M13.8926 12.8906L14.3359 10H11.5625V8.12422C11.5625 7.33328 11.9499 6.5625 13.1922 6.5625H14.4531V4.10156C14.4531 4.10156 13.3088 3.90625 12.2146 3.90625C9.93047 3.90625 8.4375 5.29063 8.4375 7.79688V10H5.89844V12.8906H8.4375V19.8785C8.95439 19.9595 9.4768 20.0001 10 20C10.5232 20.0002 11.0456 19.9595 11.5625 19.8785V12.8906H13.8926Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_9100_14409">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <p>{account?.name || "xxxxx"}</p>
      </div>
      {account?.partner_approval_status === "PARTNER_APPROVAL_PENDING" && (
        <p className="text-[#E5AE40] font-semibold text-[0.75rem]">
          Pending removal
        </p>
      )}
    </div>
  );
}

ConnectionCard.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    fb_page_id: PropTypes.string,
    page_category: PropTypes.string,
    merchant_id: PropTypes.string,
    fb_page_link: PropTypes.string,
    partner_approval_status: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
};
