import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import Button from "../forms/Button";
import { useOffer } from "../../utils/Contexts/V2/OfferContext";
import { useWalletContext } from "../../utils/Contexts/V2/WalletContext";
import OfferOptions from "./offersOptions";
import DeleteConfirmationPrompt from "./DeleteConfirmationPrompt";
import BulkOfferTopUp from "../../pages/V2/offer/BulkOfferTopUp";
import OfferDetails from "./OfferDetails";

export default function OffersTab() {
  const { offers, deleteOfferHandler, isDeletingOffer, offersContacts } =
    useOffer();
  const { walletDetails } = useWalletContext();
  const [showOfferOption, setShowOfferOption] = useState(false);
  const [selectedOfferId, setSelectedOfferId] = useState(null);
  const [showBuySMSBulkPrompt, setShowBuySMSBulkPrompt] = useState(false);
  const [showDeleteConfirmationPrompt, setShowDeleteConfirmationPrompt] =
    useState(false);

  const showOfferOptionHandler = (id) => {
    setSelectedOfferId(id);
    setShowOfferOption(true);
  };

  const deleteOffer = () => {
    deleteOfferHandler(selectedOfferId, setShowDeleteConfirmationPrompt);
    setShowOfferOption(false);
  };

  return (
    <div className="relative min-h-full">
      <Transition
        className="fixed -bottom-12 left-0 z-20 w-full"
        show={showOfferOption}
        enterFrom=" translate-y-0"
        enterTo=" -translate-y-12"
        leaveFrom=" -translate-y-12"
        leaveTo=" translate-y-0"
      >
        <OfferOptions
          offerId={selectedOfferId}
          setShowOfferOption={setShowOfferOption}
          setShowDeleteConfirmationPrompt={setShowDeleteConfirmationPrompt}
        />
      </Transition>
      <Transition
        className="fixed -bottom-12 left-0 z-20 w-full"
        show={showDeleteConfirmationPrompt}
        enterFrom=" translate-y-0"
        enterTo=" -translate-y-12"
        leaveFrom=" -translate-y-12"
        leaveTo=" translate-y-0"
      >
        <DeleteConfirmationPrompt
          action={deleteOffer}
          text="You are about to remove this offer"
          closePrompt={setShowDeleteConfirmationPrompt}
          isDeleting={isDeletingOffer}
        />
      </Transition>
      <Transition
        className="fixed -bottom-12 left-0 z-20 w-full"
        show={showBuySMSBulkPrompt}
        enterFrom=" translate-y-0"
        enterTo=" -translate-y-12"
        leaveFrom=" -translate-y-12"
        leaveTo=" translate-y-0"
      >
        <BulkOfferTopUp setShowPrompt={setShowBuySMSBulkPrompt} />
      </Transition>
      {offers?.length > 0 ? (
        <div className="flex flex-col justify-start items-center space-y-3 w-full min-h-full pb-20">
          <div className="py-2.5 px-[4.2rem] bg-[#EEF1F2] w-full">
            <h1 className="text-sm font-normal">
              You have{" "}
              <span className="text-[#002366] font-bold">
                {walletDetails?.bulk_sms_count}
              </span>{" "}
              Bulk SMS left.{" "}
              <button
                type="button"
                onClick={() => setShowBuySMSBulkPrompt(true)}
                className="text-[#002366] font-bold"
              >
                Buy more
              </button>
            </h1>
          </div>
          <div className="flex flex-col justify-start items-start w-full px-4 space-y-4">
            <OfferDetails
              offers={offers}
              offersContacts={offersContacts}
              showOfferOptionHandler={showOfferOptionHandler}
            />
          </div>
        </div>
      ) : (
        <div className="relative pt-[7.38rem] px-8 flex flex-col items-center space-y-8">
          <svg
            width="189"
            height="104"
            viewBox="0 0 189 104"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M188.133 101.26H0.866943V101.735H188.133V101.26Z"
              fill="#DBD8D4"
            />
            <path
              d="M139.903 11.4725L130.609 16.8486L135.975 26.1604L145.269 20.7842L139.903 11.4725Z"
              fill="#EBA12A"
            />
            <path
              d="M140.023 28.1005L134.42 18.3773L144.125 12.7637L149.728 22.487L140.023 28.1005ZM135.067 18.5512L140.197 27.4516L149.08 22.3131L143.951 13.4126L135.067 18.5512Z"
              fill="#9F9C98"
            />
            <path
              d="M131.328 54.2288C133.815 54.2288 135.832 52.2085 135.832 49.7164C135.832 47.2243 133.815 45.2041 131.328 45.2041C128.84 45.2041 126.824 47.2243 126.824 49.7164C126.824 52.2085 128.84 54.2288 131.328 54.2288Z"
              fill="#45B0EC"
            />
            <path
              d="M134.647 54.4664C133.709 54.4664 132.793 54.1879 132.013 53.666C131.233 53.144 130.626 52.4022 130.267 51.5343C129.908 50.6664 129.814 49.7114 129.997 48.79C130.18 47.8686 130.632 47.0223 131.295 46.358C131.958 45.6937 132.802 45.2413 133.722 45.0581C134.642 44.8748 135.595 44.9689 136.461 45.3284C137.327 45.6879 138.068 46.2967 138.589 47.0778C139.11 47.8589 139.388 48.7772 139.388 49.7166C139.388 50.9764 138.888 52.1845 137.999 53.0753C137.11 53.966 135.904 54.4664 134.647 54.4664ZM134.647 45.4418C133.803 45.4418 132.978 45.6925 132.276 46.1622C131.575 46.632 131.028 47.2996 130.705 48.0807C130.382 48.8618 130.297 49.7214 130.462 50.5506C130.627 51.3798 131.033 52.1415 131.63 52.7394C132.227 53.3372 132.987 53.7444 133.815 53.9093C134.642 54.0743 135.5 53.9896 136.28 53.6661C137.059 53.3425 137.726 52.7946 138.195 52.0916C138.664 51.3886 138.914 50.5621 138.914 49.7166C138.914 48.5829 138.464 47.4955 137.664 46.6939C136.864 45.8922 135.779 45.4418 134.647 45.4418Z"
              fill="#9F9C98"
            />
            <path
              d="M159.74 102.34H116.124C115.59 102.339 115.078 102.126 114.7 101.748C114.322 101.369 114.11 100.856 114.109 100.321V59.7101C114.11 59.1749 114.322 58.6618 114.7 58.2833C115.078 57.9049 115.59 57.692 116.124 57.6914H159.74C160.275 57.692 160.787 57.9049 161.165 58.2833C161.542 58.6618 161.755 59.1749 161.755 59.7101V100.321C161.755 100.856 161.542 101.369 161.165 101.748C160.787 102.126 160.275 102.339 159.74 102.34Z"
              fill="#D0D5DD"
            />
            <path
              d="M141.014 103.766H134.851C134.316 103.765 133.804 103.552 133.427 103.174C133.049 102.796 132.836 102.283 132.836 101.747V58.504C132.836 57.9688 133.049 57.4557 133.427 57.0773C133.804 56.6988 134.316 56.486 134.851 56.4854H141.014C141.548 56.486 142.06 56.6988 142.438 57.0773C142.816 57.4557 143.028 57.9688 143.029 58.504V101.747C143.028 102.283 142.816 102.796 142.438 103.174C142.06 103.552 141.548 103.765 141.014 103.766Z"
              fill="url(#paint0_linear_7836_15272)"
            />
            <path
              d="M171.328 53.6718C171.073 57.8612 165.425 60.9252 165.425 60.9252C165.425 60.9252 160.189 57.1967 160.444 53.0073C160.699 48.8179 166.348 45.7539 166.348 45.7539C166.348 45.7539 171.583 49.4824 171.328 53.6718Z"
              fill="url(#paint1_linear_7836_15272)"
            />
            <path
              d="M171.584 66.7426C167.402 66.5004 164.326 60.8509 164.326 60.8509C164.326 60.8509 168.031 55.594 172.214 55.8362C176.396 56.0785 179.472 61.728 179.472 61.728C179.472 61.728 175.767 66.9848 171.584 66.7426Z"
              fill="url(#paint2_linear_7836_15272)"
            />
            <path
              opacity="0.1"
              d="M161.637 62.6601V78.1635L146.246 57.8105H157.97L161.637 62.6601Z"
              fill="black"
            />
            <path
              d="M174.702 89.1978C174.391 89.1984 174.083 89.1266 173.804 88.988C173.525 88.8494 173.282 88.6478 173.094 88.399L141.028 45.9972C140.868 45.7859 140.751 45.5451 140.684 45.2886C140.617 45.0322 140.602 44.765 140.638 44.5024C140.674 44.2398 140.761 43.9869 140.895 43.7581C141.029 43.5293 141.206 43.3292 141.417 43.1691L146.141 39.5835C146.351 39.4234 146.592 39.3065 146.848 39.2394C147.104 39.1724 147.37 39.1565 147.633 39.1928C147.895 39.229 148.147 39.3166 148.375 39.4506C148.604 39.5846 148.803 39.7623 148.963 39.9736L181.029 82.3753C181.189 82.5866 181.306 82.8273 181.373 83.0838C181.44 83.3403 181.456 83.6074 181.419 83.8701C181.383 84.1327 181.296 84.3856 181.162 84.6143C181.029 84.8431 180.851 85.0433 180.64 85.2034L175.917 88.7889C175.567 89.0547 175.141 89.1983 174.702 89.1978Z"
              fill="#D0D5DD"
            />
            <path
              d="M161.868 56.2499L153.744 62.417L159.899 70.5566L168.024 64.3895L161.868 56.2499Z"
              fill="url(#paint3_linear_7836_15272)"
            />
            <path
              d="M120.074 25.2323L117.297 35.6182L127.663 38.4011L130.441 28.0152L120.074 25.2323Z"
              fill="#D73C6A"
            />
            <path
              d="M123.265 39.9537L112.441 37.0479L115.342 26.2031L126.166 29.1089L123.265 39.9537ZM113.022 36.7121L122.93 39.372L125.585 29.4447L115.677 26.7848L113.022 36.7121Z"
              fill="#9F9C98"
            />
            <path
              d="M77.6693 65.1607C77.9541 64.8874 78.1756 64.5547 78.3183 64.1863C78.4609 63.8179 78.5212 63.4226 78.4949 63.0282C78.4686 62.6339 78.3562 62.2502 78.1659 61.9041C77.9755 61.558 77.7117 61.2579 77.3931 61.025L73.04 25.8691L67.4158 27.5446L73.4876 61.7572C73.1326 62.3194 72.9957 62.9926 73.1029 63.6493C73.2101 64.3059 73.5541 64.9003 74.0695 65.3198C74.585 65.7393 75.2361 65.9549 75.8996 65.9255C76.563 65.8962 77.1927 65.6241 77.6693 65.1607Z"
              fill="#AF6544"
            />
            <path
              d="M82.8826 98.7635H86.172L87.7377 86.0518H82.8826V98.7635Z"
              fill="#AF6544"
            />
            <path
              d="M92.5173 101.823H82.179V97.8214H88.8884C89.8508 97.8214 90.7739 98.2044 91.4544 98.8863C92.135 99.5681 92.5173 100.493 92.5173 101.457V101.823Z"
              fill="#1A1A1A"
            />
            <path
              d="M60.1365 93.6303L63.2197 94.7793L69.1103 83.4118L64.56 81.7158L60.1365 93.6303Z"
              fill="#AF6544"
            />
            <path
              d="M68.0994 99.8633L58.4096 96.2522L59.8022 92.5014L66.0906 94.8449C66.9927 95.1811 67.7246 95.8625 68.1252 96.7393C68.5258 97.6161 68.5623 98.6164 68.2268 99.5202L68.0994 99.8633L68.0994 99.8633Z"
              fill="#1A1A1A"
            />
            <path
              d="M76.9832 16.7432C80.4183 16.7432 83.203 13.9533 83.203 10.5118C83.203 7.0702 80.4183 4.28027 76.9832 4.28027C73.5481 4.28027 70.7634 7.0702 70.7634 10.5118C70.7634 13.9533 73.5481 16.7432 76.9832 16.7432Z"
              fill="#AF6544"
            />
            <path
              d="M67.2535 26.5041C67.0587 20.7643 73.0798 16.7825 78.1458 19.4699C78.7912 19.8123 85.4557 25.485 85.7069 29.0078C85.958 32.5306 86.4603 38.3181 86.4603 38.3181L93.4928 90.9087L78.4233 90.1539L80.2432 74.8124L70.448 90.4134L58.5818 86.631L73.149 44.6089C73.149 44.6089 67.5486 35.2024 67.2535 26.5041Z"
              fill="#163348"
            />
            <path
              d="M67.3724 28.5026L68.3401 41.1059C68.3502 41.2375 68.4095 41.3605 68.5062 41.4501C68.6028 41.5398 68.7297 41.5896 68.8615 41.5896H73.1192C73.2052 41.5896 73.29 41.5684 73.3658 41.5277C73.4417 41.4871 73.5064 41.4283 73.5542 41.3566C73.6019 41.2849 73.6313 41.2025 73.6397 41.1167C73.648 41.0309 73.6351 40.9444 73.6021 40.8647L68.3767 28.2614C68.329 28.1463 68.2419 28.0521 68.1311 27.9955C68.0202 27.939 67.8929 27.9239 67.7719 27.9529C67.651 27.982 67.5443 28.0532 67.471 28.154C67.3978 28.2547 67.3628 28.3783 67.3724 28.5026Z"
              fill="#FFCB05"
            />
            <path
              d="M89.2369 62.9768C89.4879 62.672 89.669 62.3156 89.7676 61.933C89.8663 61.5504 89.88 61.1507 89.8078 60.7622C89.7356 60.3737 89.5792 60.0058 89.3497 59.6844C89.1202 59.363 88.8232 59.0959 88.4795 58.9019L80.0498 24.498L74.6599 26.8214L84.6864 60.087C84.3995 60.687 84.3422 61.3716 84.5254 62.0112C84.7086 62.6507 85.1196 63.2007 85.6805 63.5569C86.2414 63.9131 86.9132 64.0508 87.5687 63.9439C88.2242 63.837 88.8177 63.4929 89.2369 62.9768Z"
              fill="#AF6544"
            />
            <path
              d="M75.41 21.4593C75.41 21.4593 73.6519 24.9821 75.41 31.2729C77.1682 37.5636 78.424 42.8479 78.424 42.8479C78.424 42.8479 82.4425 40.3316 84.2006 41.0864C85.9587 41.8413 81.4195 24.317 81.4195 24.317C81.4195 24.317 78.6751 18.4398 75.41 21.4593Z"
              fill="#FFCB05"
            />
            <path
              d="M78.4408 11.4839C78.5249 10.1909 80.153 10.1466 81.4463 10.1343C82.7396 10.1221 84.2857 10.1683 85.0175 9.09996C85.5011 8.3939 85.4087 7.41369 85.0092 6.65669C84.6097 5.8997 83.9525 5.31749 83.306 4.75738C81.6369 3.31134 79.9166 1.87677 77.9127 0.951208C75.9087 0.0256446 73.5604 -0.348343 71.4821 0.394647C68.9291 1.30734 65.4779 6.00558 65.1799 8.70487C64.8819 11.4042 65.9586 14.1559 67.7736 16.173C69.5887 18.1901 73.7429 17.3762 76.3572 18.0938C74.7679 15.8401 75.8155 11.7451 78.3616 10.6926L78.4408 11.4839Z"
              fill="#272729"
            />
            <path
              d="M30.4804 90.9387C33.0259 90.9387 35.0894 88.8713 35.0894 86.321C35.0894 83.7708 33.0259 81.7034 30.4804 81.7034C27.9349 81.7034 25.8713 83.7708 25.8713 86.321C25.8713 88.8713 27.9349 90.9387 30.4804 90.9387Z"
              fill="#039855"
            />
            <path
              d="M29.2355 93.0641L30.3692 93.7679V89.0101L29.0984 88.2211C28.9933 88.1559 28.9166 88.0535 28.8833 87.9342C29.0047 87.911 29.1304 87.9343 29.2355 87.9995L30.3692 88.7034V86.8584C30.3692 86.5015 30.4129 86.1459 30.4993 85.7997C30.5857 86.1459 30.6294 86.5015 30.6294 86.8584V89.8698L32.302 88.8314C32.4356 88.7484 32.5901 88.7057 32.7472 88.7083C32.6796 88.8504 32.5726 88.97 32.4391 89.0529L30.6294 90.1764V101.428H30.3692V94.0746L29.0984 93.2857C28.9933 93.2204 28.9166 93.118 28.8833 92.9988C29.0047 92.9755 29.1304 92.9989 29.2355 93.0641Z"
              fill="#DBD8D4"
            />
            <path
              d="M23.6537 91.3309C26.1992 91.3309 28.2627 89.2635 28.2627 86.7133C28.2627 84.163 26.1992 82.0956 23.6537 82.0956C21.1082 82.0956 19.0447 84.163 19.0447 86.7133C19.0447 89.2635 21.1082 91.3309 23.6537 91.3309Z"
              fill="#039855"
            />
            <path
              d="M24.8987 93.4564L23.7649 94.1603V89.4024L25.0357 88.6134C25.1408 88.5482 25.2176 88.4458 25.2508 88.3266C25.1294 88.3033 25.0038 88.3266 24.8987 88.3919L23.7649 89.0957V87.2508C23.7649 86.8938 23.7212 86.5383 23.6348 86.192C23.5484 86.5383 23.5047 86.8938 23.5047 87.2508V90.2621L21.8321 89.2237C21.6986 89.1408 21.5441 89.0981 21.387 89.1006C21.4546 89.2427 21.5615 89.3624 21.6951 89.4453L23.5048 90.5688V101.454H23.7649V94.467L25.0357 93.678C25.1408 93.6128 25.2176 93.5104 25.2508 93.3911C25.1294 93.3679 25.0038 93.3912 24.8987 93.4564Z"
              fill="#DBD8D4"
            />
            <path
              d="M27.0022 85.025C31.3849 85.025 34.9378 81.4654 34.9378 77.0745C34.9378 72.6836 31.3849 69.124 27.0022 69.124C22.6195 69.124 19.0667 72.6836 19.0667 77.0745C19.0667 81.4654 22.6195 85.025 27.0022 85.025Z"
              fill="#039855"
            />
            <path
              d="M29.1452 88.6842L27.1932 89.8961V81.7042L29.3812 80.3458C29.5621 80.2335 29.6942 80.0572 29.7515 79.8519L29.7515 79.8519H29.7515C29.5425 79.8119 29.3261 79.852 29.1452 79.9643L27.1932 81.1762V77.9997C27.1932 77.3851 27.1179 76.7729 26.9692 76.1768C26.8204 76.7729 26.7452 77.3851 26.7452 77.9997V83.1844L23.8654 81.3965C23.6354 81.2538 23.3694 81.1802 23.0989 81.1847C23.2153 81.4293 23.3995 81.6353 23.6294 81.778L26.7452 83.7124V101.471H27.1932V90.4241L29.3812 89.0657C29.5621 88.9534 29.6942 88.7771 29.7515 88.5718L29.7515 88.5718H29.7515C29.5425 88.5318 29.3261 88.5719 29.1452 88.6842Z"
              fill="#DBD8D4"
            />
            <defs>
              <linearGradient
                id="paint0_linear_7836_15272"
                x1="132.836"
                y1="80.1258"
                x2="143.029"
                y2="80.1258"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FFAD03" />
                <stop offset="0.65098" stopColor="#FE8005" />
                <stop offset="1" stopColor="#FD5308" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_7836_15272"
                x1="160.435"
                y1="53.3396"
                x2="171.337"
                y2="53.3396"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FFAD03" />
                <stop offset="0.65098" stopColor="#FE8005" />
                <stop offset="1" stopColor="#FD5308" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_7836_15272"
                x1="164.326"
                y1="61.2895"
                x2="179.472"
                y2="61.2895"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FFAD03" />
                <stop offset="0.65098" stopColor="#FE8005" />
                <stop offset="1" stopColor="#FD5308" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_7836_15272"
                x1="156.822"
                y1="66.4868"
                x2="164.957"
                y2="60.3343"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FFAD03" />
                <stop offset="0.65098" stopColor="#FE8005" />
                <stop offset="1" stopColor="#FD5308" />
              </linearGradient>
            </defs>
          </svg>

          <div className="flex flex-col jusitfy-start items-center space-y-3">
            <p className="font-semibold text-base text-center">Create offers</p>
            <p className="text-sm text-center">
              Encourage your customers to buy when you create a special offer.
            </p>
            <Button
              className="text-sm text-center font-bold flex justify-center items-center"
              href="/create-offers"
            >
              Encourage customers to buy
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
