import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Transition } from "@headlessui/react";
import Button from "../../../components/forms/Button";
import Layout from "../../../components/layout/V2/Layout";
import { useContact } from "../../../utils/Contexts/V2/ContactContext";
import { useOffer } from "../../../utils/Contexts/V2/OfferContext";

export default function SelectCustomer() {
  const { contacts } = useContact();
  const {
    selectedCustomersForOffer,
    selectedCustomersForOfferHandler,
    setSelectedCustomersForOffer,
    selectedAllCustomersForOfferHandler,
  } = useOffer();
  const { id } = useParams();
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [searchedContact, setSearchedContact] = useState(contacts);
  const displayPicHandler = (name) => {
    const abbreviatedName = name.split(" ");
    if (abbreviatedName.length >= 2) {
      return `${Array.from(abbreviatedName[0])[0]?.toUpperCase()}`;
    }
    return `${Array.from(abbreviatedName[0])[0]?.toUpperCase()}`;
  };

  useEffect(() => {
    setSelectedCustomersForOffer([]);
  }, []);

  const continueButtonHandler = () => {
    if (selectedCustomersForOffer.length > 0) {
      setShowError(false);
      navigate(`/create-offers/review-offer/${id}`);
    } else {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 3000);
    }
  };

  return (
    <Layout headerTitle="Create Offers" header>
      <div className="relative w-full h-screen scroll-smooth pt-20 flex flex-col items-center justify-between">
        <Transition
          className="fixed left-0 right-0 -bottom-12 z-20 w-full flex justify-center items-center shadow-xl mb-4"
          show={showError}
          enterFrom=" translate-y-0"
          enterTo=" -translate-y-12"
          leaveFrom=" -translate-y-12"
          leaveTo=" translate-y-0"
        >
          <p className="py-2.5 px-4 text-red-50 text-sm font-bold w-64 text-center bg-white rounded-[6.25rem] mt-72">
            Please select at least one contact
          </p>
        </Transition>
        <div className="px-4 flex flex-col justify-center items-center space-y-4">
          <div className="relative w-[15.56rem] h-1 rounded-lg bg-primary-20">
            <div className="w-[50%] bg-primary-50 h-1 rounded-lg" />
          </div>
          <p className="px-7 font-normal text-base font-sm text-center">
            Who would you like to send this offer to?
          </p>
        </div>
        <div className="w-full px-4 pt-4">
          <div className="flex flex-row justify-between items-center border border-solid rounded-lg px-3 py-1">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.3335 0.333252C9.6455 0.333252 12.3335 3.02125 12.3335 6.33325C12.3335 9.64525 9.6455 12.3333 6.3335 12.3333C3.0215 12.3333 0.333496 9.64525 0.333496 6.33325C0.333496 3.02125 3.0215 0.333252 6.3335 0.333252ZM6.3335 10.9999C8.9115 10.9999 11.0002 8.91125 11.0002 6.33325C11.0002 3.75459 8.9115 1.66659 6.3335 1.66659C3.75483 1.66659 1.66683 3.75459 1.66683 6.33325C1.66683 8.91125 3.75483 10.9999 6.3335 10.9999ZM11.9902 11.0473L13.8762 12.9326L12.9328 13.8759L11.0475 11.9899L11.9902 11.0473Z"
                fill="#808A93"
              />
            </svg>
            <input
              className="h-full w-[87%] py-2 text-xs outline-none "
              placeholder="Search"
              onChange={(e) =>
                setSearchedContact(
                  contacts?.filter((c) => c.full_name.includes(e.target.value))
                )
              }
            />
          </div>{" "}
        </div>
        <div className="flex flex-row justify-between items-center w-full px-6 py-2">
          <p className="font-normal text-xs">
            {selectedCustomersForOffer?.length} / {contacts?.length} selected
          </p>
          <div className="flex flex-row space-x-2 items-center">
            <p className="font-normal text-xs text-[#566370]">Select all</p>
            <input
              className="form-check-input appearance-none rounded-full h-[1.4rem] w-[1.4rem] border border-gray-300 bg-white checked:text-primary-50 checked:border-white checked:outline-white transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="checkbox"
              name="flexRadioDefault"
              checked={selectedCustomersForOffer?.length === contacts?.length}
              onChange={(e) => selectedAllCustomersForOfferHandler(e, contacts)}
            />
          </div>
        </div>
        <div className="w-full h-full flex flex-col justify-start pt-[0.69rem] px-4 h-[70%] overflow-y-scroll">
          {searchedContact?.map((item) => (
            <div
              className="w-full flex flex-row justify-between items-center py-2 border-t border-b border-[#F8F6FB]"
              key={item.id}
            >
              <div className="flex flex-row items-center space-x-[0.69rem]">
                <p className="w-10 h-10 bg-[#D9D9D9] rounded-full p-4 flex justify-center items-center font-bold">
                  {displayPicHandler(item.full_name)}
                </p>
                <div className="flex flex-col justify-between items-start">
                  <p className="font-bold text-sm">{item.full_name}</p>
                  <p className="font-normal text-xs text-[#566370]">
                    {item.phone_number}
                  </p>
                </div>
              </div>
              <input
                className="form-check-input appearance-none rounded-full h-[1.4rem] w-[1.4rem] border border-gray-300 bg-white checked:text-primary-50 checked:border-white checked:outline-white transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                name="flexRadioDefault"
                checked={
                  !!selectedCustomersForOffer.find(
                    (c) => c.full_name === item.full_name
                  )
                }
                id={item.name}
                onChange={(e) => selectedCustomersForOfferHandler(e, item)}
              />
            </div>
          ))}
        </div>
        <div className="relative p-4 w-full shadow-[0_-2px_8px_rgba(0, 0, 0, 0.3)] border border-t-solid border-t-[#D1D9DD]">
          <Button
            className="font-semibold flex justify-center items-center"
            onClick={continueButtonHandler}
          >
            Continue
          </Button>
        </div>
      </div>
    </Layout>
  );
}
