/* eslint-disable camelcase */
import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { useAuth } from "../../utils/Contexts/V2/AuthenticationContext";
import { useAppContext } from "../../utils/Contexts/AppContext";
import resolveSubdomain from "../../utils/resolveSubdomain";
import { useRequestPaymentContext } from "../../utils/Contexts/V2/RequestPaymentContext";

function ShareDialogue({ operation, showDialogueHandler, clickedPhoto }) {
  const { user } = useAuth();
  const { appVendor } = useAppContext();
  const business_name = user?.business_name;
  const { paymentLink, contactDetails } = useRequestPaymentContext();
  const [showAlert, setShowAlert] = useState(false);

  const getImageId = () => {
    if (clickedPhoto) {
      const imageURLArray = clickedPhoto?.split("/");
      return imageURLArray[imageURLArray.length - 1];
    }
    return null;
  };

  const resolvedSubdomain = useCallback(
    () => resolveSubdomain(appVendor, user),
    [appVendor, user]
  );

  const SHARE_WEBPAGE = `Hello! Visit ${business_name} website to find out more about my business. You can call or chat with us on WhatsApp as well as leave a review so we can serve you better. ${resolvedSubdomain()}`;
  const REQUEST_REVIEW = `Thank you for patronizing ${business_name}, please leave us a review on our website. ${resolvedSubdomain()}/review?v=true`;
  const INVITE_FRIENDS = `Hi! Believe it or not, I can now create Ads on Facebook & Instagram, talk to my customers, and send SMS offers all in one place! Trust me, the THryve app is a MUST HAVE for your business growth. Tap here to download now: https://play.google.com/store/apps/details?id=com.${appVendor}.app`;
  const PRODUCT_PHOTO = `You might like this product from ${business_name}! Check it out here ${resolvedSubdomain()}/photos/${getImageId()}.`;
  const REQUEST_PAYMENT_LINK = `Hey there! ${business_name} has requested N${contactDetails?.amount} from you. To make the process smooth and hassle-free, we're sharing a unique payment link with you. Please click on the link below to proceed with the payment: ${paymentLink?.payment_link_url}`;

  const textHandler = () => {
    switch (operation) {
      case "Share Webpage":
        return SHARE_WEBPAGE;
      case "Request Review":
        return REQUEST_REVIEW;
      case "Invite Friends":
        return INVITE_FRIENDS;
      case "Share Product Photo":
        return PRODUCT_PHOTO;
      case "Request Payment":
        return REQUEST_PAYMENT_LINK;
      default:
        return INVITE_FRIENDS;
    }
  };

  const copyToClipboardHandler = () => {
    setShowAlert(true);
    navigator.clipboard.writeText(textHandler());
    setTimeout(() => {
      setShowAlert(false);
    }, 1500);
  };

  return (
    <div className="relative h-screen w-full flex flex-col items-center">
      {showAlert && (
        <div className="fixed left-0 right-0 bottom-[28rem] z-20 w-full flex justify-center items-center">
          <p className="py-2.5 px-4 text-green-50 text-sm font-bold w-48 text-center bg-white rounded-[6.25rem]">
            Copied to Clipboard
          </p>
        </div>
      )}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="absolute h-screen w-full bg-blur"
        onClick={() => showDialogueHandler(false)}
      />
      <div className="absolute -bottom-10 w-full rounded-t-2xl bg-white pt-[1.62rem] pb-6 px-4">
        <div className="w-full flex flex-row justify-between items-center pb-[1.12rem]">
          <h1 className="text-lg text-[#1A1A1A] font-semibold">{operation}</h1>
          <button type="button" onClick={() => showDialogueHandler(false)}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
                fill="#D0D5DD"
              />
              <path
                d="M9.16986 15.58C8.97986 15.58 8.78986 15.51 8.63986 15.36C8.34986 15.07 8.34986 14.59 8.63986 14.3L14.2999 8.63999C14.5899 8.34999 15.0699 8.34999 15.3599 8.63999C15.6499 8.92999 15.6499 9.40998 15.3599 9.69998L9.69986 15.36C9.55986 15.51 9.35986 15.58 9.16986 15.58Z"
                fill="#101828"
              />
              <path
                d="M14.8299 15.58C14.6399 15.58 14.4499 15.51 14.2999 15.36L8.63986 9.69998C8.34986 9.40998 8.34986 8.92999 8.63986 8.63999C8.92986 8.34999 9.40986 8.34999 9.69986 8.63999L15.3599 14.3C15.6499 14.59 15.6499 15.07 15.3599 15.36C15.2099 15.51 15.0199 15.58 14.8299 15.58Z"
                fill="#101828"
              />
            </svg>
          </button>
        </div>
        <div className="px-[0.67rem] flex flex-col justify-start items-center space-y-6 w-full">
          <p className="text-base text-base font-normal">
            Share this with your social community
          </p>
          <div className="w-full flex flex-row justify-center space-x-6 items-center">
            <WhatsappShareButton
              url=" " // When user shares content, all relevant links are already shared in the content, no need to add them here
              title={textHandler()}
            >
              <svg
                width="37"
                height="36"
                viewBox="0 0 37 36"
                className="fill-primary-50"
              >
                <path d="M18.5 36C28.4411 36 36.5 27.9411 36.5 18C36.5 8.0589 28.4411 0 18.5 0C8.5589 0 0.5 8.0589 0.5 18C0.5 27.9411 8.5589 36 18.5 36Z" />
                <path
                  d="M9.00401 28L10.356 23.032C9.46515 21.5049 8.99711 19.768 9.00001 18C9.00001 12.477 13.477 8 19 8C24.523 8 29 12.477 29 18C29 23.523 24.523 28 19 28C17.2328 28.0028 15.4967 27.5352 13.97 26.645L9.00401 28ZM15.391 13.308C15.2619 13.316 15.1357 13.35 15.02 13.408C14.9115 13.4694 14.8125 13.5462 14.726 13.636C14.606 13.749 14.538 13.847 14.465 13.942C14.0954 14.423 13.8966 15.0134 13.9 15.62C13.902 16.11 14.03 16.587 14.23 17.033C14.639 17.935 15.312 18.89 16.201 19.775C16.415 19.988 16.624 20.202 16.849 20.401C17.9524 21.3725 19.2673 22.073 20.689 22.447L21.258 22.534C21.443 22.544 21.628 22.53 21.814 22.521C22.1053 22.506 22.3896 22.4271 22.647 22.29C22.778 22.2225 22.9058 22.1491 23.03 22.07C23.03 22.07 23.073 22.042 23.155 21.98C23.29 21.88 23.373 21.809 23.485 21.692C23.568 21.606 23.64 21.505 23.695 21.39C23.773 21.227 23.851 20.916 23.883 20.657C23.907 20.459 23.9 20.351 23.897 20.284C23.893 20.177 23.804 20.066 23.707 20.019L23.125 19.758C23.125 19.758 22.255 19.379 21.724 19.137C21.668 19.1126 21.608 19.0987 21.547 19.096C21.4786 19.089 21.4095 19.0967 21.3443 19.1186C21.2791 19.1405 21.2193 19.1761 21.169 19.223V19.221C21.164 19.221 21.097 19.278 20.374 20.154C20.3325 20.2098 20.2754 20.2519 20.2098 20.2751C20.1443 20.2982 20.0733 20.3013 20.006 20.284C19.9409 20.2666 19.877 20.2445 19.815 20.218C19.691 20.166 19.648 20.146 19.563 20.109L19.558 20.107C18.9859 19.8572 18.4562 19.5198 17.988 19.107C17.862 18.997 17.745 18.877 17.625 18.761C17.2316 18.3842 16.8887 17.958 16.605 17.493L16.546 17.398C16.5036 17.3342 16.4694 17.2653 16.444 17.193C16.406 17.046 16.505 16.928 16.505 16.928C16.505 16.928 16.748 16.662 16.861 16.518C16.9551 16.3983 17.0429 16.2738 17.124 16.145C17.242 15.955 17.279 15.76 17.217 15.609C16.937 14.925 16.647 14.244 16.349 13.568C16.29 13.434 16.115 13.338 15.956 13.319C15.902 13.313 15.848 13.307 15.794 13.303C15.6597 13.2963 15.5251 13.2987 15.391 13.308Z"
                  className="fill-secondary-50 thryve:fill-base tappi:fill-white"
                />
              </svg>
            </WhatsappShareButton>
            <FacebookShareButton
              url={resolvedSubdomain()}
              title={textHandler()}
            >
              <svg
                width="37"
                height="36"
                viewBox="0 0 37 36"
                className="fill-primary-50"
              >
                <path d="M18.5 36C28.4411 36 36.5 27.9411 36.5 18C36.5 8.0589 28.4411 0 18.5 0C8.5589 0 0.5 8.0589 0.5 18C0.5 27.9411 8.5589 36 18.5 36Z" />
                <path
                  d="M28.5 18C28.5 12.48 24.02 8 18.5 8C12.98 8 8.5 12.48 8.5 18C8.5 22.84 11.94 26.87 16.5 27.8V21H14.5V18H16.5V15.5C16.5 13.57 18.07 12 20 12H22.5V15H20.5C19.95 15 19.5 15.45 19.5 16V18H22.5V21H19.5V27.95C24.55 27.45 28.5 23.19 28.5 18Z"
                  className="fill-secondary-50 thryve:fill-base tappi:fill-white"
                />
              </svg>
            </FacebookShareButton>
            <TwitterShareButton url={resolvedSubdomain()} title={textHandler()}>
              <svg
                width="37"
                height="36"
                viewBox="0 0 37 36"
                className="fill-primary-50"
              >
                <path d="M0.5 18C0.5 8.05887 8.55887 0 18.5 0C28.4411 0 36.5 8.05887 36.5 18C36.5 27.9411 28.4411 36 18.5 36C8.55887 36 0.5 27.9411 0.5 18Z" />
                <path
                  d="M17.9609 14.6306L17.9987 15.2535L17.3691 15.1772C15.0776 14.8848 13.0757 13.8934 11.376 12.2282L10.545 11.402L10.331 12.0121C9.87772 13.3722 10.1673 14.8086 11.1116 15.7746C11.6152 16.3085 11.5019 16.3847 10.6332 16.067C10.331 15.9653 10.0666 15.889 10.0414 15.9271C9.95326 16.0161 10.2554 17.1728 10.4947 17.6304C10.822 18.266 11.4893 18.8888 12.2196 19.2574L12.8365 19.5498L12.1063 19.5625C11.4012 19.5625 11.376 19.5752 11.4515 19.8421C11.7034 20.6684 12.698 21.5454 13.806 21.9267L14.5866 22.1937L13.9067 22.6004C12.8995 23.1851 11.716 23.5156 10.5324 23.541C9.96585 23.5538 9.5 23.6046 9.5 23.6427C9.5 23.7698 11.0361 24.4817 11.93 24.7613C14.6118 25.5875 17.7972 25.2316 20.1894 23.8207C21.8892 22.8165 23.5889 20.8209 24.3821 18.8888C24.8102 17.8592 25.2383 15.978 25.2383 15.0755C25.2383 14.4908 25.2761 14.4145 25.9811 13.7154C26.3966 13.3087 26.7869 12.8638 26.8625 12.7367C26.9884 12.4952 26.9758 12.4952 26.3337 12.7112C25.2635 13.0926 25.1124 13.0417 25.6412 12.4697C26.0315 12.063 26.4973 11.3257 26.4973 11.1097C26.4973 11.0715 26.3085 11.1351 26.0944 11.2495C25.8678 11.3766 25.3642 11.5673 24.9865 11.6817L24.3066 11.8977L23.6896 11.4783C23.3497 11.2495 22.8712 10.9953 22.6194 10.919C21.9773 10.741 20.9952 10.7665 20.4161 10.9698C18.8422 11.5418 17.8476 13.0163 17.9609 14.6306Z"
                  className="fill-secondary-50 thryve:fill-base tappi:fill-white"
                />
              </svg>
            </TwitterShareButton>
            <EmailShareButton
              subject={`Check out ${business_name}`}
              body={textHandler()}
              separator=" "
            >
              <svg
                width="37"
                height="36"
                viewBox="0 0 37 36"
                className="fill-primary-50"
              >
                <rect x="0.5" width="36" height="36" rx="18" />
                <path
                  d="M21.8335 23.6666H15.1668C13.1668 23.6666 11.8335 22.6666 11.8335 20.3333V15.6666C11.8335 13.3333 13.1668 12.3333 15.1668 12.3333H21.8335C23.8335 12.3333 25.1668 13.3333 25.1668 15.6666V20.3333C25.1668 22.6666 23.8335 23.6666 21.8335 23.6666Z"
                  strokeWidth="1.8"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="stroke-secondary-50 thryve:stroke-base tappi:stroke-white"
                />
                <path
                  d="M21.8332 16L19.7465 17.6667C19.0598 18.2133 17.9332 18.2133 17.2465 17.6667L15.1665 16"
                  strokeWidth="1.8"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="stroke-secondary-50 thryve:stroke-base tappi:stroke-white"
                />
              </svg>
            </EmailShareButton>
            <TelegramShareButton
              url=" " // When user shares content, all relevant links are already shared in the content, no need to add them here
              title={textHandler()}
            >
              <svg
                width="37"
                height="36"
                viewBox="0 0 37 36"
                className="fill-primary-50"
              >
                <circle cx="18.5" cy="18" r="18" />
                <path
                  d="M19 8C13.48 8 9 12.48 9 18C9 23.52 13.48 28 19 28C24.52 28 29 23.52 29 18C29 12.48 24.52 8 19 8ZM23.64 14.8C23.49 16.38 22.84 20.22 22.51 21.99C22.37 22.74 22.09 22.99 21.83 23.02C21.25 23.07 20.81 22.64 20.25 22.27C19.37 21.69 18.87 21.33 18.02 20.77C17.03 20.12 17.67 19.76 18.24 19.18C18.39 19.03 20.95 16.7 21 16.49C21.0069 16.4582 21.006 16.4252 20.9973 16.3938C20.9886 16.3624 20.9724 16.3337 20.95 16.31C20.89 16.26 20.81 16.28 20.74 16.29C20.65 16.31 19.25 17.24 16.52 19.08C16.12 19.35 15.76 19.49 15.44 19.48C15.08 19.47 14.4 19.28 13.89 19.11C13.26 18.91 12.77 18.8 12.81 18.45C12.83 18.27 13.08 18.09 13.55 17.9C16.47 16.63 18.41 15.79 19.38 15.39C22.16 14.23 22.73 14.03 23.11 14.03C23.19 14.03 23.38 14.05 23.5 14.15C23.6 14.23 23.63 14.34 23.64 14.42C23.63 14.48 23.65 14.66 23.64 14.8Z"
                  className="fill-secondary-50 thryve:fill-base tappi:fill-white"
                />
              </svg>
            </TelegramShareButton>
          </div>
        </div>
        <div className="mt-8 flex flex-row justify-between items-center w-full">
          <div className="bg-[#D0D5DD] border-b-[0.069rem] border-[#E7E7E7] w-[6.8rem]" />
          <p className="text-grey-60 text-sm">or copy link</p>
          <div className="bg-[#D0D5DD] border-b-[0.069rem] border-[#E7E7E7] w-[6.8rem]" />
        </div>
        <div className="mt-8 flex flex-row justify-between items-start space-x-5 text-jutify w-full rounded-xl border-[0.18rem] border-grey-40 p-4">
          <p className="text-grey-60 text-sm overflow-hidden h-10">
            {textHandler().substring(0, 79)}
            <span>...</span>
          </p>
          <button type="button" onClick={copyToClipboardHandler}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.4 22.75H7.6C3.21 22.75 1.25 20.79 1.25 16.4V12.6C1.25 8.21 3.21 6.25 7.6 6.25H10.6C11.01 6.25 11.35 6.59 11.35 7C11.35 7.41 11.01 7.75 10.6 7.75H7.6C4.02 7.75 2.75 9.02 2.75 12.6V16.4C2.75 19.98 4.02 21.25 7.6 21.25H11.4C14.98 21.25 16.25 19.98 16.25 16.4V13.4C16.25 12.99 16.59 12.65 17 12.65C17.41 12.65 17.75 12.99 17.75 13.4V16.4C17.75 20.79 15.79 22.75 11.4 22.75Z"
                fill="#667085"
              />
              <path
                d="M17.0001 14.15H13.8001C10.9901 14.15 9.8501 13.01 9.8501 10.2V6.99999C9.8501 6.69999 10.0301 6.41999 10.3101 6.30999C10.5901 6.18999 10.9101 6.25999 11.1301 6.46999L17.5301 12.87C17.7401 13.08 17.8101 13.41 17.6901 13.69C17.5801 13.97 17.3001 14.15 17.0001 14.15ZM11.3501 8.80999V10.2C11.3501 12.19 11.8101 12.65 13.8001 12.65H15.1901L11.3501 8.80999Z"
                fill="#667085"
              />
              <path
                d="M15.6001 2.75H11.6001C11.1901 2.75 10.8501 2.41 10.8501 2C10.8501 1.59 11.1901 1.25 11.6001 1.25H15.6001C16.0101 1.25 16.3501 1.59 16.3501 2C16.3501 2.41 16.0101 2.75 15.6001 2.75Z"
                fill="#667085"
              />
              <path
                d="M7 5.75C6.59 5.75 6.25 5.41 6.25 5C6.25 2.93 7.93 1.25 10 1.25H12.62C13.03 1.25 13.37 1.59 13.37 2C13.37 2.41 13.03 2.75 12.62 2.75H10C8.76 2.75 7.75 3.76 7.75 5C7.75 5.41 7.41 5.75 7 5.75Z"
                fill="#667085"
              />
              <path
                d="M19.1899 17.25C19.0561 17.25 18.9399 17.1339 18.9399 17C18.9399 16.8661 19.0561 16.75 19.1899 16.75C20.6077 16.75 21.7499 15.5946 21.7499 14.19V8C21.7499 7.86614 21.8661 7.75 21.9999 7.75C22.1338 7.75 22.2499 7.86614 22.2499 8V14.19C22.2499 15.8739 20.8738 17.25 19.1899 17.25Z"
                fill="#667085"
                stroke="#667085"
              />
              <path
                d="M22 8.74999H19C16.34 8.74999 15.25 7.65999 15.25 4.99999V1.99999C15.25 1.69999 15.43 1.41999 15.71 1.30999C15.99 1.18999 16.31 1.25999 16.53 1.46999L22.53 7.46999C22.74 7.67999 22.81 8.00999 22.69 8.28999C22.58 8.56999 22.3 8.74999 22 8.74999ZM16.75 3.80999V4.99999C16.75 6.82999 17.17 7.24999 19 7.24999H20.19L16.75 3.80999Z"
                fill="#667085"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

ShareDialogue.defaultProps = {
  clickedPhoto: null,
  operation: null,
  showDialogueHandler: null,
};

ShareDialogue.propTypes = {
  operation: PropTypes.string,
  showDialogueHandler: PropTypes.func,
  clickedPhoto: PropTypes.string,
};

export default ShareDialogue;
