/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { v4 as uuid } from "uuid";
import { toast } from "react-hot-toast";

import { useQueryClient } from "@tanstack/react-query";
import ClockIcon from "../../assets/icons/clock-icon.png";
import Button from "../forms/Button";
import { updateMerchantOperationHours } from "../../utils/requests-v2";

export default function UpdateWorkingHoursForm({ workingHours }) {
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const times = [
    "12 AM",
    "1 AM",
    "2 AM",
    "3 AM",
    "4 AM",
    "5 AM",
    "6 AM",
    "7 AM",
    "8 AM",
    "9 AM",
    "10 AM",
    "11 AM",
    "12 PM",
    "1 PM",
    "2 PM",
    "3 PM",
    "4 PM",
    "5 PM",
    "6 PM",
    "7 PM",
    "8 PM",
    "9 PM",
    "10 PM",
    "11 PM",
  ];

  const navigate = useNavigate();

  const redirectTimeOut = () =>
    setTimeout(() => {
      navigate("/account/profile");
    }, 2000);

  // Clear timeout if component is unmounted
  useEffect(() => () => clearTimeout(redirectTimeOut), []);

  const [defaultWorkingHours, setDefaultWorkingHours] = useState(workingHours);

  // eslint-disable-next-line no-unused-vars
  const [initialDefaultValues, _] = useState(() => {
    let hours = {};

    const initialDays = workingHours.map((day) => day.day);

    // For each day, we want to create an object with the hours for that day based on the workingHours from the API
    // This also ensures the UI maintains intuitive ordering of working days, regardless of how we get them from the API
    days.forEach((day) => {
      // If the day is not included in the initial working hours, we create it with a temporary ID
      if (!initialDays.includes(day)) {
        hours = {
          ...hours,
          [day]: {
            open: "8 AM",
            close: "5 PM",
            id: `temp_${uuid()}`, // This is used to track temporary hours
            day,
          },
        };
      } else {
        hours = {
          ...hours,
          [day]: {
            open: workingHours
              .filter((value) => value.day === day)[0]
              ?.open.toUpperCase(),
            close: workingHours
              .filter((value) => value.day === day)[0]
              ?.close.toUpperCase(),
            id: workingHours.filter((value) => value.day === day)[0].id,
            day,
          },
        };
      }
    });

    // Set the default working hours the page displays to the user
    // This ensures all 7 days are shown on the UI, even if they haven't been set on the backend
    // This has to be an array
    setDefaultWorkingHours(() =>
      Object.entries(hours).map((value) => ({
        ...value[1],
        day: value[0],
      }))
    );

    // This object is used by the form, instead of passing an array
    return hours;
  });

  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: initialDefaultValues,
  });

  // Track whether a day is set to open or not. Value is obtained from the API, but defaults to false otherwise
  const [openDays, setOpenDays] = useState({
    Monday:
      workingHours
        .filter((day) => day.day === "Monday")
        .map((day) => !!day.open)[0] || false,
    Tuesday:
      workingHours
        .filter((day) => day.day === "Tuesday")
        .map((day) => !!day.open)[0] || false,
    Wednesday:
      workingHours
        .filter((day) => day.day === "Wednesday")
        .map((day) => !!day.open)[0] || false,
    Thursday:
      workingHours
        .filter((day) => day.day === "Thursday")
        .map((day) => !!day.open)[0] || false,
    Friday:
      workingHours
        .filter((day) => day.day === "Friday")
        .map((day) => !!day.open)[0] || false,
    Saturday:
      workingHours
        .filter((day) => day.day === "Saturday")
        .map((day) => !!day.open)[0] || false,
    Sunday:
      workingHours
        .filter((day) => day.day === "Sunday")
        .map((day) => !!day.open)[0] || false,
  });

  const queryClient = useQueryClient();

  async function onSubmit(data) {
    const newDates = [];

    // Check if any dates have been added that did not exist
    // Also, if the dates are set to be open, we know that they are being created afresh
    newDates.push(
      ...Object.values(data).filter((date) => !!openDays[date.day])
    );

    // If there are any changes, we run the requests
    if (newDates.length) {
      try {
        // Create any new dates
        if (newDates.length) {
          await updateMerchantOperationHours({
            data: newDates.map((d) => ({
              day: d.day,
              open: d.open,
              close: d.close,
            })),
          });
        }

        // Refetch queries
        await queryClient.invalidateQueries(["/working-hours"]);
        toast.success("Successfully update your working hours");
        redirectTimeOut();
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong. Please try again");
      }
    } else {
      toast.error("You have not made any changes");
    }
  }

  return (
    <form
      className="flex flex-col w-full mt-6 h-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
        {/* <div className="form-check form-switch flex justify-between py-2 w-full pl-0 mb-2">
        <label
          className="form-check-label inline-block text-gray-800"
          htmlFor="businessAlwaysOpen"
        >
          Always Open
        </label>
        <input
          className="form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"
          type="checkbox"
          role="switch"
          id="businessAlwaysOpen"
          checked={false}
          onChange={({ target: { checked } }) =>
            // setFormState({
            //   ...formState,
            //   businessAlwaysOpen: checked,
            // })
            console.log("Toggling whether merchant is always open")
          }
        />
      </div> */}

        {/* {formState.businessAlwaysOpen ? null : (
          <> */}
        {defaultWorkingHours.map((day, index) => (
          <div
            className={`flex justify-between my-2 ${
              index === 6 ? "flex-grow items-start" : "items-center"
            }`}
            key={uuid()}
          >
            <div className="form-check form-switch flex justify-between py-2 w-[120px] pl-0">
              <label
                className="form-check-label block text-gray-800 text-xs"
                htmlFor={`${day.day}Input`}
              >
                {day.day}
              </label>
              <input
                className="form-check-input appearance-none -ml-10 rounded-full float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm h-4 w-8"
                type="checkbox"
                role="switch"
                id={`${day.day}Input`}
                checked={!!openDays[day.day]}
                name={`${day.day}.isOpen`}
                onChange={({ target: { checked } }) => {
                  setOpenDays((data) => ({
                    ...data,
                    [day.day]: checked,
                  }));
                }}
              />
            </div>

            {openDays[day.day] ? (
              <div className="flex items-center ml-1">
                <div className="relative">
                  <img
                    src={ClockIcon}
                    alt="Clock Icon"
                    width={10}
                    height={10}
                    className="absolute top-[10px] left-[6px]"
                  />
                  <div>
                    <select
                      className="form-select appearance-none bg-none block px-3 py-1.5 text-xs font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none w-[92px] pl-[20px] appearance-none "
                      aria-label="Set Opening Time"
                      name={`${day.day}.open`}
                      {...register(`${day.day}.open`)}
                    >
                      {times.map((time) => (
                        <option key={time} value={time}>
                          {time}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <p className="mx-2">to</p>
                <div className="relative">
                  <img
                    src={ClockIcon}
                    alt="Clock Icon"
                    width={10}
                    height={10}
                    className="absolute top-[10px] left-[6px]"
                  />
                  <div>
                    <select
                      className="form-select appearance-none bg-none block px-3 py-1.5 text-xs font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none w-[92px] pl-[20px] appearance-none "
                      aria-label="Set Closing Time"
                      name={`${day.day}.close`}
                      {...register(`${day.day}.close`)}
                    >
                      {times.map((time) => (
                        <option key={time} value={time}>
                          {time}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            ) : (
              <p className="text-xs">Closed</p>
            )}
          </div>
        ))}
        {/* </>
        )} */}

        <div className="text-center lg:text-left mb-2">
          <Button type="submit" isLoading={isSubmitting}>
            Update
          </Button>
        </div>
      </fieldset>
    </form>
  );
}

UpdateWorkingHoursForm.propTypes = {
  workingHours: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string,
      open: PropTypes.string,
      close: PropTypes.string,
      id: PropTypes.number,
    })
  ).isRequired,
};
