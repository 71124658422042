import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "tw-elements";
import { StateMachineProvider, createStore } from "little-state-machine";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";

import "./index.css";
import HomeV2 from "./pages/V2/home";
import JoinV2 from "./pages/V2/join";
import SubmitOTPV2 from "./pages/V2/submitOTP";
import { AuthProvider as AuthProviderV2 } from "./utils/Contexts/V2/AuthenticationContext";
import BusinessRegistration from "./pages/BusinessRegistration";
import SetWOrkingHoursPageV2 from "./pages/V2/setWorkingHours";
import { BusinessProvider as BusinessProviderV2 } from "./utils/Contexts/V2/BusinessContext";
import BankingSetup from "./pages/BankingSetup";
import AddPhotoV2 from "./pages/V2/addPhoto";
import AccountPageV2 from "./pages/V2/account";
import PrivateRouteV2 from "./components/V2/PrivateRoute";
import { ProductPhotosProvider as ProductPhotosProviderV2 } from "./utils/Contexts/V2/ProductPhotosContext";
import CustomersPageV2 from "./pages/V2/Customers";
import ProfilePageV2 from "./pages/V2/profile";
import Subscription from "./pages/Subscription";
import SubscriptionPlans from "./pages/SubscriptionPlans";
import PaymentSelectMethodV2 from "./pages/V2/PaymentSelectMethod";
import PaymentSuccessful from "./pages/PaymentSuccessful";
import PaymentSuccessfulV2 from "./pages/V2/PaymentSuccessful";
import { SubscriptionProvider as SubscriptionProviderV2 } from "./utils/Contexts/V2/SubscriptionContext";
import PaymentPromoCodePage1V2 from "./pages/V2/PaymentPromoCode";
import PaymentMpesaSelection from "./pages/PaymentMpesaSelection";
import SpendPage from "./pages/spend";
import Wallet2 from "./pages/V2/Wallet";
import { WalletProvider as WalletProviderV2 } from "./utils/Contexts/V2/WalletContext";
import { PaymentProvider as PaymentProviderV2 } from "./utils/Contexts/V2/PaymentContext";
import { PaymentProvider as PaymentProvider2V2 } from "./utils/Contexts/V2/PaymentContext2";
import AdCampaignsPageV2 from "./pages/V2/adCampaigns";
import CreateAdPage from "./pages/createAd";
import AdCampaignAudiencePageV2 from "./pages/V2/adCampaignsAudience";
import AdDesignPageV2 from "./pages/V2/adDesign";
import AdPreviewPageV2 from "./pages/V2/adPreview";
import AdPaymentSelectPageV2 from "./pages/V2/adPaymentSelect";
import MpesaPaymentPage from "./pages/mpesaPayment";
import PromoCodePaymentsPageV2 from "./pages/V2/promoCodePayments";
import { DEFAULT_STORE_VALUE } from "./utils/stateMachine";
import PaymentSuccessPageV2 from "./pages/V2/paymentSuccess";
import OfferDetailsV2 from "./pages/V2/offer/OfferDetails";
import SelectCustomerV2 from "./pages/V2/offer/SelectCustomers";
import ReviewOfferV2 from "./pages/V2/offer/ReviewOffer";
import { AppProvider } from "./utils/Contexts/AppContext";
import { ContactProvider } from "./utils/Contexts/V2/ContactContext";
import BankDetails from "./pages/V2/bankDetails";
import { OfferProvider } from "./utils/Contexts/V2/OfferContext";
import EditOfferDetails from "./pages/V2/offer/EditOfferDetails";
import OfferPaymentSuccessful from "./pages/V2/offer/OfferPaymentSuccessful";
import DeleteAccountPageV2 from "./pages/V2/deleteAccount";
import ErrorComponent from "./components/ErrorComponent";
import { PaymentInfoProvider } from "./utils/Contexts/V2/PaymentInfoContext";
import SetLocationPage from "./pages/V2/setLocation";
import ConnectFacebook from "./pages/V2/connectFacebook";
import QRCodePage from "./pages/V2/qrCode";
import InsightsPage from "./pages/V2/insights";
import RequestPayment from "./pages/V2/request-payment";
import EnterAmount from "./pages/V2/request-payment/enter-amount";
import { RequestPaymentProvider } from "./utils/Contexts/V2/RequestPaymentContext";
import ForgotPasswordPage from "./pages/V2/forgotPassword";
import RegistrationSuccessfulPage from "./pages/V2/registrationSuccessful";
import ForgotPasswordSubmitOTPPage from "./pages/V2/forgotPasswordSubmitOTP";
import ResetPasswordPage from "./pages/V2/resetPassword";
import ResetPasswordSuccessPage from "./pages/V2/resetPasswordSuccess";
import SelectLoginAccountPage from "./pages/V2/selectLoginAccount";
import ConnectedAccounts from "./pages/V2/connected-accounts/connectedAccounts";
import FacebookConnect from "./pages/V2/connected-accounts/facebookConnect";
import FindingFacebookId from "./pages/V2/connected-accounts/findingdFacbookId";
import { ConnectAccountProvider } from "./utils/Contexts/V2/ConnectAccountContext";
import ConfigureConnection from "./pages/V2/connected-accounts/configureConnection";
import SelectOffer from "./pages/V2/offer/SelectOffer";

createStore({
  ...DEFAULT_STORE_VALUE,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      retry: false,
    },
  },
});

const routerVersion2 = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateRouteV2>
        <HomeV2 />
      </PrivateRouteV2>
    ),
    // errorElement: <NotFound />,
  },
  {
    path: "/join",
    element: <JoinV2 />,
    errorElement: <ErrorComponent />,
  },
  {
    path: "/join/otp",
    element: <SubmitOTPV2 />,
    errorElement: <ErrorComponent />,
  },
  {
    path: "/join/business-registration",
    element: <BusinessRegistration />,
    errorElement: <ErrorComponent />,
  },
  {
    path: "/join/setup-banking",
    element: <BankingSetup />,
    errorElement: <ErrorComponent />,
  },
  {
    path: "/add-photos",
    element: (
      <PrivateRouteV2>
        <AddPhotoV2 />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/account",
    element: (
      <PrivateRouteV2>
        <AccountPageV2 />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/customers",
    element: (
      <PrivateRouteV2>
        <CustomersPageV2 />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/account/profile",
    element: (
      <PrivateRouteV2>
        <ProfilePageV2 />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/account/my-bank-details",
    element: (
      <PrivateRouteV2>
        <BankDetails />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/account/profile/working-hours",
    element: (
      <PrivateRouteV2>
        <SetWOrkingHoursPageV2 />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/account/connect-facebook",
    element: (
      <PrivateRouteV2>
        <ConnectFacebook />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/insights",
    element: (
      <PrivateRouteV2>
        <InsightsPage />
      </PrivateRouteV2>
    ),
  },
  {
    path: "/insights/spend",
    element: (
      <PrivateRouteV2>
        <SpendPage />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/account/subscription",
    element: (
      <PrivateRouteV2>
        <Subscription />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/account/subscription/plans",
    element: (
      <PrivateRouteV2>
        <SubscriptionPlans />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/account/subscription/payment",
    element: (
      <PrivateRouteV2>
        <PaymentSelectMethodV2 />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/account/subscription/payment/mpesa",
    element: (
      <PrivateRouteV2>
        <PaymentMpesaSelection />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/account/subscription/status",
    element: (
      <PrivateRouteV2>
        <PaymentSuccessful />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/account/qr-code",
    element: (
      <PrivateRouteV2>
        <QRCodePage />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/account/delete",
    element: (
      <PrivateRouteV2>
        <DeleteAccountPageV2 />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/wallet",
    element: (
      <PrivateRouteV2>
        <Wallet2 />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/wallet/payment",
    element: (
      <PrivateRouteV2>
        <PaymentSelectMethodV2 />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/wallet/payment/mpesa",
    element: (
      <PrivateRouteV2>
        <PaymentMpesaSelection />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/wallet/payment/promo_code",
    element: (
      <PrivateRouteV2>
        <PaymentPromoCodePage1V2 />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/wallet/status",
    element: (
      <PrivateRouteV2>
        <PaymentSuccessfulV2 />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/ads",
    element: (
      <PrivateRouteV2>
        <AdCampaignsPageV2 />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/ads/create",
    element: (
      <PrivateRouteV2>
        <CreateAdPage />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/ads/create/audience",
    element: (
      <PrivateRouteV2>
        <AdCampaignAudiencePageV2 />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/ads/create/design",
    element: (
      <PrivateRouteV2>
        <AdDesignPageV2 />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/ads/create/preview",
    element: (
      <PrivateRouteV2>
        <AdPreviewPageV2 />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/ads/create/payment",
    element: (
      <PrivateRouteV2>
        <AdPaymentSelectPageV2 />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/payments/mpesa",
    element: (
      <PrivateRouteV2>
        <MpesaPaymentPage />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/payments/promo",
    element: (
      <PrivateRouteV2>
        <PromoCodePaymentsPageV2 />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/payments/success",
    element: (
      <PrivateRouteV2>
        <PaymentSuccessPageV2 />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/create-offers",
    element: (
      <PrivateRouteV2>
        <OfferDetailsV2 />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/create-offers/select-customers/:id",
    element: (
      <PrivateRouteV2>
        <SelectCustomerV2 />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/create-offers/review-offer/:id",
    element: (
      <PrivateRouteV2>
        <ReviewOfferV2 />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/edit-offers/:id",
    element: (
      <PrivateRouteV2>
        <EditOfferDetails />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/create-offers/success/top-up/:quantity",
    element: (
      <PrivateRouteV2>
        <OfferPaymentSuccessful />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/account/profile/location",
    element: (
      <PrivateRouteV2>
        <SetLocationPage />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/request-payment",
    element: (
      <PrivateRouteV2>
        <RequestPayment />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/request-payment/enter-amount",
    element: (
      <PrivateRouteV2>
        <EnterAmount />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/join/success",
    element: <RegistrationSuccessfulPage />,
  },
  {
    path: "/forgot-password/submit-otp",
    element: <ForgotPasswordSubmitOTPPage />,
  },
  {
    path: "/forgot-password/reset-password",
    element: <ResetPasswordPage />,
  },
  {
    path: "/forgot-password/success",
    element: <ResetPasswordSuccessPage />,
  },
  {
    path: "/join/select-account",
    element: <SelectLoginAccountPage />,
  },
  {
    path: "/account/connected-accounts",
    element: (
      <PrivateRouteV2>
        <ConnectedAccounts />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/account/connected-accounts/facebook",
    element: (
      <PrivateRouteV2>
        <FacebookConnect />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/account/connected-accounts/find-facebook-id",
    element: (
      <PrivateRouteV2>
        <FindingFacebookId />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/account/connected-accounts/configure-facebook",
    element: (
      <PrivateRouteV2>
        <ConfigureConnection />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
  {
    path: "/offers/select-offer",
    element: (
      <PrivateRouteV2>
        <SelectOffer />
      </PrivateRouteV2>
    ),
    errorElement: <ErrorComponent />,
  },
]);

function AppVersion2() {
  return (
    <StateMachineProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProviderV2>
          <BusinessProviderV2>
            <PaymentProvider2V2>
              <ProductPhotosProviderV2>
                <PaymentProviderV2>
                  <SubscriptionProviderV2>
                    <ConnectAccountProvider>
                      <WalletProviderV2>
                        <OfferProvider>
                          <ContactProvider>
                            <PaymentInfoProvider>
                              <RequestPaymentProvider>
                                <Toaster
                                  position="top-center"
                                  reverseOrder={false}
                                  gutter={8}
                                  containerClassName=""
                                  containerStyle={{}}
                                  toastOptions={{
                                    // define default options
                                    className:
                                      "toast rounded-full font-medium text-sm",
                                    duration: 5000,
                                    style: {
                                      background: "#fffbfa",
                                      color: "#667085",
                                      textAlign: "center",
                                    },

                                    // Default options for specific types
                                    success: {
                                      duration: 5000,
                                      style: {
                                        background: "#fffbfa",
                                        color: "#12B76A",
                                        textAlign: "center",
                                      },
                                      icon: null,
                                    },
                                    error: {
                                      duration: 5000,
                                      style: {
                                        background: "#fffbfa",
                                        color: "#F04438",
                                        textAlign: "center",
                                      },
                                    },
                                    icon: null,
                                  }}
                                />
                                <RouterProvider router={routerVersion2} />
                              </RequestPaymentProvider>
                            </PaymentInfoProvider>
                          </ContactProvider>
                        </OfferProvider>
                      </WalletProviderV2>
                    </ConnectAccountProvider>
                  </SubscriptionProviderV2>
                </PaymentProviderV2>
              </ProductPhotosProviderV2>
            </PaymentProvider2V2>
          </BusinessProviderV2>
        </AuthProviderV2>
      </QueryClientProvider>
    </StateMachineProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AppProvider>
      <AppVersion2 />
    </AppProvider>
  </React.StrictMode>
);
