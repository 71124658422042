import {
  getCurrentMerchant,
  loginMerchant,
  registerMerchant,
} from "./requests";

const authenticationService = {
  // Login the user. Persist user data in local storage
  async login({ username, password }) {
    try {
      const res = await loginMerchant({ username, password });

      const userData = await getCurrentMerchant(res.user_id);

      authenticationService.updateUserData(userData);
      return userData;
    } catch (e) {
      console.error("Could not log user in : ", e);
      return Promise.reject(e);
    }
  },

  // Clear local storage to log out user
  logout() {
    localStorage.clear();
    sessionStorage.clear();
  },

  // Check if user is logged in and return their data
  getCurrentUser() {
    const storedUserData = localStorage.getItem("T");
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);

      // Log user out if token is expired
      if (parsedUserData.status === 400) {
        authenticationService.logout();
        return null;
      }
      return parsedUserData;
    }
    return null;
  },

  // Register a new user
  async register(userData) {
    try {
      const res = await registerMerchant(userData);
      console.log(res);
      return res;
    } catch (e) {
      console.error("Could not register user : ", e);
      return Promise.reject(e);
    }
  },

  // Update user data
  async updateUserData(userData) {
    localStorage.setItem("T", JSON.stringify(userData));
  },
};

export default authenticationService;
