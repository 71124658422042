import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { toast } from "react-hot-toast";
import moment from "moment/moment";

import Layout from "../../components/layout/V2/Layout";
import { useWalletContext } from "../../utils/Contexts/V2/WalletContext";
import { usePaymentContext } from "../../utils/Contexts/V2/PaymentContext";
import {
  PAYMENT_REASONS,
  usePaymentContext as usePaymentContext2,
} from "../../utils/Contexts/V2/PaymentContext2";
import AirtimePaymentPrompt from "../../components/V2/AirtimePaymentPrompt";
import FlutterwaveIcon from "../../assets/icons/flutterwave.svg";
import { updateSubscriptionPackage } from "../../utils/requests-v2";
import { useAppContext } from "../../utils/Contexts/AppContext";
import { useSubscriptionContext } from "../../utils/Contexts/V2/SubscriptionContext";

function PaymentSelectMethod() {
  const navigate = useNavigate();
  const { appVendor } = useAppContext();
  const {
    PAYMENT_METHOD,
    country,
    currency,
    selectedPayment,
    selectedPaymentHandler,
    flutterwavePaymentDetails,
  } = usePaymentContext();
  const { paymentFor, paymentAmount } = usePaymentContext2();
  const {
    showError,
    errorMessage,
    paymentNavigationHandler,
    airtimeConfirmationPrompt,
    airtimePayConfirmation,
    airtimePay,
    setAirtimeConfirmationPrompt,
    isCheckingAirtimePaymentStatus,
    confirmFlutterwavePaymentStatus,
    flutterwavePay,
    isProcessingPayment,
    topUpAmount,
    walletDetails,
  } = useWalletContext();

  const { subscriptionPackages, selectedPlan } = useSubscriptionContext();

  const [activePaymentAmount, setActivePaymentAmount] = useState(paymentFor);

  // If payment is for subscriptions or ads, we get amount from the PaymentContext
  // Otherwise we get amount from WalletContext
  useEffect(() => {
    if (
      paymentFor === PAYMENT_REASONS.ADS ||
      paymentFor === PAYMENT_REASONS.SUBSCRIPTION
    ) {
      setActivePaymentAmount(paymentAmount);
      // Default payment method is set to credits
      selectedPaymentHandler({ target: { id: "credits" } });
    } else {
      // Payment for credits defaults to MPESA on Tappi
      if (paymentFor === PAYMENT_REASONS.CREDITS && appVendor === "tappi") {
        selectedPaymentHandler({ target: { id: "mpesa" } });
      }
      setActivePaymentAmount(topUpAmount);
    }
  }, [paymentFor]);

  const [subscriptionToPurchase, setSubscriptionToPurchase] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (subscriptionPackages?.length && selectedPlan) {
      const packageObject = subscriptionPackages.find(
        (p) => p.name === selectedPlan.plan
      );

      setSubscriptionToPurchase({
        id: packageObject.id,
        name: packageObject.name,
        interval: selectedPlan.rate,
      });
    }
  }, [selectedPlan, subscriptionPackages]);

  const handlePaymentSelected = async () => {
    if (selectedPayment.mpesa) {
      navigate("/payments/mpesa");
    } else if (selectedPayment.credits) {
      if (paymentAmount > walletDetails.credit_balance) {
        toast.error("Insufficient credit balance");
      } else {
        setIsLoading(true);
        updateSubscriptionPackage({
          packageId: subscriptionToPurchase.id,
          interval: subscriptionToPurchase.interval,
          startDate: moment().format("YYYY-MM-DD"),
        })
          .then(() => toast.success("Subscription succesfully purchased"))
          .catch((error) => {
            console.error(
              "Failed to purchase subscription",
              error.response?.data
            );
            toast.error(
              error.response?.data?.detail ||
                "Could not purchase subscription. Please try again"
            );
          })
          .finally(() => setIsLoading(false));
      }
    } else if (selectedPayment.promoCode) {
      navigate("/payments/promo");
    } else if (selectedPayment.airtime) {
      airtimePay();
    } else if (selectedPayment.flutterwave) {
      confirmFlutterwavePaymentStatus(navigate);
    } else {
      paymentNavigationHandler(navigate);
    }
  };

  return (
    <Layout header headerTitle="Select Payment Method">
      <div className="relative flex flex-col justify-between items-center h-screen w-full">
        <Transition
          className="fixed left-0 right-0 -bottom-5 z-20 w-full flex justify-center items-center shadow-xl "
          show={showError}
          enterFrom=" translate-y-0"
          enterTo=" -translate-y-12"
          leaveFrom=" -translate-y-12"
          leaveTo=" translate-y-0"
        >
          <p className="py-2.5 px-4 text-red-50 text-sm font-bold w-64 text-center bg-white/[.8] rounded-[6.25rem]">
            {errorMessage}
          </p>
        </Transition>
        <Transition
          className="fixed -bottom-12 left-0 z-20 w-full"
          show={airtimeConfirmationPrompt}
          enterFrom=" translate-y-0"
          enterTo=" -translate-y-12"
          leaveFrom=" -translate-y-12"
          leaveTo=" translate-y-0"
        >
          <AirtimePaymentPrompt
            confirmPaymentHandler={airtimePayConfirmation}
            close={setAirtimeConfirmationPrompt}
            checkingStatus={isCheckingAirtimePaymentStatus}
            errorMessage={errorMessage}
          />
        </Transition>
        <div className="flex flex-col items-center space-y-6 w-full px-4 pt-20 ">
          <div className="flex flex-col items-center justify-start space-y-2">
            <p className="text-sm font-medium">You are about to pay</p>
            <h1 className="text-xl font-semibold text-[#1A1A1A]">
              {currency} {activePaymentAmount}
            </h1>
          </div>
          <div className="flex flex-col justify-start items-center space-y-4 w-full">
            {PAYMENT_METHOD.filter((item) => item.country === country).map(
              (item) => {
                // Hide credits option if users want to top up their wallet
                if (
                  window.location.pathname.includes("wallet") &&
                  (item.name === "credits" || item.name === "promoCode")
                ) {
                  return null;
                }
                if (
                  !window.location.pathname.includes("wallet") &&
                  item.name !== "credits"
                ) {
                  return null;
                }
                return (
                  <div
                    className={`flex flex-row justify-between items-center w-full ${
                      selectedPayment[item.name]
                        ? "border-[0.089rem] border-primary-50"
                        : "border border-solid"
                    }  py-3.5 px-4 rounded-[0.87rem]`}
                    key={item.name}
                  >
                    <div className="flex flex-row items-center space-x-3.5">
                      <img src={item.logo} alt={item.name} />
                      <p className="text-[0.9rem] font-semibold">{item.text}</p>
                    </div>
                    <input
                      className="form-check-input appearance-none rounded-full h-[1.4rem] w-[1.4rem] border border-gray-300 bg-white checked:bg-primary-50 checked:border-primary-50 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      name="flexRadioDefault"
                      id={item.name}
                      onChange={selectedPaymentHandler}
                      checked={selectedPayment[item.name]}
                      disabled={item.disabled}
                    />
                  </div>
                );
              }
            )}
            {country === "Nigeria" && (
              <div
                className={`flex flex-col justify-start items-center w-full  space-y-3 ${
                  selectedPayment.flutterwave
                    ? "border-[0.089rem] border-primary-50"
                    : "border border-solid"
                }  py-2.5 px-4 rounded-[0.87rem] mb-4`}
              >
                <div className="flex flex-row items-center justify-between w-full">
                  <div className="w-full flex flex-row items-center space-x-2.5">
                    <img src={FlutterwaveIcon} alt="Flutterwave payment" />
                    <p className="text-[0.9rem] font-semibold">
                      Pay with Flutterwave
                    </p>
                  </div>
                  <input
                    className="form-check-input appearance-none rounded-full h-[1.4rem] w-[1.4rem] border border-gray-300 bg-white checked:bg-primary-50 checked:border-primary-50 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="flexRadioDefault"
                    id="flutterwave"
                    onChange={selectedPaymentHandler}
                    checked={selectedPayment.flutterwave}
                    onClick={flutterwavePay}
                  />
                </div>
                {country === "Nigeria" &&
                  selectedPayment.flutterwave &&
                  (isProcessingPayment ? (
                    // Spinner
                    <div className="h-full w-full flex justify-center items-center">
                      <div
                        className="spinner-border animate-spin inline-block w-10 h-10 border-4 rounded-full text-primary-50"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    flutterwavePaymentDetails?.status === "success" && (
                      <div className="flex flex-col justify-start items-start w-full space-y-3">
                        <p className="pt-2 px-4 pb-3 bg-[#E7E7E7] text-[#566370] font-semibold text-[0.81rem]">
                          Please make a bank transfer to the account below
                          below. Once your payment goes through, click on the
                          Continue button below.
                        </p>
                        <div className="flex flex-row justify-start items-center w-full py-5 px-4 border border-solid space-x-2">
                          <ul className="h-full outline-none text-[#1A1A1A] text-sm font-normal list-disc ml-3 leading-6">
                            <li>
                              Account number:{" "}
                              {flutterwavePaymentDetails?.transfer_account}
                            </li>
                            <li>
                              Bank name:{" "}
                              {flutterwavePaymentDetails?.transfer_bank}
                            </li>
                            <li>{flutterwavePaymentDetails?.transfer_note}</li>
                          </ul>
                        </div>
                      </div>
                    )
                  ))}
              </div>
            )}
          </div>
        </div>
        <div className="relative p-4 w-full shadow-[0_-2px_8px_rgba(0, 0, 0, 0.3)] border border-t-solid border-t-[#D1D9DD]">
          <button
            type="button"
            className="w-full h-12 text-white thryve:text-base text-center font-semibold rounded-xl bg-primary-50 justify-center items-center"
            onClick={handlePaymentSelected}
            disabled={!selectedPayment}
          >
            {/* {console.log(selectedPayment)} */}
            {isProcessingPayment || isLoading
              ? "Processing payment..."
              : "Continue"}
          </button>
        </div>
      </div>
    </Layout>
  );
}

export default PaymentSelectMethod;
