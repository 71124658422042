import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOffer } from "../../../utils/Contexts/V2/OfferContext";
import Layout from "../../../components/layout/Layout";

export default function SelectOffer() {
  const navigate = useNavigate();
  const { offersContacts, offers } = useOffer();
  const [selectedOfferId, setSelectedOfferId] = useState(null);
  const getOfferStatus = (offer) => {
    if (offer.is_active) {
      if (moment(offer.end_date).isAfter(moment())) {
        return "Live";
      }
      return "Completed";
    }
    return "Draft";
  };

  const getCustomerCountForOffer = (id) =>
    offersContacts.filter((item) => item.id === id)[0]?.count;
  return (
    <Layout header headerTitle="Select Offer" backTo="/customers?tab=customers">
      <div className="relative h-screen pt-20 max-w-screen-xl container mx-auto flex flex-col justify-between items-center">
        <div className="w-full px-4 h-[90%] overflow-y-scroll">
          {offers.map((item) => (
            <button
              type="button"
              onClick={() => setSelectedOfferId(item.id)}
              className={`flex flex-col justify-start items-center p-4  rounded-xl w-full space-y-2 mb-4 ${
                item.id === selectedOfferId
                  ? "border-[3px] border-secondary-50"
                  : "border border-solid"
              }`}
              key={item.id}
            >
              <div className="flex flex-row justify-between items-center w-full">
                <h1 className="font-bold text-sm text-[#1A1A1A]">
                  {item.title}
                </h1>
              </div>
              <div className="flex justify-start items-start w-full pr-[2.4rem]">
                <p className="text-[#566370] text-xs">{item.description}</p>
              </div>
              <div className="flex flex-row justify-between items-center w-full">
                <div className="flex flex-col justify-start items-start">
                  <div className="flex flex-row space-x-1.5 justify-start items-center">
                    <div
                      className={`h-2.5 w-2.5 ${
                        getOfferStatus(item) === "Live"
                          ? "bg-green-500"
                          : "bg-[#D1CDCD]"
                      }  rounded-full`}
                    />
                    <p className="font-bold text-sm text-[#1A1A1A]">
                      {getOfferStatus(item)}
                    </p>
                  </div>
                  <p className="text-[0.63rem] font-normal text-[#566370]">
                    Status
                  </p>
                </div>
                <div className="flex flex-col justify-start items-start border-l border-solid pl-2">
                  <div className="flex flex-row space-x-1.5 justify-start items-center">
                    <p className="font-bold text-sm text-[#1A1A1A]">
                      {moment(item.end_date).format("DD/MM/YYYY")}
                    </p>
                  </div>
                  <p className="text-[0.63rem] font-normal text-[#566370]">
                    Date
                  </p>
                </div>
                <div className="flex flex-col justify-start items-start border-l border-solid pl-2">
                  <div className="flex flex-row space-x-1.5 justify-start items-center">
                    <p className="font-bold text-sm text-[#1A1A1A]">
                      {getCustomerCountForOffer(item.id)} customers
                    </p>
                  </div>
                  <p className="text-[0.63rem] font-normal text-[#566370]">
                    Delivered to
                  </p>
                </div>
              </div>
            </button>
          ))}
        </div>
        <div className="w-full px-4">
          <button
            disabled={selectedOfferId === null}
            onClick={() =>
              navigate(`/create-offers/review-offer/${selectedOfferId}`)
            }
            type="button"
            className="w-full h-12 text-white thryve:text-base text-base font-semibold rounded-xl bg-primary-50 mb-5"
          >
            Continue
          </button>
        </div>
      </div>
    </Layout>
  );
}
