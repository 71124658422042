import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useOffer } from "../../utils/Contexts/V2/OfferContext";
import { useRequestPaymentContext } from "../../utils/Contexts/V2/RequestPaymentContext";

export default function CustomerDetails({
  contact,
  setShowCustomerDetails,
  setShowDeleteCustomerPrompt,
}) {
  const navigate = useNavigate();
  const { setSelectedCustomersForOffer } = useOffer();
  const { selectContactHandler } = useRequestPaymentContext();
  const removeCustomerHandler = () => {
    setShowDeleteCustomerPrompt(true);
    setShowCustomerDetails(false);
  };

  const displayPicHandler = (name) => {
    const abbreviatedName = name.split(" ");
    if (abbreviatedName.length >= 2) {
      return `${Array.from(abbreviatedName[0])[0].toUpperCase()}${Array.from(
        abbreviatedName[1]
      )[0].toUpperCase()}`;
    }
    return `${Array.from(abbreviatedName[0])[0].toUpperCase()}`;
  };
  return (
    <div className="relative h-screen w-full flex flex-col items-center">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="absolute h-screen w-full bg-blur"
        onClick={() => setShowCustomerDetails(false)}
      />
      <div className="absolute -bottom-2 w-full rounded-t-2xl bg-white pt-[1.62rem] pb-6">
        <div className="w-full flex flex-row justify-between items-center pb-[1.12rem] px-4">
          <div />
          <button type="button" onClick={() => setShowCustomerDetails(false)}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.0079 18.5383L4.7651 6.29552C4.34681 5.87722 4.34681 5.18347 4.7651 4.76517C5.1834 4.34688 5.87715 4.34688 6.29545 4.76517L18.5382 17.0079C18.9565 17.4262 18.9565 18.12 18.5382 18.5383C18.1199 18.9566 17.4262 18.9566 17.0079 18.5383Z"
                fill="#1A1A1A"
              />
              <path
                d="M4.76551 18.5383C4.34721 18.12 4.34721 17.4262 4.76551 17.0079L17.0083 4.76517C17.4266 4.34688 18.1203 4.34688 18.5386 4.76517C18.9569 5.18347 18.9569 5.87722 18.5386 6.29552L6.29585 18.5383C5.87756 18.9566 5.1838 18.9566 4.76551 18.5383Z"
                fill="#1A1A1A"
              />
            </svg>
          </button>
        </div>
        <div className="flex flex-col justify-start items-center pt-[1.04rem]">
          <div className="flex flex-col justify-start items-center space-y-4">
            <div className="w-20 h-20 bg-[#D9D9D9] rounded-full p-4 flex justify-center items-center">
              <p className="font-bold text-2xl">
                {displayPicHandler(contact[0].full_name)}
              </p>
            </div>
            <p className="font-bold text-lg">{contact[0].full_name}</p>
            <p className="font-normal text-xs text-left text-[#566370]">
              {contact[0]?.phone_number}
            </p>
          </div>
          <div className="w-full flex flex-row justify-between items-center py-8 px-[1.3rem]">
            <button
              type="button"
              onClick={() => {
                navigate("/offers/select-offer");
                setSelectedCustomersForOffer(contact);
              }}
            >
              <div className="flex flex-col justify-start items-center space-y-2">
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="24" cy="24" r="24" fill="#E37333" />
                  <g clipPath="url(#clip0_7836_15934)">
                    <path
                      d="M22.8998 14.1001L32.7988 15.5151L34.2128 25.4151L25.0208 34.6071C24.8333 34.7946 24.579 34.8999 24.3138 34.8999C24.0487 34.8999 23.7944 34.7946 23.6068 34.6071L13.7068 24.7071C13.5194 24.5196 13.4141 24.2653 13.4141 24.0001C13.4141 23.7349 13.5194 23.4806 13.7068 23.2931L22.8998 14.1001ZM25.7278 22.5861C25.9136 22.7718 26.1341 22.9191 26.3768 23.0195C26.6195 23.12 26.8796 23.1717 27.1422 23.1716C27.4049 23.1716 27.6649 23.1198 27.9076 23.0193C28.1502 22.9187 28.3707 22.7714 28.5563 22.5856C28.742 22.3998 28.8893 22.1793 28.9898 21.9367C29.0903 21.694 29.1419 21.4339 29.1419 21.1712C29.1419 20.9086 29.0901 20.6485 28.9895 20.4059C28.889 20.1632 28.7416 19.9428 28.5558 19.7571C28.3701 19.5714 28.1496 19.4241 27.9069 19.3237C27.6642 19.2232 27.4041 19.1715 27.1415 19.1715C26.611 19.1716 26.1024 19.3824 25.7273 19.7576C25.3523 20.1327 25.1417 20.6415 25.1418 21.172C25.1419 21.7024 25.3527 22.2111 25.7278 22.5861Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7836_15934">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(12 12)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div className="flex flex-col justify-start items-center space-y-px font-bold text-xs text-[#1A1A1A]">
                  <p>Send</p>
                  <p>Offer</p>
                </div>
              </div>
            </button>
            <button
              type="button"
              onClick={() => {
                selectContactHandler(contact[0], navigate);
              }}
            >
              <div className="flex flex-col justify-start items-center space-y-2">
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="24" cy="24" r="24" fill="#008753" />
                  <g clipPath="url(#clip0_7836_15941)">
                    <path
                      d="M12 16.5H36V32.5H12V16.5ZM24 20.5C25.0609 20.5 26.0783 20.9214 26.8284 21.6716C27.5786 22.4217 28 23.4391 28 24.5C28 25.5609 27.5786 26.5783 26.8284 27.3284C26.0783 28.0786 25.0609 28.5 24 28.5C22.9391 28.5 21.9217 28.0786 21.1716 27.3284C20.4214 26.5783 20 25.5609 20 24.5C20 23.4391 20.4214 22.4217 21.1716 21.6716C21.9217 20.9214 22.9391 20.5 24 20.5ZM17.3333 19.1667C17.3333 19.8739 17.0524 20.5522 16.5523 21.0523C16.0522 21.5524 15.3739 21.8333 14.6667 21.8333V27.1667C15.3739 27.1667 16.0522 27.4476 16.5523 27.9477C17.0524 28.4478 17.3333 29.1261 17.3333 29.8333H30.6667C30.6667 29.1261 30.9476 28.4478 31.4477 27.9477C31.9478 27.4476 32.6261 27.1667 33.3333 27.1667V21.8333C32.6261 21.8333 31.9478 21.5524 31.4477 21.0523C30.9476 20.5522 30.6667 19.8739 30.6667 19.1667H17.3333Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7836_15941">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(12 12)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div className="flex flex-col justify-start items-center space-y-px font-bold text-xs text-[#1A1A1A]">
                  <p>Request</p>
                  <p>Payment</p>
                </div>
              </div>
            </button>
            {/* <NavLink to="/offers"> */}
            {/*  <div className="flex flex-col justify-start items-center space-y-2"> */}
            {/*    <svg */}
            {/*      width="48" */}
            {/*      height="48" */}
            {/*      viewBox="0 0 48 48" */}
            {/*      fill="none" */}
            {/*      xmlns="http://www.w3.org/2000/svg" */}
            {/*    > */}
            {/*      <circle cx="24" cy="24" r="24" fill="#F3C341" /> */}
            {/*      <g clipPath="url(#clip0_7836_15947)"> */}
            {/*        <path */}
            {/*          d="M24 26V34H16C16 31.8783 16.8429 29.8434 18.3431 28.3431C19.8434 26.8429 21.8783 26 24 26ZM30 33.5L27.061 35.045L27.622 31.773L25.245 29.455L28.531 28.977L30 26L31.47 28.977L34.755 29.455L32.378 31.773L32.938 35.045L30 33.5ZM24 25C20.685 25 18 22.315 18 19C18 15.685 20.685 13 24 13C27.315 13 30 15.685 30 19C30 22.315 27.315 25 24 25Z" */}
            {/*          fill="white" */}
            {/*        /> */}
            {/*      </g> */}
            {/*      <defs> */}
            {/*        <clipPath id="clip0_7836_15947"> */}
            {/*          <rect */}
            {/*            width="24" */}
            {/*            height="24" */}
            {/*            fill="white" */}
            {/*            transform="translate(12 12)" */}
            {/*          /> */}
            {/*        </clipPath> */}
            {/*      </defs> */}
            {/*    </svg> */}
            {/*    <div className="flex flex-col justify-start items-center space-y-px font-bold text-xs text-[#1A1A1A]"> */}
            {/*      <p>Request</p> */}
            {/*      <p>Review</p> */}
            {/*    </div> */}
            {/*  </div> */}
            {/* </NavLink> */}
            <button type="button" onClick={removeCustomerHandler}>
              <div className="flex flex-col justify-start items-center space-y-2">
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="24" cy="24" r="24" fill="#F04438" />
                  <path
                    d="M21.7917 16.6667H26.375C26.375 16.0589 26.1336 15.476 25.7038 15.0462C25.274 14.6164 24.6911 14.375 24.0833 14.375C23.4755 14.375 22.8927 14.6164 22.4629 15.0462C22.0331 15.476 21.7917 16.0589 21.7917 16.6667ZM20.4167 16.6667C20.4167 15.6942 20.803 14.7616 21.4906 14.0739C22.1782 13.3863 23.1109 13 24.0833 13C25.0558 13 25.9884 13.3863 26.6761 14.0739C27.3637 14.7616 27.75 15.6942 27.75 16.6667H33.4792C33.6615 16.6667 33.8364 16.7391 33.9653 16.868C34.0942 16.997 34.1667 17.1718 34.1667 17.3542C34.1667 17.5365 34.0942 17.7114 33.9653 17.8403C33.8364 17.9692 33.6615 18.0417 33.4792 18.0417H32.2783L31.1628 31.4278C31.0816 32.4015 30.6374 33.3093 29.9184 33.971C29.1993 34.6326 28.2578 34.9999 27.2807 35H20.886C19.9088 34.9999 18.9674 34.6326 18.2483 33.971C17.5292 33.3093 17.0851 32.4015 17.0039 31.4278L15.8883 18.0417H14.6875C14.5052 18.0417 14.3303 17.9692 14.2014 17.8403C14.0724 17.7114 14 17.5365 14 17.3542C14 17.1718 14.0724 16.997 14.2014 16.868C14.3303 16.7391 14.5052 16.6667 14.6875 16.6667H20.4167ZM22.7083 21.9375C22.7083 21.7552 22.6359 21.5803 22.507 21.4514C22.378 21.3224 22.2032 21.25 22.0208 21.25C21.8385 21.25 21.6636 21.3224 21.5347 21.4514C21.4058 21.5803 21.3333 21.7552 21.3333 21.9375V29.7292C21.3333 29.9115 21.4058 30.0864 21.5347 30.2153C21.6636 30.3442 21.8385 30.4167 22.0208 30.4167C22.2032 30.4167 22.378 30.3442 22.507 30.2153C22.6359 30.0864 22.7083 29.9115 22.7083 29.7292V21.9375ZM26.1458 21.25C25.9635 21.25 25.7886 21.3224 25.6597 21.4514C25.5308 21.5803 25.4583 21.7552 25.4583 21.9375V29.7292C25.4583 29.9115 25.5308 30.0864 25.6597 30.2153C25.7886 30.3442 25.9635 30.4167 26.1458 30.4167C26.3282 30.4167 26.503 30.3442 26.632 30.2153C26.7609 30.0864 26.8333 29.9115 26.8333 29.7292V21.9375C26.8333 21.7552 26.7609 21.5803 26.632 21.4514C26.503 21.3224 26.3282 21.25 26.1458 21.25Z"
                    fill="white"
                  />
                </svg>
                <div className="flex flex-col justify-start items-center space-y-px font-bold text-xs text-[#1A1A1A]">
                  <p>Remove</p>
                  <p>Customer</p>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

CustomerDetails.defaultProps = {
  contact: null,
};

CustomerDetails.propTypes = {
  contact: PropTypes.objectOf(PropTypes.string),
  setShowCustomerDetails: PropTypes.func.isRequired,
  setShowDeleteCustomerPrompt: PropTypes.func.isRequired,
};
