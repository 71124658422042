import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Layout from "../../../components/layout/V2/Layout";
import Button from "../../../components/forms/Button";
import LabelledInput from "../../../components/forms/LabelledInput";
import LabelledTextarea from "../../../components/forms/LabelledTextarea";
import DatePickerInput from "../../../components/forms/DatePickerInput";
import { useOffer } from "../../../utils/Contexts/V2/OfferContext";

export default function EditOfferDetails() {
  const {
    offers,
    newOfferHandler,
    isCreatingOffer,
    setNewOffer,
    editOfferHandler,
  } = useOffer();

  const { id } = useParams();
  const navigate = useNavigate();

  const offerToBeEdited = offers.filter((item) => item.id.toString() === id);

  useEffect(() => {
    setNewOffer({
      title: offerToBeEdited[0].title,
      description: offerToBeEdited[0].description,
      is_active: false,
      start_date: new Date(offerToBeEdited[0].start_date).toISOString(),
      end_date: new Date(offerToBeEdited[0].end_date).toISOString(),
    });
  }, []);

  return (
    <Layout headerTitle="Create Offers" header>
      <div className="relative w-full h-screen scroll-smooth pt-20 flex flex-col items-center justify-between">
        <div className="px-4 flex flex-col justify-center items-center space-y-4">
          <div className="relative w-[15.56rem] h-1 rounded-lg bg-primary-20">
            <div className="w-[25%] bg-primary-50 h-1 rounded-lg" />
          </div>
          <p className="px-7 font-normal text-base font-sm text-center">
            You can give details of your flash sales, special offers & holiday
            deals here.
          </p>
          <div className="flex flex-col justify-start items-center space-y-6 w-full">
            <LabelledInput
              id="title"
              placeholder="Offer name"
              label="Offer name"
              name="title"
              defaultValue={offerToBeEdited[0].title}
              rules={{ required: true }}
              onChange={newOfferHandler}
            />
            <LabelledTextarea
              label="description"
              placeholder="Description"
              name="description"
              defaultValue={offerToBeEdited[0].description}
              rules={{ required: true, maxLength: 100 }}
              id="description"
              onChange={newOfferHandler}
            />
            <DatePickerInput
              label="Start date"
              type="date"
              placeholder="Start date"
              name="start_date"
              defaultValue={moment(offerToBeEdited[0].start_date).format(
                "yyyy-MM-DD"
              )}
              rules={{ required: true, maxLength: 100 }}
              id="start_date"
              onChange={newOfferHandler}
            />
            <DatePickerInput
              label="End date"
              type="date"
              placeholder="End date"
              name="end_date"
              defaultValue={moment(offerToBeEdited[0].end_date).format(
                "yyyy-MM-DD"
              )}
              rules={{ required: true, maxLength: 100 }}
              id="end_date"
              onChange={newOfferHandler}
            />
          </div>
        </div>

        <div className="relative p-4 w-full shadow-[0_-2px_8px_rgba(0, 0, 0, 0.3)] border border-t-solid border-t-[#D1D9DD]">
          <Button
            className="font-semibold flex justify-center items-center"
            onClick={() => editOfferHandler(navigate, id)}
          >
            {isCreatingOffer ? `Updating Offer..` : `Continue`}
          </Button>
        </div>
      </div>
    </Layout>
  );
}
