import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import { toast } from "react-hot-toast";
import PropTypes from "prop-types";
import {
  createNewContact,
  getAllContacts,
  deleteContact,
} from "../../requests-v2";
import { useAuth } from "./AuthenticationContext";

const ContactContext = createContext(null);

function useContact() {
  return useContext(ContactContext);
}

function ContactProvider({ children }) {
  const { isAuthenticated, user } = useAuth();
  const countryCode = user?.country?.country_code;
  const [contactData, setContactData] = useState({
    full_name: "",
    phone_number: "",
  });
  const [contacts, setContacts] = useState(null);
  const [formErrors, setFormErrors] = useState({
    full_name: "",
    phone_number: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [showAddCustomerPrompt, setShowAddCustomerPrompt] = useState(false);
  const [showContactErrorPopUp, setShowContactErrorPopUp] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const contactDataHandler = (e) => {
    if (!e.target.value) {
      setFormErrors({
        ...formErrors,
        [e.target.name]: "This field is required",
      });
    }
    if (e.target.value) {
      if (e.target.name === "full_name") {
        setContactData({ ...contactData, full_name: e.target.value.trim() });
        setFormErrors({ ...formErrors, [e.target.name]: "" });
      } else if (
        e.target.name === "phone_number" &&
        (/^234\d{10}$/.test(e.target.value) ||
          /^254\d{9}$/.test(e.target.value))
      ) {
        setContactData({ ...contactData, phone_number: e.target.value.trim() });
        setFormErrors({ ...formErrors, [e.target.name]: "" });
      } else {
        setFormErrors({
          ...formErrors,
          phone_number: `Invalid phone number. Phone number must be ${
            countryCode === "KE" ? "254XXXXXXXXX" : "234XXXXXXXXXX"
          }`,
        });
      }
    }
  };

  const createContact = async (callback) => {
    if (
      !Object.values(contactData).some((value) => value === "") &&
      Object.values(formErrors).every((value) => value === "")
    ) {
      setIsLoading(true);
      createNewContact(contactData)
        .then((res) => {
          setIsLoading(false);
          setIsModified(true);
          callback();
          return Promise.resolve(res);
        })
        .catch((err) => {
          console.log(err.response.data.detail);
          setIsLoading(false);
          setShowContactErrorPopUp(true);
          setErrorMessage(err.response.data.detail);
          setTimeout(() => {
            setShowContactErrorPopUp(false);
          }, 3000);
          return Promise.reject(err);
        });
    } else {
      toast.error(
        "Customer data is required. Please ensure no fields are left empty and contain valid data"
      );
    }
  };

  const deleteContactHandler = async (id, callback) => {
    setIsDeleting(true);
    deleteContact(id)
      .then((res) => {
        setIsDeleting(false);
        setIsModified(true);
        callback(false);
        return Promise.resolve(res);
      })
      .catch((error) => {
        setIsDeleting(false);
        setErrorMessage("Customer data is required");
        setShowContactErrorPopUp(true);
        setTimeout(() => {
          setShowContactErrorPopUp(false);
        }, 3000);
        return Promise.reject(error);
      });
  };

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      getAllContacts()
        .then((res) => {
          setIsLoading(false);
          setContacts(res.data.items);
          setIsModified(false);
          return Promise.resolve(res);
        })
        .catch((error) => {
          setIsLoading(false);
          setContacts(null);
          return Promise.reject(error);
        });
    }
  }, [isModified, isAuthenticated, user]);

  const value = useMemo(() => ({
    isLoading,
    showContactErrorPopUp,
    errorMessage,
    contacts,
    formErrors,
    showAddCustomerPrompt,
    isDeleting,
    createContact,
    contactDataHandler,
    deleteContactHandler,
    setShowAddCustomerPrompt,
  }));

  return (
    <ContactContext.Provider value={value}>{children}</ContactContext.Provider>
  );
}

export { useContact, ContactProvider };

ContactProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
