/* eslint-disable react/forbid-prop-types */
import React from "react";
import { useStateMachine } from "little-state-machine";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment/moment";
import classNames from "classnames";
import { useQuery } from "@tanstack/react-query";

import { useBusinessContext } from "../../utils/Contexts/V2/BusinessContext";
import { useAuth } from "../../utils/Contexts/V2/AuthenticationContext";
import { updateAdJourney, DEFAULT_STORE_VALUE } from "../../utils/stateMachine";
import Dropdown from "../Dropdown";
import truncateText from "../../utils/truncateText";
import { getFacebookAdStatistics } from "../../utils/requests-v2";

export default function FacebookAdDetails({
  ad,
  handleDeleteAd,
  showMenu = true,
  handleShowAdStats,
}) {
  const { user } = useAuth();
  const { business } = useBusinessContext();
  const { actions } = useStateMachine({ updateAdJourney });
  const navigate = useNavigate();

  function handleEditDraft(id) {
    const draftAds = business.facebookAdsInsights.filter(
      (advert) => advert.id === id
    );

    const draftAd = draftAds[0];

    const payload = {
      id: draftAd.id,
      isEditing: true,
      description: draftAd.body_text,
      headline: draftAd.header_text,
      // image: draftAd.image_url,
      is_draft: true,
      url: draftAd.url,
      ad_type: draftAd.ad_type_id,
      audienceData: {
        startAge:
          parseInt(draftAd.age_min, 10) ||
          DEFAULT_STORE_VALUE.adJourney.audienceData.startAge,
        endAge:
          parseInt(draftAd.age_max, 10) ||
          DEFAULT_STORE_VALUE.adJourney.audienceData.endAge,
        gender:
          draftAd.gender || DEFAULT_STORE_VALUE.adJourney.audienceData.gender,
        location: draftAd.location || "All",
        goal: draftAd.ai_goal,
        interests: draftAd.ai_target_audience,
      },
      durationData: {
        startDate:
          draftAd.start_date ||
          DEFAULT_STORE_VALUE.adJourney.durationData.startDate,
        endDate:
          draftAd.end_date ||
          DEFAULT_STORE_VALUE.adJourney.durationData.endDate,
        days:
          draftAd.duration || DEFAULT_STORE_VALUE.adJourney.durationData.days,
      },
      stepsDone: 1,
      facebookPageId: draftAd.facebook_page_id,
    };

    actions.updateAdJourney(payload);

    navigate("/ads/create/audience");
  }

  return (
    <div className="border rounded-lg p-2">
      <div className="flex space-x-4 items-start py-2">
        <div className="h-20 w-20 flex-shrink-0">
          {ad.images?.[0].image_file ? (
            <img
              src={ad.images?.[0].image_file}
              alt={user.business_name}
              className="object-cover h-20 w-20"
            />
          ) : (
            <div className="h-20 w-20 flex flex-col items-center justify-center bg-gray-300">
              <p className="text-[10px]">NO IMAGE</p>
            </div>
          )}
        </div>
        <div className="flex-grow">
          <p
            className="font-semibold text-sm text-[#1A1A1A]"
            title={ad.header_text}
          >
            {truncateText(ad.header_text, 8)}
          </p>
          <p className="text-xs text-[#566370]" title={ad.body_text}>
            {truncateText(ad.body_text)}
          </p>
        </div>
        {showMenu
          ? ad.status === "IS_DRAFT" && (
              <Dropdown
                trigger={
                  <button type="button">
                    <svg
                      viewBox="0 0 21 21"
                      fill="currentColor"
                      height="1em"
                      width="1em"
                    >
                      <g fill="currentColor" fillRule="evenodd">
                        <path d="M11.5 10.5 A1 1 0 0 1 10.5 11.5 A1 1 0 0 1 9.5 10.5 A1 1 0 0 1 11.5 10.5 z" />
                        <path d="M11.5 5.5 A1 1 0 0 1 10.5 6.5 A1 1 0 0 1 9.5 5.5 A1 1 0 0 1 11.5 5.5 z" />
                        <path d="M11.5 15.5 A1 1 0 0 1 10.5 16.5 A1 1 0 0 1 9.5 15.5 A1 1 0 0 1 11.5 15.5 z" />
                      </g>
                    </svg>
                  </button>
                }
                menu={[
                  <button
                    type="button"
                    onClick={() => handleEditDraft(ad.id)}
                    // disabled
                  >
                    Edit Draft
                  </button>,
                  <button
                    type="button"
                    onClick={() => handleDeleteAd(ad.id)}
                    disabled
                  >
                    Delete Draft
                  </button>,
                ]}
                menuPosition="left"
              />
            )
          : null}
        {showMenu
          ? (ad.status === "ACTIVE" || ad.status === "COMPLETED") && (
              <Dropdown
                trigger={
                  <button type="button">
                    <svg
                      viewBox="0 0 21 21"
                      fill="currentColor"
                      height="1em"
                      width="1em"
                    >
                      <g fill="currentColor" fillRule="evenodd">
                        <path d="M11.5 10.5 A1 1 0 0 1 10.5 11.5 A1 1 0 0 1 9.5 10.5 A1 1 0 0 1 11.5 10.5 z" />
                        <path d="M11.5 5.5 A1 1 0 0 1 10.5 6.5 A1 1 0 0 1 9.5 5.5 A1 1 0 0 1 11.5 5.5 z" />
                        <path d="M11.5 15.5 A1 1 0 0 1 10.5 16.5 A1 1 0 0 1 9.5 15.5 A1 1 0 0 1 11.5 15.5 z" />
                      </g>
                    </svg>
                  </button>
                }
                menu={[
                  <button
                    type="button"
                    onClick={() => handleShowAdStats(ad.id)}
                    // disabled
                  >
                    View Stats
                  </button>,
                  ...(ad.effective_url
                    ? [
                        <a
                          href={ad.effective_url}
                          className="p-2 block cursor-pointer"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Ad
                        </a>,
                      ]
                    : []),
                ]}
                menuPosition="left"
              />
            )
          : null}
      </div>
      <div className="flex justify-between text-[10px] sm:text-xs md:text-sm">
        <div className="flex flex-col border-r px-1 pr-2">
          <div className="flex items-center space-x-1">
            <span
              className={classNames({
                "h-[10px] w-[10px] rounded-full inline-block": true,
                "bg-green-500": ad.status === "ACTIVE",
                "bg-grey-50": ad.status === "COMPLETED",
                "bg-red-60": ad.status === "REJECTED",
                "bg-yellow-30": ad.status === "IN_REVIEW",
                "bg-red-50": ad.status === "IS_DRAFT",
              })}
            />
            <p>{ad.status.replace("_", " ")}</p>
          </div>
          <p>Status</p>
        </div>
        <div className="flex flex-col border-r px-1 pr-2">
          <p>{moment(ad.end_date).format("YYYY-MM-DD")}</p>
          <p>Date</p>
        </div>
        <div className="flex flex-col border-r px-1 pr-2">
          <p>{ad.impressions || 0}</p>
          <p>Reached Customers</p>
        </div>
        <div className="flex flex-col px-1">
          <p>{ad.clicks || 0}</p>
          <p>Clicks</p>
        </div>
      </div>
    </div>
  );
}

// Dummy data for testing. Should delete
const stats = [
  {
    ad_id: 276,
    clicks: 10,
    impressions: 4,
    engagements: 5,
    reach: 12,
    ctr: 1,
    comments: 1,
    created_at: "2023-07-12",
    id: 23423423,
  },
  {
    ad_id: 276,
    clicks: 14,
    impressions: 14,
    engagements: 52,
    reach: 11,
    ctr: 1,
    comments: 3,
    created_at: "2023-07-13",
    id: 23423425,
  },
  {
    ad_id: 276,
    clicks: 5,
    impressions: 34,
    engagements: 25,
    reach: 52,
    ctr: 1,
    comments: 1,
    created_at: "2023-07-14",
    id: 23423428,
  },
];

function AdStatsCard({ adStats }) {
  return (
    <div className="text-[#1A1A1A]">
      <p className="text-sm mb-1">
        On {moment(adStats.created_at).format("LL")}
      </p>
      <div className="flex text-[10px] md:text-sm">
        <div className="flex-col border-r px-2 pr-2 flex-grow">
          <p className="font-semibold text-sm">{adStats.impressions}</p>
          <p className="text-[#566370] font-regular">Impressions</p>
        </div>
        <div className="flex-col border-r px-2 pr-2 flex-grow">
          <p className="font-semibold text-sm">{adStats.engagements}</p>
          <p className="text-[#566370] font-regular">Engagements</p>
        </div>
        <div className="flex-col border-r px-2 pr-2 flex-grow">
          <p className="font-semibold text-sm">{adStats.reach}</p>
          <p className="text-[#566370] font-regular">Reach</p>
        </div>
        <div className="flex-col px-2 pr-2 flex-grow">
          <p className="font-semibold text-sm">{adStats.clicks}</p>
          <p className="text-[#566370] font-regular">Clicks</p>
        </div>
      </div>
    </div>
  );
}

export function FacebookAdStatistics({
  adDetails,
  onHideAdStats,
  onShowAdStats,
}) {
  const { data: adStats } = useQuery(
    ["ad-insights/", adDetails.id],
    () =>
      getFacebookAdStatistics({ adId: adDetails.id })
        .then((res) => res.data)
        .catch((error) => {
          console.error("Could not get ad stats : ", error);
          return Promise.resolve(stats);
        }),
    {
      enabled: !!adDetails.id,
    }
  );

  return (
    <div>
      {console.log(adDetails.id)}
      <FacebookAdDetails
        ad={adDetails}
        showMenu={false}
        handleShowAdStats={onShowAdStats}
        handleHideAdStats={onHideAdStats}
      />

      <div className="mt-4 mb-2">
        {adStats?.map((stat) => (
          <div className="my-2" key={stat.created_at}>
            <AdStatsCard adStats={stat} />
          </div>
        ))}
      </div>
    </div>
  );
}

FacebookAdStatistics.propTypes = {
  adDetails: PropTypes.object.isRequired,
  onShowAdStats: PropTypes.func,
  onHideAdStats: PropTypes.func,
};

FacebookAdStatistics.defaultProps = {
  onShowAdStats: () => console.error("Not implemented"),
  onHideAdStats: () => console.error("Not implemented"),
};

AdStatsCard.propTypes = {
  adStats: PropTypes.object.isRequired,
};

FacebookAdDetails.propTypes = {
  ad: PropTypes.object.isRequired,
  handleDeleteAd: PropTypes.func,
  showMenu: PropTypes.bool,
  handleShowAdStats: PropTypes.func.isRequired,
};

FacebookAdDetails.defaultProps = {
  showMenu: true,
  handleDeleteAd: () => console.error("Not implemented"),
};
