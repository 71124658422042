import React from "react";
import { useStateMachine } from "little-state-machine";
import { useNavigate } from "react-router-dom";

import Layout from "../components/layout/Layout";
import ReachCustomersIcon from "../assets/icons/reach-customers-icon.png";
import { updateState } from "../utils/stateMachine";

export default function CreateAdPage() {
  const { state, actions } = useStateMachine({ updateState });

  const navigate = useNavigate();

  return (
    <Layout
      header
      headerTitle={
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-base">Choose A Goal</h1>
          <p className="text-xs font-normal text-gray-500 text-center">
            What results would you like to get from this ad?
          </p>
        </div>
      }
      backTo="/ads"
    >
      <div className="max-w-screen-xl container mx-auto h-[75vh] md:h-[80vh] pt-24">
        <div className="px-4 py-2 text-sm text-center">
          <p>Target new and existing customers with facebook ads</p>
        </div>
        <div className="my-4">
          <div className="h-1 w-[70vw] bg-gray-200 dark:bg-gray-300 mx-auto">
            <div
              className="h-1 bg-purple-900 thryve:bg-primary-50"
              style={{ width: "25%" }}
            />
          </div>
        </div>
        <div className="px-4">
          <button
            type="button"
            className="flex items-center border px-4 py-2 space-x-2 rounded-lg mx-auto w-full"
            onClick={() => {
              actions.updateState({
                adJourney: {
                  intent: "reach",
                  channel: "facebook",
                  stepsDone: 1,
                  ...state.adJourney,
                },
              });

              navigate("/ads/create/audience");
            }}
          >
            <img src={ReachCustomersIcon} alt="Reach Customers" />
            <div className="flex flex-col items-start justify-center text-sm flex-grow text-start">
              <h3 className="font-semibold w-full">Reach More Customers</h3>
              <p className="text-xs text-gray-60 w-full">
                Get more people to visit your business profile
              </p>
            </div>
            <svg viewBox="0 0 1024 1024" height="1em" width="1em">
              <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z" />
            </svg>
          </button>
        </div>
      </div>
    </Layout>
  );
}
