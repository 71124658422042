import React, { useCallback, useState } from "react";
import Modal from "react-modal";
import { useStateMachine } from "little-state-machine";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { toast } from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import Layout from "../../components/layout/Layout";
import Button from "../../components/forms/Button";
import { updateState } from "../../utils/stateMachine";
import FacebookAdsAudienceModal from "../../components/V2/FacebookAdsAudienceModal";
import FacebookAdsAudienceDetails from "../../components/FacebookAdsAudienceDetails";
import FacebookAdsDurationModal from "../../components/FacebookAdsDurationModal.";
import FacebookAdsDurationDetails from "../../components/FacebookAdsDurationDetails";
import {
  createFacebookAd,
  generateAdDescriptionWithAi,
  updateFacebookAd,
} from "../../utils/requests-v2";
import { useAuth } from "../../utils/Contexts/V2/AuthenticationContext";

const modalStyles = {
  content: {
    inset: "auto 0 0 0",
    borderRadius: "16px 16px 0 0",
  },
  overlay: {
    backgroundColor: "#00000033",
  },
};

Modal.setAppElement("#root");

export default function AdCampaignAudiencePage() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [visibleModal, setVisibleModal] = useState(null);

  const { actions, state } = useStateMachine({ updateState });
  const { user } = useAuth();

  const openModal = useCallback((modalId) => {
    setModalIsOpen(true);
    setVisibleModal(modalId);
  }, []);

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
    setVisibleModal(null);
  }, []);

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const defaultValues = {
    header_text: user.business_name,
    body_text: state.adJourney.description || "",
    duration: state.adJourney.durationData.days.toString(),
    start_date:
      typeof state.adJourney.durationData.startDate === "string"
        ? moment(state.adJourney.durationData.startDate).format(
            "YYYY-MM-DD HH:mm:ss"
          )
        : state.adJourney.durationData.startDate.format("YYYY-MM-DD HH:mm:ss"),
    end_date:
      typeof state.adJourney.durationData.endDate === "string"
        ? moment(state.adJourney.durationData.endDate).format(
            "YYYY-MM-DD HH:mm:ss"
          )
        : state.adJourney.durationData.endDate.format("YYYY-MM-DD HH:mm:ss"),
    is_draft: true,
    age_max: state.adJourney.audienceData.endAge.toString(),
    age_min: state.adJourney.audienceData.startAge.toString(),
    gender: state.adJourney.audienceData.gender,
    ad_type: 1,
    location: state.adJourney.audienceData.location.key || null,
    ai_goal: state.adJourney.audienceData.goal,
    ai_target_audience: state.adJourney.audienceData.interests,
  };

  const { mutateAsync: mutateCreateDraftAd, isLoading: isCreatingDraftAd } =
    useMutation((data) => createFacebookAd({ data }), {
      onSuccess: async (res) => {
        queryClient.invalidateQueries(["/facebook-ads"]);
        actions.updateState({
          adJourney: {
            ...state.adJourney,
            isEditing: true,
            id: res.id,
            stepsDone: 2,
          },
        });
      },
    });

  const { mutateAsync: mutateEditDraftAd } = useMutation(
    (data) => updateFacebookAd({ data, id: data.id }),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["/facebook-ads"]);
        actions.updateState({
          adJourney: {
            ...state.adJourney,
            stepsDone: 2,
          },
        });
      },
    }
  );

  const proceed = useCallback(async () => {
    const startDate =
      typeof state.adJourney.durationData.startDate === "string"
        ? moment(state.adJourney.durationData.startDate)
        : state.adJourney.durationData.startDate;

    if (startDate < moment().clone().subtract(1, "day")) {
      toast.error(
        "Start date has passed. Please set a day not earlier than tomorrow."
      );
      return;
    }

    if (!state.adJourney.audienceData.goal) {
      toast.error("Please provide a goal for your ad");
      return;
    }

    if (!state.adJourney.audienceData.interests) {
      toast.error("Please provide the interests/target audience for your ad");
      return;
    }

    actions.updateState({
      adJourney: {
        ...state.adJourney,
        stepsDone: 2,
      },
    });

    const adsData = {
      header_text: user.business_name,
      body_text: state.adJourney.description || "",
      duration: state.adJourney.durationData.days.toString(),
      start_date:
        typeof state.adJourney.durationData.startDate === "string"
          ? moment(state.adJourney.durationData.startDate).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : state.adJourney.durationData.startDate.format(
              "YYYY-MM-DD HH:mm:ss"
            ),
      end_date:
        typeof state.adJourney.durationData.endDate === "string"
          ? moment(state.adJourney.durationData.endDate).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : state.adJourney.durationData.endDate.format("YYYY-MM-DD HH:mm:ss"),
      is_draft: true,
      age_max: state.adJourney.audienceData.endAge.toString(),
      age_min: state.adJourney.audienceData.startAge.toString(),
      gender: state.adJourney.audienceData.gender,
      ad_type: 1,
      location: state.adJourney.audienceData.location.key || null,
      ai_goal: state.adJourney.audienceData.goal,
      ai_target_audience: state.adJourney.audienceData.interests,
    };

    // If the ad was not already being edited, we save it as a draft and persist the ad id
    if (!state.adJourney.isEditing) {
      await mutateCreateDraftAd(adsData);
    } else {
      // Since the ad is being edited, we first check if the goals and interests have changed
      if (
        defaultValues.ai_goal !== state.adJourney.audienceData.goal ||
        defaultValues.ai_target_audience !==
          state.adJourney.audienceData.interests
      ) {
        console.log("The goal has changed");
        const description = await generateAdDescriptionWithAi({
          goal: state.adJourney.audienceData.goal,
          target_audience: state.adJourney.audienceData.interests,
          age: `${state?.adJourney.audienceData.startAge}-${state?.adJourney.audienceData.endAge}`,
          gender: state.adJourney.audienceData.gender,
          location: `${state?.adJourney.audienceData.location.name}, ${user.country.name}`,
        });

        actions.updateState({
          adJourney: {
            ...state.adJourney,
            body_text: description.data?.ad_description || "",
          },
        });
        // adsData.description = description.data?.ad_description || "";
      }
      adsData.id = state.adJourney.id;

      // When editing, the API expects to be supplied with ad_type_id and not ad_type
      // https://ajua.atlassian.net/browse/TAP-1500
      delete adsData.ad_type;

      adsData.ad_type = 1;
      // Then we update the draft ad
      await mutateEditDraftAd(adsData);
    }

    navigate("/ads/create/design");
  });

  return (
    <Layout
      header
      headerTitle={
        <div className="flex flex-col items-center justify-center">
          <p className="text-xs font-normal text-gray-500 text-center">
            Create Ad
          </p>
          <h2 className="text-base">Define Your Ad Reach</h2>
        </div>
      }
    >
      <div className="pt-20 max-w-screen-xl container mx-auto px-4 h-screen flex flex-col">
        <div className="my-4">
          <div className="h-1 w-[70vw] bg-gray-200 dark:bg-gray-300 mx-auto">
            <div
              className="h-1 bg-purple-900 thryve:bg-primary-50"
              style={{ width: "50%" }}
            />
          </div>
        </div>
        <p className="text-sm text-center text-[#1A1A1A]">
          We&apos;ve created some default audience settings based on our data.
          You can change these settings below.
        </p>
        <div className="my-4 space-y-4 flex-grow">
          <FacebookAdsAudienceDetails openModal={openModal} />

          <FacebookAdsDurationDetails openModal={openModal} />
        </div>
        <Button
          type="button"
          className="mb-4"
          onClick={proceed}
          isLoading={isCreatingDraftAd}
        >
          Continue
        </Button>

        <FacebookAdsAudienceModal
          closeModal={closeModal}
          modalIsOpen={modalIsOpen && visibleModal === "audience-modal"}
          modalStyles={modalStyles}
        />

        <FacebookAdsDurationModal
          closeModal={closeModal}
          modalIsOpen={modalIsOpen && visibleModal === "duration-modal"}
          modalStyles={modalStyles}
        />
      </div>
    </Layout>
  );
}
