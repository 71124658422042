/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import Rating from "react-rating";
import LevelComponent from "./LevelComponent";
import FilledStar from "../assets/icons/filledStar.svg";
import EmptyStar from "../assets/icons/emptyStar.svg";

export default function ReviewInsightsCard({ data }) {
  return (
    <div>
      <div className="border rounded-xl px-4 py-4">
        <div className="text-center mb-4">
          <p className="text-2xl md:text-3xl font-semibold">
            {data?.averageRating}
          </p>
          <Rating
            start={0}
            stop={5}
            step={1}
            readonly
            initialRating={data?.averageRating}
            fullSymbol={
              <img src={FilledStar} className="icon" alt="filled star" />
            }
            emptySymbol={
              <img src={EmptyStar} className="icon" alt="empty star" />
            }
          />
          <p className="text-[#848792]">Based on {data?.reviewTotal} reviews</p>
        </div>

        <div className="space-y-2">
          <LevelComponent
            label="Excellent"
            total={data?.reviewTotal}
            value={data?.fiveStarReviews}
            levelClassName="h-full bg-[#4BA34B] rounded-xl"
          />

          <LevelComponent
            label="Good"
            total={data?.reviewTotal}
            value={data?.fourStarReviews}
            levelClassName="h-full bg-[#A5D633] rounded-xl"
          />

          <LevelComponent
            label="Average"
            total={data?.reviewTotal}
            value={data?.threeStarReviews}
            levelClassName="h-full bg-[#F6E632] rounded-xl"
          />

          <LevelComponent
            label="Fair"
            total={data?.reviewTotal}
            value={data?.twoStarReviews}
            levelClassName="h-full bg-[#F6A521] rounded-xl"
          />

          <LevelComponent
            label="Poor"
            total={data?.reviewTotal}
            value={data?.oneStarReviews}
            levelClassName="h-full bg-[#EE3A10] rounded-xl"
          />
        </div>
      </div>
    </div>
  );
}

ReviewInsightsCard.propTypes = {
  data: PropTypes.object.isRequired,
};
