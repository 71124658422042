import React from "react";
import { Transition } from "@headlessui/react";
import Layout from "../../components/layout/V2/Layout";
import LabelledInput from "../../components/forms/LabelledInput";
import { useProductPhotosContext } from "../../utils/Contexts/V2/ProductPhotosContext";
import PhotoUploadConfirmation from "../../components/V2/PhotoUploadConfirmation";

function AddPhoto() {
  const {
    setTitle,
    setBusinessPhoto,
    businessPhoto,
    isLoading,
    uploadProductPhoto,
    showError,
    showUploadConfirmation,
  } = useProductPhotosContext();

  return (
    <Layout header headerTitle="Add product photo">
      <div className="relative w-full h-screen scroll-smooth pt-20 flex flex-col justify-between">
        <Transition
          className="fixed -bottom-12 left-0 z-20 w-full"
          show={showUploadConfirmation}
          enterFrom=" translate-y-0"
          enterTo=" -translate-y-12"
          leaveFrom=" -translate-y-12"
          leaveTo=" translate-y-0"
        >
          <PhotoUploadConfirmation />
        </Transition>
        <div className="flex flex-col space-y-6 justify-start items-center w-full pt-6 px-4">
          <div className="relative w-full h-[15.4rem] border border-solid border-[#D1D9DD] rounded-lg flex justify-center items-center">
            <input
              type="file"
              accept="image/*"
              className=" absolute z-20 w-full h-full outline-none overflow-hidden"
              onChange={(event) => setBusinessPhoto(event.target.files[0])}
            />
            {businessPhoto ? (
              <img
                alt="not fount"
                className="absolute z-10 w-full h-full object-contain"
                src={URL.createObjectURL(businessPhoto)}
              />
            ) : (
              <svg
                width="30"
                height="27"
                viewBox="0 0 30 27"
                className="absolute z-10 fill-primary-50 thryve:fill-secondary-50"
              >
                <path d="M25.9998 16V20H29.9998V22.6667H25.9998V26.6667H23.3332V22.6667H19.3332V20H23.3332V16H25.9998ZM26.0105 0C26.7412 0 27.3332 0.593333 27.3332 1.324V13.7893C26.4767 13.4868 25.5749 13.3326 24.6665 13.3333V2.66667H3.33317L3.3345 21.3333L15.7238 8.94266C15.9531 8.71264 16.2585 8.57431 16.5826 8.55368C16.9068 8.53306 17.2273 8.63156 17.4838 8.83066L17.6078 8.944L22.3358 13.6773C21.302 13.9924 20.3427 14.5135 19.5158 15.2093C18.6888 15.9051 18.0113 16.7611 17.5242 17.7259C17.037 18.6906 16.7503 19.744 16.6812 20.8225C16.6122 21.901 16.7623 22.9824 17.1225 24.0013L1.98917 24C1.63826 23.9996 1.30183 23.86 1.05382 23.6117C0.805813 23.3635 0.666504 23.0269 0.666504 22.676V1.324C0.668944 0.973837 0.809038 0.63869 1.05652 0.390957C1.304 0.143224 1.63901 0.00279287 1.98917 0H26.0105ZM8.6665 5.33333C9.37375 5.33333 10.052 5.61428 10.5521 6.11438C11.0522 6.61448 11.3332 7.29275 11.3332 8C11.3332 8.70724 11.0522 9.38552 10.5521 9.88562C10.052 10.3857 9.37375 10.6667 8.6665 10.6667C7.95926 10.6667 7.28098 10.3857 6.78088 9.88562C6.28079 9.38552 5.99984 8.70724 5.99984 8C5.99984 7.29275 6.28079 6.61448 6.78088 6.11438C7.28098 5.61428 7.95926 5.33333 8.6665 5.33333Z" />
              </svg>
            )}
          </div>
          <LabelledInput
            name="product_name"
            id="product_name"
            label="Enter product name"
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="relative p-4 w-full shadow-[0_-2px_8px_rgba(0, 0, 0, 0.3)] border border-t-solid border-t-[#D1D9DD]">
          <Transition
            className="absolute -bottom-4 left-0 z-20 w-full flex justify-center items-center"
            show={showError}
            enterFrom=" translate-y-0"
            enterTo=" -translate-y-12"
            leaveFrom=" -translate-y-12"
            leaveTo=" translate-y-0"
          >
            <p className="text-sm text-center py-2 px-4 rounded-xl bg-slate-300/[.8]">
              Photo and Title are required
            </p>
          </Transition>
          <button
            type="button"
            className="w-full h-12 text-white thryve:text-base text-base font-semibold rounded-xl bg-primary-50"
            onClick={uploadProductPhoto}
          >
            {!isLoading ? "Upload" : "Uploading..."}
          </button>
        </div>
      </div>
    </Layout>
  );
}

export default AddPhoto;
