/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { useStateMachine } from "little-state-machine";
import { useForm } from "react-hook-form";

import RangeSlider from "./forms/RangeSlider";
import Button from "./forms/Button";
import { updateAdJourney } from "../utils/stateMachine";
import { useAppContext } from "../utils/Contexts/AppContext";
import { useAuth } from "../utils/Contexts/V2/AuthenticationContext";
import LabelledInput from "./forms/LabelledInput";
import { generateAdDescriptionWithAi } from "../utils/requests-v2";
import LabelledTextarea from "./forms/LabelledTextarea";

const NigerianStates = require("../utils/NigerianStates.json");

function AiDescriptionModal({ description, handleClose, onUpdateDescription }) {
  return (
    <div>
      <div className="py-4 space-y-2">
        <h2 className="text-center">Ad Description</h2>
        <p className="text-center text-xs">
          Our AI has crafted this content for you based on your data. Would you
          like to continue with it, or prefer to provide your own?
        </p>
      </div>

      <form onSubmit={onUpdateDescription}>
        <LabelledTextarea
          label="Description"
          placeholder="Description"
          value={description}
          name="description"
          readOnly
          id="descriptionInput"
        />

        <div className="space-y-2">
          <Button type="submit">Yes, Proceed</Button>
          <Button onClick={handleClose}>No, I have my own content</Button>
        </div>
      </form>
    </div>
  );
}

export default function FacebookAdsAudience({
  modalIsOpen,
  closeModal,
  modalStyles,
}) {
  const { state, actions } = useStateMachine({ updateAdJourney });

  const [defaultValues, setDefaultValues] = useState({
    startAge: state?.adJourney?.audienceData?.startAge || 18,
    endAge: state?.adJourney?.audienceData?.endAge || 35,
    gender: state?.adJourney?.audienceData?.gender || "All",
    location: state?.adJourney?.audienceData?.location || "All",
    interests: state?.adJourney?.audienceData?.interests || "",
    goal: state?.adJourney?.audienceData?.goal || "",
  });

  const [formState, setFormState] = useState({ ...defaultValues });

  const {
    control,
    register,
    handleSubmit: onSubmit,
  } = useForm({
    defaultValues: {
      goal: defaultValues.goal,
      interests: defaultValues.interests,
    },
  });

  const [showAiPrompt, setShowAiPrompt] = useState(false);
  const [aiDescription, setAiDescription] = useState("");

  const handleCloseModal = () => {
    setShowAiPrompt(false);
    closeModal();
  };

  async function handleSubmit(data) {
    console.log("formstate : ", formState);
    console.log("data : ", data);
    actions.updateAdJourney({
      audienceData: {
        ...state.adJourney.audienceData,
        ...formState,
        ...data,
      },
    });

    // We need to update the AI description when the goals or interests change
    const updatePayload = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(formState)) {
      if (value !== defaultValues[key]) {
        updatePayload[key] = value;
      }
    }

    if (defaultValues.interests !== data.interests) {
      updatePayload.interests = data.interests;
    } else if (defaultValues.goal !== data.goal) {
      updatePayload.goal = data.goal;
    }

    // Get new AI ad copy if changes were made
    if (Object.keys(updatePayload).length) {
      // console.log("Changes were made : ", updatePayload)
      const description = await generateAdDescriptionWithAi({
        goal: data.goal,
        age: `${formState.startAge}-${formState.endAge}`,
        gender: formState.gender,
        location: formState.location,
        target_audience: data.interests,
      });

      setAiDescription(description.data.ad_description);

      setShowAiPrompt(true);
    } else {
      handleCloseModal();
    }
  }

  const { appTheme } = useAppContext();

  const { user } = useAuth();

  const onUpdateDescription = async (useGeneratedDescription = true) => {
    if (useGeneratedDescription) {
      actions.updateAdJourney({
        description: aiDescription,
      });
    } else {
      setAiDescription("");
      actions.updateAdJourney({
        description: "",
      });
    }
    handleCloseModal();
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={handleCloseModal}
      style={modalStyles}
      contentLabel="Audience Modal"
      id="audience-modal"
      shouldCloseOnOverlayClick={false}
    >
      {showAiPrompt ? (
        <AiDescriptionModal
          description={aiDescription}
          handleClose={handleCloseModal}
          onUpdateDescription={onUpdateDescription}
        />
      ) : (
        <>
          {/* Add appTheme here because the theme isn't automatically picked up in modals */}
          <form onSubmit={onSubmit(handleSubmit)} className={appTheme}>
            <fieldset className="space-y-4">
              <div className="flex justify-between items-center">
                <h2>Edit Audience</h2>
                <button type="button" onClick={closeModal}>
                  <svg
                    viewBox="0 0 1024 1024"
                    fill="currentColor"
                    height="1.5em"
                    width="1.5em"
                  >
                    <path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 00-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z" />
                    <path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                  </svg>
                </button>
              </div>
              <div className="my-4 space-y-2">
                <h5>Age</h5>
                <RangeSlider
                  className="w-full h-8"
                  defaultValue={[formState.startAge, formState.endAge]}
                  onChange={(newValues) => {
                    setFormState((currentState) => ({
                      ...currentState,
                      startAge: newValues[0],
                      endAge: newValues[1],
                    }));
                  }}
                  max={65}
                  min={18}
                />
              </div>
              <fieldset className="my-4">
                <legend>Gender</legend>

                <section className="flex items-center justify-between mt-2">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="AllGenderInput"
                      name="gender"
                      value="All"
                      checked={formState.gender === "All"}
                      onChange={(e) => {
                        setFormState((currentState) => ({
                          ...currentState,
                          gender: e.target.value,
                        }));
                      }}
                      hidden
                    />
                    <label
                      htmlFor="AllGenderInput"
                      className={`border rounded-lg px-4 py-1 cursor-pointer ${
                        formState.gender === "All" ? "bg-primary-20" : "white"
                      }`}
                    >
                      All
                    </label>
                  </div>

                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="MaleGenderInput"
                      name="gender"
                      value="Male"
                      checked={formState.gender === "Male"}
                      onChange={(e) => {
                        setFormState((currentState) => ({
                          ...currentState,
                          gender: e.target.value,
                        }));
                      }}
                      hidden
                    />
                    <label
                      htmlFor="MaleGenderInput"
                      className={`border rounded-lg px-4 py-1 cursor-pointer ${
                        formState.gender === "Male" ? "bg-primary-20" : "white"
                      }`}
                    >
                      Male
                    </label>
                  </div>

                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="FemaleGenderInput"
                      name="gender"
                      value="Female"
                      checked={formState.gender === "Female"}
                      onChange={(e) => {
                        setFormState((currentState) => ({
                          ...currentState,
                          gender: e.target.value,
                        }));
                      }}
                      hidden
                    />
                    <label
                      htmlFor="FemaleGenderInput"
                      className={`border rounded-lg px-4 py-1 cursor-pointer ${
                        formState.gender === "Female"
                          ? "bg-primary-20"
                          : "white"
                      }`}
                    >
                      Female
                    </label>
                  </div>
                </section>
              </fieldset>

              <div>
                <label htmlFor="locationInput">Location</label>
                <select
                  className="form-select appearance-none block w-full h-[48px] px-3 py-1.5 text-sm font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded-lg transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-purple-40 focus:outline-none focus:shadow mt-2"
                  aria-label="Select Location"
                  required
                  id="locationInput"
                  name="location"
                  value={formState.location}
                  onChange={(e) =>
                    setFormState((currentState) => ({
                      ...currentState,
                      location: e.target.value,
                    }))
                  }
                >
                  <option value="All">All States</option>
                  {user.country.country_code === "NG"
                    ? NigerianStates.map((region) => (
                        <option value={region.value}>{region.label}</option>
                      ))
                    : null}
                </select>
              </div>

              <LabelledInput
                name="goal"
                placeholder="What is the goal of this ad?"
                label="What is the goal of this ad?"
                rules={{ required: true }}
                characterLimit={25}
                showCharacterCount
                control={control}
                register={register}
              />

              <div className="flex flex-col">
                <LabelledInput
                  name="interests"
                  placeholder="Add interests/topics"
                  label="Add interests/topics"
                  register={register}
                />
                <span className="text-xs text-gray-400">
                  Use a comma to add new interests
                </span>
              </div>

              <hr />

              <div className="flex justify-between">
                <Button type="submit" size="sm">
                  Update
                </Button>
              </div>
            </fieldset>
          </form>
        </>
      )}
    </Modal>
  );
}

AiDescriptionModal.propTypes = {
  description: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  onUpdateDescription: PropTypes.func.isRequired,
};

FacebookAdsAudience.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalStyles: PropTypes.object.isRequired,
};
