import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Layout from "../../../components/layout/Layout";
import Bank from "../../../assets/icons/BankAccounts.svg";
import Facebook from "../../../assets/icons/FacebookAccount.svg";
import { useConnectAccount } from "../../../utils/Contexts/V2/ConnectAccountContext";
import { usePaymentInfoContext } from "../../../utils/Contexts/V2/PaymentInfoContext";
import Spinner from "../../../components/Spinner";

const CONNECTED_ACCOUNT_CONTENT = (status, connectedAccounts) => [
  {
    name: "Facebook",
    icon: Facebook,
    desc: "Create and manage Facebook Ads for your business right from the tappi app and track their performance seamlessly.",
    status: status.facebook,
    link:
      connectedAccounts?.length > 0
        ? "/account/connected-accounts/configure-facebook"
        : "/account/connected-accounts/facebook",
  },
  {
    name: "Bank Accounts",
    icon: Bank,
    desc: "Create and manage Facebook Ads for your business right from the tappi app and track their performance seamlessly.",
    status: status.bank,
    link: "/account/my-bank-details",
  },
];

export default function ConnectedAccounts() {
  const { isLoadingAccounts, connectedAccounts } = useConnectAccount();
  const { paymentInfo } = usePaymentInfoContext();
  const status = {
    facebook: connectedAccounts?.length > 0,
    bank: paymentInfo?.valid,
  };
  return (
    <Layout header headerTitle="Connected Accounts" backTo="/account">
      {isLoadingAccounts ? (
        <div className="h-screen w-screen flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className="relative h-screen pt-20 max-w-screen-xl container mx-auto flex flex-col justify-between items-center">
          <div className="flex flex-col space-y-[1.5rem] items-center w-full px-4">
            <p className="text-[0.875rem] text-[#1A1A1A] text-center">
              Manage and monitor all the external accounts and platforms
              integrated with your tappi account.
            </p>
            <div className="w-full flex flex-col justify-start space-y-[1.5rem]">
              {CONNECTED_ACCOUNT_CONTENT(status, connectedAccounts).map(
                (content) => (
                  <ConnectedAccountsCards
                    key={content.name}
                    accounts={content}
                  />
                )
              )}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

function ConnectedAccountsCards({ accounts }) {
  return (
    <div className="w-full p-[1rem] flex flex-col justify-start items-start space-y-[0.62rem] bg-white rounded-[0.75rem] shadow-[0px_1px_3px_1px_rgba(144,_47,_240,_0.08)]">
      <div className="flex flex-row justify-start items-center space-x-[0.5rem]">
        <img
          src={accounts.icon}
          alt={accounts.name}
          className="w-[1.25rem] h-[1.25rem]"
        />
        <p className="text-[#1A1A1A] text-[1rem] font-semibold">
          {accounts.name}
        </p>
      </div>
      <p className="text-[#1A1A1A] text-[0.875rem] leading-[1.25rem]">
        {accounts.desc}
      </p>
      <div className="flex flex-row justify-between items-center w-full">
        <p
          className={`${
            accounts.status ? "text-[#12B76A]" : "text-[#F04438]"
          } text-[0.75rem] font-semibold`}
        >
          {accounts.status ? "Connected" : "Not Connected"}
        </p>
        <Link
          to={`${accounts.link}`}
          className="flex flex-row justify-start items-center space-x-[0.25rem]"
        >
          <p className="text-[#006187] text-[0.875rem] font-semibold">
            Configure
          </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
          >
            <path
              d="M3.30799 1.02253C3.19082 1.13973 3.125 1.29868 3.125 1.46441C3.125 1.63013 3.19082 1.78908 3.30799 1.90628L6.40174 5.00003L3.30799 8.09378C3.19414 8.21166 3.13115 8.36953 3.13257 8.53341C3.13399 8.69728 3.19972 8.85404 3.3156 8.96992C3.43148 9.0858 3.58824 9.15153 3.75212 9.15295C3.91599 9.15438 4.07386 9.09138 4.19174 8.97753L7.72737 5.44191C7.84454 5.3247 7.91036 5.16576 7.91036 5.00003C7.91036 4.8343 7.84454 4.67536 7.72737 4.55816L4.19174 1.02253C4.07454 0.905361 3.91559 0.839539 3.74987 0.839539C3.58414 0.839539 3.4252 0.905361 3.30799 1.02253Z"
              fill="#006187"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
}

ConnectedAccountsCards.propTypes = {
  accounts: PropTypes.shape({
    icon: PropTypes.string,
    name: PropTypes.string,
    desc: PropTypes.string,
    status: PropTypes.string,
    link: PropTypes.string,
  }).isRequired,
};
