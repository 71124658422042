import React, { useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Loader } from "@googlemaps/js-api-loader";
import { useAppContext } from "../../utils/Contexts/AppContext";

function ControlCard({ inputRef }) {
  return (
    <div className="pac-card mx-2 mt-4 pr-4 w-full rounded-lg" id="pac-card">
      <input
        id="pac-input"
        type="text"
        placeholder="Search Map"
        ref={inputRef}
        style={{
          height: 48,
        }}
        className="rounded-lg border border-gray-300 p-2 w-full text-lg"
      />
    </div>
  );
}

function GoogleMap({ lat, lng, countryCode, setLocationInfo }) {
  const googlemap = useRef();
  const inputRef = useRef();
  const { appTheme } = useAppContext();

  const initMap = useCallback(
    (google) => {
      const map = new google.maps.Map(googlemap.current, {
        center: { lat, lng },
        zoom: 13,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        zoomControl: false,
      });
      const card = document.getElementById("pac-card");
      const input = inputRef.current;

      const options = {
        componentRestriction: {
          country: countryCode,
        },
        fields: ["formatted_address", "geometry", "name"],
        types: ["establishment"],
      };

      map.controls[google.maps.ControlPosition.TOP_LEFT].push(card);

      const autocomplete = new google.maps.places.Autocomplete(input, options);

      // Bind the map's bounds (viewport) property to the autocomplete object,
      // so that the autocomplete requests use the current map bounds for the
      // bounds option in the request.
      autocomplete.bindTo("bounds", map);

      const marker = new google.maps.Marker({
        map,
        position: { lat, lng },
      });

      autocomplete.addListener("place_changed", () => {
        // infowindow.close();
        marker.setVisible(false);

        const place = autocomplete.getPlace();

        if (!place.geometry || !place.geometry.location) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          console.error(`No details available for input: '${place.name}'`);
          return;
        }

        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(17);
        }

        marker.setPosition(place.geometry.location);
        marker.setVisible(true);

        setLocationInfo(place);
      });
    },
    [countryCode, lat, lng]
  );

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      version: "weekly",
      libraries: ["places"],
    });

    loader.load().then(() => {
      initMap(window.google);
    });
  }, [initMap]);

  return (
    <div>
      <div className={appTheme}>
        <div
          id="map"
          ref={googlemap}
          className="h-[calc(100vh-58px)] w-screen"
        />
        <ControlCard inputRef={inputRef} />
      </div>
    </div>
  );
}

export default GoogleMap;

ControlCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  inputRef: PropTypes.object.isRequired,
};

GoogleMap.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  countryCode: PropTypes.string.isRequired,
  setLocationInfo: PropTypes.func.isRequired,
};
