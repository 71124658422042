/* eslint-disable camelcase */
import axios from "axios";

const API_URL = process.env.REACT_APP_THRYVE_API_URL;
const BRIDGE_URL = process.env.REACT_APP_BRIDGE_API_URL;
const BRIDGE_ACCESS_KEY = process.env.REACT_APP_BRIDGE_ACCESS_KEY;
const ENGAUGE_ACCESS_KEY = process.env.REACT_APP_ENGAUGE_ACCESS_KEY;

// API PATHS
const API_PATHS = {
  getMerchantOTP: "/users/merchants/api/v1/merchant-otp",
  loginMerchant: "/users/merchants/api/v1/merchant-login",
  registerMerchant: "/users/merchant/api/v1/merchant-registration",
  getMerchantDetails(merchantId) {
    return `/users/merchant/api/v1/merchant-details/${merchantId}`;
  },
  getProductPhotos(merchantId) {
    return `/users/merchant/api/v1/merchant-business-photo/${merchantId}`;
  },
  uploadProductPhoto(merchantId) {
    return `/users/merchant/api/v1/merchant-business-photo/${merchantId}`;
  },
  deleteProductPhoto(photoId) {
    return `/users/merchant/api/v1/merchant-business-photo/${photoId}`;
  },
  updateBusinessPhotos(merchantId) {
    return `/users/merchant/api/v1/merchant-update-photo/${merchantId}`;
  },
  updateMerchantDetails(merchantId) {
    return `/users/merchant/api/v1/merchant-update/${merchantId}`;
  },
  /**
   * @param { URLSearchParams } queryParams - Query params for the request
   * @returns {string} - The path for the request
   */
  getMerchantReviews(queryParams) {
    return `/ext/tappi/reviews?${queryParams.toString()}`;
  },
  getMerchantReviewsKE(queryParams) {
    return `/reviews?${queryParams.toString()}`;
  },
  getFacebookAdsData(merchantId) {
    return `/users/merchant/api/v1/merchant-ads/${merchantId}`;
  },
  mpesaSpend: "/ext/mpesa/tappi/transactions",
  getAllSubscriptionPackages() {
    return `/users/merchant/api/v1/subscription-packages`;
  },
  updateMerchantSubscriptionPackage(tathminiId) {
    return `/users/merchant/api/v1/update-subscription/${tathminiId}`;
  },
  makeMpesaPayment(queryParams) {
    return `/payments/api/v1/mpesa-ad-payment?${queryParams.toString()}`;
  },
  checkPromoCode(promo_code) {
    return `/payments/api/v1/promo-code/${promo_code}`;
  },
  getSubscriptionHistory(merchantId) {
    return `/users/merchant/api/v1/merchant-history/${merchantId}`;
  },
  makeSubscriptionPaymentViaFlutterwave() {
    return `https://merchant-api.ajua.com/v0/tappi-flutterwave-bank-transfer`;
  },
  flutterwavePaymentStatus() {
    return `https://merchant-api.ajua.com/v0/tappi-flutterwave-bank-transfer-status`;
  },
  getWalletDetails(phone_number) {
    return `/users/merchant/api/v1/wallet-data/${phone_number}`;
  },
  getWalletTransactionHistory(merchantId) {
    return `/users/merchant/api/v1/wallet-transactions/${merchantId}`;
  },
  topUpWallet() {
    return `/users/merchant/api/v1/wallet-top-up`;
  },
  checkPayBillPaymentStatus() {
    return `/payments/api/v1/confirm-payment`;
  },
  getMerchantOperationHours(merchantId) {
    return `/users/merchant/api/v1/operation-hours/${merchantId}`;
  },
  createMerchantOperationHours(merchantId) {
    return `/users/merchant/api/v1/operation-hours/${merchantId}`;
  },
  updateMerchantOperationHours(id) {
    return `/users/merchant/api/v1/operation-hours/${id}`;
  },
  deleteMerchantOperationHours(id) {
    return `/users/merchant/api/v1/operation-hours/${id}`;
  },
  createFacebookAd(merchantId) {
    return `/users/merchant/api/v1/merchant-ads/${merchantId}`;
  },
  consumeWalletCredits: "/users/merchant/api/v1/wallet-debit",
  updateFacebookAd(id) {
    return `/users/merchant/api/v1/merchant-ads/${id}`;
  },
  deleteFacebookAd(id) {
    return `/users/merchant/api/v1/merchant-ads/${id}`;
  },
  getCountrlyList: "/users/merchant/api/v1/country-list",
};

/**
 * Send an OTP to the user's phone number, email address or WhatsApp
 * @param {{ phoneNumber: string }} data - Contains phone number
 * @returns Promise
 */
export const getMerchantOTP = async ({ phoneNumber }) => {
  try {
    const res = await axios
      .post(
        `${API_URL}${API_PATHS.getMerchantOTP}`,
        { phone_number: phoneNumber },
        {
          withCredentials: false,
        }
      )
      .then((response) => {
        if (response.data.status === 400) {
          return Promise.reject(response.data);
        }
        return response.data;
      });
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 *
 * @param {{ username: string, password: string }} data - phone number for loggin in the user
 * @returns AxiosResponse<any>
 */
export const loginMerchant = async (data) => {
  try {
    const response = await axios
      .post(
        `${API_URL}${API_PATHS.loginMerchant}`,
        { username: data.username, password: data.password },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: false,
        }
      )
      .then((res) => res.data);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

// Fetch current user

export const getCurrentMerchant = async (merchantId) => {
  try {
    return await axios
      .get(`${API_URL}${API_PATHS.getMerchantDetails(merchantId)}`, {
        withCredentials: false,
      })
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

// Fetch product photos

export const getMerchantPhotos = async (merchantId) => {
  try {
    return await axios.get(
      `${API_URL}${API_PATHS.getProductPhotos(merchantId)}`,
      {
        withCredentials: false,
      }
    );
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

// upload product photos

export const uploadPhoto = async (merchantId, businessPhoto, title) => {
  try {
    return await axios.post(
      `${API_URL}${API_PATHS.uploadProductPhoto(merchantId)}`,
      {
        business_photo: businessPhoto,
        title,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: false,
      }
    );
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

// delete product photos

export const deletePhoto = async (photoId) => {
  try {
    return await axios.delete(
      `${API_URL}${API_PATHS.deleteProductPhoto(photoId)}`,
      {
        withCredentials: false,
      }
    );
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

// Register Merchant
/**
 * Register a merchant
 * @param {{ email: string, phone_number: string, "merchant.business_name": string, "merchant.industry": string, "merchant.country": string, "merchant.subdomain": string}} data - Data for registering business
 * @returns AxiosResponse<any>
 */
export const registerMerchant = async (data) => {
  try {
    return await axios.post(`${API_URL}${API_PATHS.registerMerchant}`, data, {
      withCredentials: false,
    });
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

// Get countryCode

export const getCountryInfo = async () => {
  try {
    return axios.get(`${BRIDGE_URL}/ext/get-country`).then((data) => data.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

// GET Industries
export async function getIndustries() {
  try {
    return axios
      .get(`${API_URL}/users/industries/api/v1/industry-list`)
      .then((data) => data.data);
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

// Update Merchant Details
/**
 * Update general merchant details
 * @param {int} merchantId - merchant id to update
 * @param {object} data - data to update
 * @returns AxiosResponse<any>
 */
export async function updateMerchantDetails(merchantId, data) {
  try {
    return await axios
      .put(`${API_URL}${API_PATHS.updateMerchantDetails(merchantId)}`, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: false,
      })
      .then((response) => response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Update Business Photos
/**
 * Update the business photos of a merchant
 * @param {int} merchantId - merchant id
 * @param {File} cover_photo - cover photo
 * @param {File} profile_photo - profile photo, or logo
 * @returns AxiosResponse<any>
 */
export async function updateBusinessPhotos(
  merchantId,
  cover_photo,
  profile_photo
) {
  const data = {
    ...(cover_photo ? { cover_photo } : {}),
    ...(profile_photo ? { profile_photo } : {}),
  };

  try {
    return await axios
      .post(`${API_URL}${API_PATHS.updateBusinessPhotos(merchantId)}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: false,
      })
      .then((response) => response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Get Merchant Reviews
/**
 *
 * @param {{ joinCode: string, limit: int, offset: int, showNumber: boolean}} args - Arguments for getting the merchant
 * @returns
 */
export async function getMerchantReviews({
  joinCode,
  limit = 20,
  offset = 0,
  showNumber = false,
}) {
  if (!joinCode) throw new Error("joinCode is required");

  const params = new URLSearchParams({ joinCode, limit, offset, showNumber });

  try {
    const response = await axios.get(
      `${BRIDGE_URL}${API_PATHS.getMerchantReviews(params)}`,
      {
        headers: {
          "x-access-key": BRIDGE_ACCESS_KEY,
        },
        withCredentials: false,
      }
    );
    return response.data.Data;
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 *
 * @param {{ joinCode: string, limit: int, offset: int, showNumber: boolean}} args - Arguments for getting the merchant
 * @returns
 */
export async function getMerchantReviewsKE({
  join_code,
  limit = 20,
  offset = 1,
  show_number = true,
}) {
  if (!join_code) throw new Error("joinCode is required");

  const params = new URLSearchParams({ join_code, limit, offset, show_number });

  try {
    const response = await axios.get(
      `${API_URL}${API_PATHS.getMerchantReviewsKE(params)}`
    );
    console.log(response.data.data);
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

// Get Facebook Ads Data
/**
 * Get Facebookds Ads Data Belonging to a merchant
 * @param {{ merchantId: int}} merchantId - Merchant Id
 * @returns AxiosResponse<any>
 */
export async function getMerchantFacebookAdsData({ merchantId }) {
  try {
    return axios
      .get(`${API_URL}${API_PATHS.getFacebookAdsData(merchantId)}`)
      .then((response) => {
        const { data } = response.data;

        data.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

        return { ...response.data, data };
      });
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * fetches mpesa spend data
 * @param { { queryParams: URLSearchParams } } data - data. Should have query params
 * @param {URLSearchParams} data.queryParams - query params <URLSearchParams> object
 * @returns {Promise<AxiosResponse<any>>}
 * */
export async function getMpesaSpend({ queryParams }) {
  try {
    return axios
      .get(`${BRIDGE_URL}${API_PATHS.mpesaSpend}?${queryParams.toString()}`, {
        headers: {
          "x-access-key": BRIDGE_ACCESS_KEY,
        },
        withCredentials: false,
      })
      .then((response) => response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Get Subscription History

export const getAllSubscriptionHistory = async (merchantId) => {
  try {
    return await axios
      .get(`${API_URL}${API_PATHS.getSubscriptionHistory(merchantId)}`, {
        withCredentials: false,
      })
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

// GET All subscription packages
export async function getSubscriptionPackages() {
  try {
    return await axios
      .get(`${API_URL}${API_PATHS.getAllSubscriptionPackages()}`, {
        withCredentials: false,
      })
      .then((response) => response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * update for subscription package
 * @param {int} tathminiId
 * @param {{subscription_id: *}} data
 * @returns AxiosResponse<any>
 */
export async function updateSubscriptionPackage(tathminiId, data) {
  try {
    return await axios
      .put(
        `${API_URL}${API_PATHS.updateMerchantSubscriptionPackage(tathminiId)}`,
        data,
        {
          withCredentials: false,
        }
      )
      .then((response) => response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Check if promo code is valid
 * @param {int} promo_code
 */
export const checkPromoCode = async (promo_code) => {
  try {
    return await axios.get(
      `${API_URL}${API_PATHS.checkPromoCode(promo_code)}`,
      {
        withCredentials: false,
      }
    );
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

/**
 * Make payment for subscription using Mpesa
 * @param {{amount: number, phone_number: (string|*)}} data
 */
export const makeMpesaPayment = async (data) => {
  const queryParams = new URLSearchParams(data);
  try {
    return await axios.post(
      `${API_URL}${API_PATHS.makeMpesaPayment(queryParams)}`,
      data,
      {
        withCredentials: false,
      }
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Make payment for subscription using Flutterwave
 * @param {{amount: number, phone_number: (string|*)}} data
 */
export const paySubscriptionFlutterwave = async (data) => {
  try {
    return await axios.post(
      `${API_PATHS.makeSubscriptionPaymentViaFlutterwave()}`,
      data,
      {
        headers: {
          Authentication: ENGAUGE_ACCESS_KEY,
        },
      }
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Make payment for subscription using Mpesa
 * @param {{transfer_reference: string}} data
 */
export const checkFlutterwaveStatus = async (data) => {
  try {
    return await axios.post(`${API_PATHS.flutterwavePaymentStatus()}`, data, {
      headers: {
        Authentication: ENGAUGE_ACCESS_KEY,
      },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Get wallet details
 * @param {string} phone_number
 */
export const getWalletDetails = async (phone_number) => {
  try {
    return await axios.get(
      `${API_URL}${API_PATHS.getWalletDetails(phone_number)}`,
      {
        withCredentials: false,
      }
    );
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

/**
 * Get wallet transaction history
 * @param {int} merchantId
 */
export const getWalletTransactionHistory = async (merchantId) => {
  try {
    return await axios.get(
      `${API_URL}${API_PATHS.getWalletTransactionHistory(merchantId)}`,
      {
        withCredentials: false,
      }
    );
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

/**
 * Make payment for subscription using Mpesa
 * @param {{amount: string, phone_number}} data
 */
export const topUpWallet = async (data) => {
  try {
    return await axios.post(`${API_URL}${API_PATHS.topUpWallet()}`, data, {
      withCredentials: false,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Make payment for subscription using Mpesa
 * @param {{amount: string, reference_number}} data
 */
export const checkPaybillPaymentStatus = async (data) => {
  try {
    return await axios.post(
      `${API_URL}${API_PATHS.checkPayBillPaymentStatus()}`,
      data,
      {
        withCredentials: false,
      }
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

// Get Mercahnt Operation Hours
export async function getMerchantOperationHours(merchantId) {
  try {
    return axios
      .get(`${API_URL}${API_PATHS.getMerchantOperationHours(merchantId)}`, {
        withCredentials: false,
      })
      .then((response) => response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Create Merchant Operation Hours
/**
 * Create new merchant operation hours
 * @param { string } merchantId
 * @param {[{ day: string, open: string, close: string }]} data
 * @returns AxiosResponse<any>
 */
export async function createMerchantOperationHours(merchantId, data) {
  try {
    return await axios
      .post(
        `${API_URL}${API_PATHS.createMerchantOperationHours(merchantId)}`,
        data,
        {
          withCredentials: false,
        }
      )
      .then((response) => response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Update Merchant Operation Hours
/**
 * Update merchant operation hours by id
 * @param {{ id: string, data: { day: string, open: string, close: string }}} param0 - Provide id of operation hours to be updated as well as the payload
 * @returns AxiosResponse<any>
 */
export async function updateMerchantOperationHours({ id, data }) {
  try {
    return axios
      .put(`${API_URL}${API_PATHS.updateMerchantOperationHours(id)}`, data, {
        withCredentials: false,
      })
      .then((response) => response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Delete Merchant Operation Hours
/**
 * Delete merchant operation hours by id
 * @param {{ id: int }} id - Id of the operation hours to be deleted
 * @returns AxiosResponse<any>
 */
export async function deleteMerchantOperationHours({ id }) {
  try {
    return axios
      .delete(`${API_URL}${API_PATHS.deleteMerchantOperationHours(id)}`, {
        withCredentials: false,
      })
      .then((response) => response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Consume wallet credits of a user
/**
 *
 * @param {{ phone_number: string, amount: string }} data - Provide phone number and amount to be consumed from user's wallet
 * @returns AxiosResponse<any>
 */
export async function consumeWalletCredits(data) {
  try {
    return axios
      .post(`${API_URL}${API_PATHS.consumeWalletCredits}`, data, {
        withCredentials: false,
      })
      .then((response) => response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Create an AD
/**
 * Request for creating a facebook ad
 * @param {{ merchantId: int, data: Object}} payload - Provide merchant id and the payload
 * @returns AxiosResponse<any>
 */
export async function createFacebookAd({ merchantId, data }) {
  try {
    return await axios
      .post(`${API_URL}${API_PATHS.createFacebookAd(merchantId)}`, data, {
        withCredentials: false,
      })
      .then((response) => {
        if (response.data.status === 400) {
          return Promise.reject(response);
        }
        return response.data;
      });
  } catch (error) {
    return Promise.reject(error);
  }
}

// Update an ad
export async function updateFacebookAd({ adId, data }) {
  try {
    return await axios
      .put(`${API_URL}${API_PATHS.updateFacebookAd(adId)}`, data, {
        withCredentials: false,
      })
      .then((response) => response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Delete an ad
export async function deleteFacebookAd({ id }) {
  try {
    return await axios
      .delete(`${API_URL}${API_PATHS.deleteFacebookAd(id)}`, {
        withCredentials: false,
      })
      .then((response) => {
        if (response.data.status !== 200) {
          return Promise.reject(response);
        }

        return response.data;
      });
  } catch (error) {
    return Promise.reject(error);
  }
}

// Get country list

export async function getCountrlyList() {
  try {
    return await axios
      .get(`${API_URL}${API_PATHS.getCountrlyList}`, {
        withCredentials: false,
      })
      .then((data) => data.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export { API_PATHS, API_URL, BRIDGE_URL, BRIDGE_ACCESS_KEY };
