import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { ButtonRadius, ButtonSize, ButtonType } from "../theme";
import { useAppContext } from "../../utils/Contexts/AppContext";

export default function Button({
  children,
  variant,
  size,
  type,
  radius,
  className,
  isLoading,
  href,
  disabled,
  ...rest
}) {
  const { appTheme } = useAppContext();

  if (href && !disabled) {
    if (href.includes("http")) {
      return (
        <a
          href={href}
          className={classNames(
            `btn btn-link inline-block font-medium leading-snug shadow-md focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out`,
            ButtonSize[size],
            ButtonType[variant],
            ButtonRadius[radius],
            className
          )}
          target="_blank"
          rel="noreferrer"
        >
          {children}
        </a>
      );
    }
    return (
      <Link
        to={href}
        className={classNames(
          `btn btn-link inline-block font-medium leading-snug shadow-md focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out`,
          ButtonSize[size],
          ButtonType[variant],
          ButtonRadius[radius],
          className
        )}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={classNames(
        `btn inline-block  font-bold leading-snug shadow-md focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out ${appTheme}`,
        ButtonSize[size],
        ButtonType[variant],
        ButtonRadius[radius],
        disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer",
        className
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
      {isLoading && (
        <div
          className="spinner-border animate-spin inline-block border-2 h-4 w-4 ml-4 rounded-full text-white"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(["primary", "outline"]),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  type: PropTypes.string,
  radius: PropTypes.oneOf(["sm", "md", "lg", "full"]),
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  href: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  variant: "primary",
  size: "md",
  type: "button",
  radius: "lg",
  className: "",
  isLoading: false,
  href: "",
  disabled: false,
};
