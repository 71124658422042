import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Transition } from "@headlessui/react";
import Layout from "../../../components/layout/Layout";
import LabelledInput from "../../../components/forms/LabelledInput";
import { useConnectAccount } from "../../../utils/Contexts/V2/ConnectAccountContext";
import SuccessfulSubmitId from "./successfulSubmitId";

const CAPTURE = `<svg width="47" height="46" viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="23.5" cy="23" r="23" fill="#F0FFFF"/>
            <path d="M21.3346 16.3333C23.168 16.3333 24.668 17.8333 24.668 19.6666C24.668 21.4999 23.168 22.9999 21.3346 22.9999C19.5013 22.9999 18.0013 21.4999 18.0013 19.6666C18.0013 17.8333 19.5013 16.3333 21.3346 16.3333ZM27.168 30.4999L28.668 31.9749C29.0846 32.3916 29.668 32.0583 29.668 31.5666V27.9999L32.0013 25.1666C32.0942 25.0428 32.1507 24.8956 32.1646 24.7414C32.1785 24.5873 32.1492 24.4323 32.08 24.2939C32.0108 24.1555 31.9044 24.0391 31.7727 23.9577C31.6411 23.8763 31.4894 23.8333 31.3346 23.8333H25.5013C24.8346 23.8333 24.418 24.6666 24.8346 25.1666L27.168 27.9999V30.4999ZM25.5013 28.5833L23.5846 26.2499C23.2513 25.8333 23.0846 25.3333 23.0846 24.8333C22.5013 24.6666 21.918 24.6666 21.3346 24.6666C17.668 24.6666 14.668 26.1666 14.668 27.9999V29.6666H25.5013V28.5833Z" fill="#006187"/>
        </svg>`;

const ASSURE = `        <svg width="47" height="46" viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="23.5" cy="23" r="23" fill="#F0FFFF"/>
            <path d="M22.9987 31.3334C21.0681 30.8473 19.4743 29.7395 18.217 28.0101C16.9598 26.2806 16.3315 24.3606 16.332 22.2501V17.1667L22.9987 14.6667L29.6654 17.1667V22.2501C29.6654 24.3612 29.0368 26.2815 27.7795 28.0109C26.5223 29.7404 24.9287 30.8479 22.9987 31.3334ZM21.332 26.3334H24.6654C24.9015 26.3334 25.0995 26.2534 25.2595 26.0934C25.4195 25.9334 25.4993 25.7356 25.4987 25.5001V23.0001C25.4987 22.764 25.4187 22.5659 25.2587 22.4059C25.0987 22.2459 24.9009 22.1662 24.6654 22.1667V21.3334C24.6654 20.8751 24.502 20.4826 24.1754 20.1559C23.8487 19.8292 23.4565 19.6662 22.9987 19.6667C22.5404 19.6667 22.1479 19.8301 21.8212 20.1567C21.4945 20.4834 21.3315 20.8756 21.332 21.3334V22.1667C21.0959 22.1667 20.8979 22.2467 20.7379 22.4067C20.5779 22.5667 20.4981 22.7645 20.4987 23.0001V25.5001C20.4987 25.7362 20.5787 25.9342 20.7387 26.0942C20.8987 26.2542 21.0965 26.334 21.332 26.3334ZM22.1654 22.1667V21.3334C22.1654 21.0973 22.2454 20.8992 22.4054 20.7392C22.5654 20.5792 22.7631 20.4995 22.9987 20.5001C23.2348 20.5001 23.4329 20.5801 23.5929 20.7401C23.7529 20.9001 23.8326 21.0979 23.832 21.3334V22.1667H22.1654Z" fill="#006187"/>
        </svg>`;

const CONTROL = `        <svg width="47" height="46" viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="23.5" cy="23" r="23" fill="#F0FFFF"/>
            <path d="M29.4987 31.8333L27.1654 29.5V29.6667H13.832V27.3333C13.832 26.8611 13.9537 26.4269 14.197 26.0308C14.4404 25.6347 14.7631 25.3328 15.1654 25.125C16.0265 24.6944 16.9015 24.3714 17.7904 24.1558C18.6793 23.9403 19.582 23.8328 20.4987 23.8333C20.6793 23.8333 20.8565 23.8369 21.0304 23.8442C21.2043 23.8514 21.3743 23.8617 21.5404 23.875L20.6654 23H20.4987C19.582 23 18.7973 22.6736 18.1445 22.0208C17.4918 21.3681 17.1654 20.5833 17.1654 19.6667V19.5L14.1654 16.5L15.3529 15.3125L30.6862 30.6458L29.4987 31.8333ZM27.9987 24.6667V22.1667H25.4987V20.5H27.9987V18H29.6654V20.5H32.1654V22.1667H29.6654V24.6667H27.9987ZM23.4987 21.0833L19.082 16.6667C19.2904 16.5556 19.5162 16.4722 19.7595 16.4167C20.0029 16.3611 20.2493 16.3333 20.4987 16.3333C21.4154 16.3333 22.2001 16.6597 22.8529 17.3125C23.5056 17.9653 23.832 18.75 23.832 19.6667C23.832 19.9167 23.8043 20.1633 23.7487 20.4067C23.6931 20.65 23.6098 20.8756 23.4987 21.0833Z" fill="#006187"/>
        </svg>`;

const CONTENT = [
  {
    icon: CAPTURE,
    desc: "Capture more leads and grow your business with Facebook Lead Ads integrated seamlessly with tappi.",
  },
  {
    icon: ASSURE,
    desc: "Rest assured that your Facebook Business Page and customer data are protected with tappi's secure integration.",
  },
  {
    icon: CONTROL,
    desc: "Your control and flexibility matter. You have the option to disconnect your Facebook Business Page from tappi anytime you want.",
  },
];

export default function FacebookConnect() {
  const {
    connectingFacebookPage,
    setFacebookId,
    facebookId,
    showSuccessPrompt,
    connectFacebookHandler,
  } = useConnectAccount();
  return (
    <Layout
      header
      headerTitle="Connect to Facebook"
      backTo="/account/connected-accounts"
    >
      <div className="relative w-full h-screen scroll-smooth pt-20 flex flex-col justify-between">
        <Transition
          className="fixed -bottom-12 left-0 z-20 w-full"
          show={showSuccessPrompt}
          enterFrom=" translate-y-0"
          enterTo=" -translate-y-12"
          leaveFrom=" -translate-y-12"
          leaveTo=" translate-y-0"
        >
          <SuccessfulSubmitId />
        </Transition>
        <div className="relative h-screen  max-w-screen-xl container mx-auto flex flex-col justify-between items-center">
          <div className="flex flex-col justify-between items-center h-full w-full px-4">
            <div className="flex flex-col justify-between items-center space-y-[1.5rem] w-full">
              {CONTENT.map((c) => (
                <ContentCard key={c.desc} content={c} />
              ))}
            </div>
            <div className="flex flex-col justify-start items-start space-y-[0.75rem] w-full">
              <p className="text-[0.875rem] font-semibold text-[#1A1A1A]">
                Facebook Page ID
              </p>
              <LabelledInput
                name="facebook_id"
                id="facebook_id"
                label="Enter your Facebook page ID"
                onChange={(e) => setFacebookId(e.target.value)}
                value={facebookId}
              />
              <Link
                to="/account/connected-accounts/find-facebook-id"
                className="text-[#006187] text-[0.875rem] font-semibold text-right w-full"
              >
                What is Facebook Page ID?
              </Link>
            </div>
            <button
              onClick={connectFacebookHandler}
              type="button"
              className="w-full h-12 text-white thryve:text-base text-base font-semibold rounded-xl bg-primary-50 mb-5"
            >
              {connectingFacebookPage ? "Connecting..." : "Connect account"}
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
}

function ContentCard({ content }) {
  return (
    <div className="w-full flex flex-col justify-center items-center space-y-[0.62rem]">
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: content.icon }} />
      <p className="text-[#667085] font-semibold text-[0.875rem] leading-[1.25rem]">
        {content.desc}
      </p>
    </div>
  );
}

ContentCard.propTypes = {
  content: PropTypes.shape({ icon: PropTypes.string, desc: PropTypes.string })
    .isRequired,
};
