import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import { toast } from "react-hot-toast";
import { addFaceBookPage, listConnectedFacebookPages } from "../../requests-v2";

const ConnectAccountContext = createContext(null);

function useConnectAccount() {
  return useContext(ConnectAccountContext);
}

function ConnectAccountProvider({ children }) {
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
  const [connectingFacebookPage, setConnectingFacebookPage] = useState(false);
  const [showSuccessPrompt, setShowSuccessPrompt] = useState(false);
  const [connectedAccounts, setConnectedAccounts] = useState(null);
  const [facebookId, setFacebookId] = useState(null);

  // eslint-disable-next-line consistent-return
  const connectFacebookHandler = async () => {
    if (facebookId) {
      setConnectingFacebookPage(true);
      try {
        const response = await addFaceBookPage({
          facebook_page_id: facebookId,
        });
        if (response.status === 200) {
          setConnectingFacebookPage(false);
          setShowSuccessPrompt(true);
          return Promise.resolve(response);
        }
        throw new Error();
      } catch (e) {
        setConnectingFacebookPage(false);
        toast.error("An error occurred!!!");
        return Promise.reject(e);
      }
    }

    toast.error("Facebook page Id can not be empty");
  };

  useEffect(() => {
    setIsLoadingAccounts(true);
    listConnectedFacebookPages()
      .then((res) => {
        console.log("con-acc-res", res);
        setIsLoadingAccounts(false);
        setConnectedAccounts(res.data.items);
      })
      .catch((error) => {
        setIsLoadingAccounts(false);
        setConnectedAccounts(null);
        return Promise.reject(error);
      });
  }, []);

  const value = useMemo(() => ({
    isLoadingAccounts,
    connectedAccounts,
    connectingFacebookPage,
    showSuccessPrompt,
    setShowSuccessPrompt,
    setFacebookId,
    connectFacebookHandler,
    facebookId,
  }));

  return (
    <ConnectAccountContext.Provider value={value}>
      {children}
    </ConnectAccountContext.Provider>
  );
}

export { useConnectAccount, ConnectAccountProvider };

ConnectAccountProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
