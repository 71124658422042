import React from "react";
import Rating from "react-rating";
import PropTypes from "prop-types";
import { useBusinessContext } from "../../utils/Contexts/V2/BusinessContext";
import FilledStar from "../../assets/icons/filledStar.svg";
import EmptyStar from "../../assets/icons/emptyStar.svg";
import Button from "../forms/Button";
import ReviewCard from "../ReviewCard";
import NoReviewsImage from "../../assets/images/reviews-empyt-state.png";
import { useAppContext } from "../../utils/Contexts/AppContext";

function ReviewsEmptyState({ showDialogueHandler }) {
  return (
    <div className="h-full flex flex-col items-center justify-start px-8">
      <div className="px-8 pt-[2.2rem]">
        <img src={NoReviewsImage} alt="No Reviews" />
      </div>

      <div className="text-center space-y-4 mt-6">
        <h2 className="text-lg font-bold">Customer Reviews</h2>
        <p className="leading-4 text-sm font-normal px-3">
          Learn what keeps your customers happy and how you can serve them
          better.
        </p>
        <Button
          type="button"
          className="w-auto text-sm font-bold"
          size="sm"
          onClick={() => showDialogueHandler(true, "Request Review")}
        >
          Request A Review
        </Button>
      </div>
    </div>
  );
}

export default function Reviews({ showDialogueHandler }) {
  const { business } = useBusinessContext();
  const { appVendor } = useAppContext();

  return (
    <div className="h-full p-2 pb-28">
      {/* eslint-disable-next-line no-nested-ternary */}
      {business?.reviews?.business_reviews_list?.length > 0 ? (
        <>
          <div className="flex justify-between items-start">
            <div className="flex flex-col">
              <div className="flex items-baseline">
                <h2 className="text-3xl mr-1">
                  {business?.reviews?.average_rating?.toFixed(2)}
                </h2>
                <Rating
                  placeholderRating={
                    parseInt(business?.reviews?.average_rating, 10) || 0
                  }
                  emptySymbol={
                    <img src={EmptyStar} className="icon" alt="empty star" />
                  }
                  placeholderSymbol={
                    <img src={FilledStar} className="icon" alt="empty star" />
                  }
                  fullSymbol={
                    <img src={FilledStar} className="icon" alt="filled star" />
                  }
                  readonly
                />
              </div>
              <p>Average Rating</p>
            </div>

            <div className="flex flex-col">
              <Button
                type="button"
                className="w-auto"
                style={{ height: "32px", padding: "5px 10px", fontSize: 14 }}
                onClick={() => showDialogueHandler(true, "Request Review")}
              >
                Request A Review
              </Button>
              <p className="text-xs mt-1">
                {business?.reviews?.rating_total?.toFixed(1)} ratings with{" "}
                {
                  business.reviews[
                    appVendor === "tappi" ? "review_total" : "reviewTotal"
                  ]
                }{" "}
                reviews{" "}
              </p>
            </div>
          </div>

          <div className="flex flex-col space-y-2 mt-4">
            {business?.reviews?.business_reviews_list?.map((review) => (
              <ReviewCard review={review} key={review.reviewID} />
            ))}
          </div>
        </>
      ) : (
        <ReviewsEmptyState showDialogueHandler={showDialogueHandler} />
      )}
    </div>
  );
}

ReviewsEmptyState.propTypes = {
  showDialogueHandler: PropTypes.func.isRequired,
};

Reviews.propTypes = {
  showDialogueHandler: PropTypes.func.isRequired,
};
