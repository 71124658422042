/** eslint-disable import/no-unresolved */
/** eslint-disable import/extensions */
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Rating from "react-rating";
import { Transition } from "@headlessui/react";
// import { useQuery } from "@tanstack/react-query";

import Layout from "../../components/layout/V2/Layout";
import QuickActionTiles from "../../components/QuickActionTiles";
import PhotoGallery from "../../components/PhotoGrid";
// import RecentRequestItems from "../../components/RecentRequestItems";
import PhotoOption from "../../components/V2/PhotoOption";
import CoverPhoto from "../../assets/images/cover.svg";
import ProfilePhoto from "../../assets/images/profile.png";
import FilledStar from "../../assets/icons/filledStar.svg";
import EmptyStar from "../../assets/icons/emptyStar.svg";
import FallBackPhotosIllustration from "../../assets/icons/photos-empty-state-illustration.svg";
import TotalReviews from "../../assets/icons/total-reviews.svg";
import TotalReach from "../../assets/icons/total-reach.svg";
// import FallBackNoReviewsIllustration from "../../assets/icons/no-reviews-illustration.svg";
// import data from "../../sampleData.json";
import { useAuth } from "../../utils/Contexts/V2/AuthenticationContext";
import { useProductPhotosContext } from "../../utils/Contexts/V2/ProductPhotosContext";
import PhotoDeleteConfirmation from "../../components/V2/PhotoDeleteConfirmation";
import { useBusinessContext } from "../../utils/Contexts/V2/BusinessContext";

// import {
//   // getMerchantFacebookAdsData,
//   getMerchantReviews,
// } from "../../utils/requests";
// import PhoneIcon from "../../assets/icons/phone-icon.svg"

function Home() {
  const { user } = useAuth();
  const { productPhotos, showDeleteConfirmation } = useProductPhotosContext();
  const [clickedPhoto, setClickedPhoto] = useState();
  const [showPhotoOption, setShowPhotoOption] = useState(false);
  const [showDialogue, setShowDialogue] = useState(false);
  const [operation, setOperation] = useState(null);

  const { business } = useBusinessContext();

  const showDialogueHandler = (bool, type) => {
    setShowDialogue(bool);
    setOperation(type);
  };

  return (
    <Layout
      headerTitle="My Business"
      showDialogueHandler={showDialogueHandler}
      operation={operation}
      show={showDialogue}
      clickedPhoto={clickedPhoto?.photo_url}
    >
      <div className="relative overflow-y-auto min-h-screen max-w-screen-xl container mx-auto scroll-smooth py-[60px]">
        <Transition
          className="fixed -bottom-12 left-0 z-20 w-full"
          show={showDeleteConfirmation}
          enterFrom=" translate-y-0"
          enterTo=" -translate-y-12"
          leaveFrom=" -translate-y-12"
          leaveTo=" translate-y-0"
        >
          <PhotoDeleteConfirmation photo={clickedPhoto} />
        </Transition>
        <Transition
          className="fixed -bottom-12 left-0 z-20 w-full"
          show={showPhotoOption}
          enterFrom=" translate-y-0"
          enterTo=" -translate-y-12"
          leaveFrom=" -translate-y-12"
          leaveTo=" translate-y-0"
        >
          <PhotoOption
            photo={clickedPhoto}
            showPhotoOptionHandler={setShowPhotoOption}
            showDialogueHandler={showDialogueHandler}
          />
        </Transition>
        <section id="profile">
          <div className="relative w-full h-[11.2rem]">
            <div className="w-full h-[8.6rem] bg-primary-0 overflow-hidden">
              {user.cover_image_url ? (
                <img
                  src={user.cover_image_url}
                  alt="cover"
                  className="object-cover w-full"
                />
              ) : (
                <img src={CoverPhoto} alt="cover" className="object-cover" />
              )}
            </div>
            <div className="absolute left-4 bottom-px h-[5.2rem] w-[5.2rem] border-2 border-white rounded-lg overflow-hidden">
              {user.profile_photo_url ? (
                <img
                  src={user.profile_photo_url}
                  alt="profile"
                  className="object-cover"
                />
              ) : (
                <img src={ProfilePhoto} alt="cover" className="object-cover" />
              )}
            </div>
          </div>
        </section>
        <section id="business" className="p-4">
          <section id="business info">
            <div className="w-full flex flex-col justify-start items-start space-y-1">
              <h1 className="flex flex-row justify-center items-center">
                {user?.business_name} (
                {business?.reviews?.average_rating?.toFixed(1) || 0.0}
                <span className="pb-1.5">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-3 h-3"
                  >
                    <path
                      d="M8.0002 12.1666L3.10186 15.1583L4.43353 9.57498L0.0751953 5.84165L5.79603 5.38331L8.0002 0.083313L10.2044 5.38331L15.926 5.84165L11.5669 9.57498L12.8985 15.1583L8.0002 12.1666Z"
                      fill="#FEC553"
                    />
                  </svg>
                </span>
                )
              </h1>
              <p className="text-sm font-normal">{user?.state}</p>
              <div className="flex flex-row justify-center items-center">
                <Rating
                  placeholderRating={
                    Math.floor(business?.reviews?.average_rating?.toFixed(1)) ||
                    0
                  }
                  emptySymbol={
                    <img src={EmptyStar} className="icon" alt="empty star" />
                  }
                  placeholderSymbol={
                    <img src={FilledStar} className="icon" alt="empty star" />
                  }
                  fullSymbol={
                    <img src={FilledStar} className="icon" alt="filled star" />
                  }
                  readonly
                />
                <p className="text-sm font-normal mb-2">
                  {`${
                    business?.reviews?.rating_total?.toFixed(1) || 0
                  } ratings, with ${
                    business?.reviews?.review_total?.toFixed(1) || 0
                  } reviews`}
                </p>
              </div>
            </div>
          </section>
          <section id="quick action" className="py-7">
            <div className="w-full flex flex-col justify-start items-start">
              <h2>Quick Actions</h2>
              <div className="w-full py-6 bg-[#E2FFD]">
                <QuickActionTiles showDialogue={showDialogueHandler} />
              </div>
            </div>
            {user.business_description ? (
              <div className="w-full mt-10 flex flex-col justify-start items-start space-y-4 bg-white">
                <h2>About</h2>
                <p className="font-sans font-normal text-sm text-[#566370]">
                  {user.business_description}
                </p>
              </div>
            ) : (
              <div className="w-full my-10 py-3 px-4 flex flex-row justify-between items-center bg-white rounded-lg shadow-xl">
                <div className="w-[16.4rem]">
                  <h2>Complete profile setup</h2>
                  <p className="font-normal text-sm text-[#6D7789]">
                    Finish setting up your profile to let more people know about
                    your business
                  </p>
                </div>
                <div className="h-10 w-10 flex justify-center items-center rounded-full bg-[#A58EC7]">
                  <p className="text-white font-medium text-sm">10%</p>
                </div>
              </div>
            )}
          </section>
          <section id="product photo">
            <div className="w-full my-10 flex flex-col justify-start items-start space-y-4">
              <h2>Product Photos</h2>
              {productPhotos?.items?.length ? (
                <PhotoGallery
                  productPhotos={productPhotos.items}
                  photo={setClickedPhoto}
                  open={setShowPhotoOption}
                />
              ) : (
                <div className="w-full mt-4 px-4 flex flex-col space-y-2 items-center">
                  <img src={FallBackPhotosIllustration} alt="no photos" />
                  <p className="font-normal text-sm text-center ">
                    These photos are directly uploaded to your business webpage,
                    so have some fun!
                  </p>
                  <NavLink
                    to="/add-photos"
                    className="px-6 py-3 bg-primary-50 thryve:text-base font-medium text-sm text-white rounded-2xl"
                  >
                    Upload a Photo
                  </NavLink>
                </div>
              )}
            </div>
          </section>
          <section id="insights">
            <div className="w-full my-10 flex flex-col justify-start items-start">
              <div className="w-full flex flex-row justify-between items-center">
                <h2>Insights</h2>
                {/* <select className="font-medium text-xs text-[#566370]"> */}
                {/*  <option value="7">Last 7 days</option> */}
                {/* </select> */}
              </div>
              <div className="w-full my-5 flex flex-col justify-center items-center">
                <Link
                  className="w-full flex flex-row justify-center items-start space-x-2.5 border-t-[0.07rem] border-b-[0.07rem] border-[#D1D9DD] pt-3 py-2"
                  to="/customers?tab=reviews"
                >
                  <img src={TotalReviews} alt="Total Reviews" />
                  <div className="w-full flex flex-col">
                    <div className="w-full flex flex-row justify-between items-center">
                      <h3 className="font-medium text-base text-[#0D253F]">
                        {business?.reviews?.reviewotal || 0} reviews
                      </h3>
                    </div>
                    <p className="font-normal text-[0.81rem] text-[#4B5762]">
                      See what your customers are saying about your business
                    </p>
                  </div>
                </Link>
                <Link
                  className="w-full flex flex-row justify-center items-start space-x-2.5  border-b-[0.07rem] border-[#D1D9DD] py-2"
                  to="/insights"
                >
                  <img src={TotalReach} alt="Total Reach" />
                  <div className="w-full flex flex-col">
                    <div className="w-full flex flex-row justify-between items-center">
                      <h3 className="font-medium text-base text-[#0D253F]">
                        {parseInt(
                          business?.total_reach || 0,
                          10
                        ).toLocaleString()}{" "}
                        total reach
                      </h3>
                    </div>
                    <p className="font-normal text-[0.81rem] text-[#4B5762]">
                      Check out who&apos;s engaging with your Ads on Facebook
                    </p>
                  </div>
                </Link>
                {/* <Link
                  className="w-full flex flex-row justify-center items-start space-x-2.5  border-b-[0.07rem] border-[#D1D9DD] py-2"
                  to="/insights/spend"
                >
                  <img src={PhoneIcon} alt="Spend Insights" />
                  <div className="w-full flex flex-col">
                    <div className="w-full flex flex-row justify-between items-center">
                      <h3 className="font-medium text-base text-[#0D253F]">
                        Spend
                      </h3>
                    </div>
                    <p className="font-normal text-[0.81rem] text-[#4B5762]">
                      See the spending habits and behaviour of your customers
                    </p>
                  </div>
                </Link> */}
              </div>
              {/* <p className="font-normal text-primary-50 text-sm"> */}
              {/*  See more activity */}
              {/* </p> */}
            </div>
          </section>
          {/* <section id="recent requests"> */}
          {/*  <div className="w-full my-10 flex flex-col justify-start items-start"> */}
          {/*    <h2>Recent requests</h2> */}
          {/*    {user?.recent ? ( */}
          {/*      <RecentRequestItems data={data.recent_request} /> */}
          {/*    ) : ( */}
          {/*      <div className="w-full mt-8 mb-28 px-4 flex flex-col space-y-2 items-center"> */}
          {/*        <img src={FallBackNoReviewsIllustration} alt="no reviews" /> */}
          {/*        <p className="font-normal text-sm text-center "> */}
          {/*          You haven&apos;t made any payment requests yet */}
          {/*        </p> */}
          {/*      </div> */}
          {/*    )} */}
          {/*  </div> */}
          {/* </section> */}
        </section>
      </div>
    </Layout>
  );
}

export default Home;
