/* eslint-disable no-underscore-dangle */
import CryptoJS from "crypto-js";
import axios from "axios";

const TOKEN_SECRET = process.env.REACT_APP_TOKEN_SECRET;
const THRYVE_API_URL = process.env.REACT_APP_THRYVE_API_URL;
const TAPPI_API_URL = process.env.REACT_APP_TAPPI_API_URL;
const REACT_APP_VENDOR = process.env.REACT_APP_APP_VENDOR;

function encryptTokenToLocalStorage(token, name = "A") {
  const cipherText = CryptoJS.AES.encrypt(token, TOKEN_SECRET).toString();
  localStorage.setItem(name, cipherText);
}

export function decodeTokenFromLocalStorage(cipher) {
  try {
    const bytes = CryptoJS.AES.decrypt(cipher, TOKEN_SECRET);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  } catch (error) {
    return null;
  }
}

const URL_CONFIG = {
  tappi: TAPPI_API_URL,
  thryve: THRYVE_API_URL,
};

const RESOLVED_BASE_URL = URL_CONFIG[REACT_APP_VENDOR];

const customAxios = axios.create({
  baseURL: RESOLVED_BASE_URL,
  withCredentials: false,
});

customAxios.interceptors.request.use((config) => {
  // If we're already refreshing, just return the config
  // Set the Authorization header to the access token
  const token = localStorage.getItem("A");
  if (token) {
    const setup = { ...config };
    setup.headers.Authorization = `Bearer ${decodeTokenFromLocalStorage(
      token
    )}`;

    return setup;
  }

  return config;
});

customAxios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // If we get a 401, attempt to get a new token and make the same request again
    if (
      error.response?.status === 401 &&
      localStorage.getItem("R") &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      const oldRefreshToken = decodeTokenFromLocalStorage(
        localStorage.getItem("R")
      );
      const oldClientId = decodeTokenFromLocalStorage(
        localStorage.getItem("C")
      );

      try {
        const accessTokenResponse = await axios.post(
          `${RESOLVED_BASE_URL}/token/refresh`,
          null,
          {
            headers: {
              "x-refresh-token": oldRefreshToken,
              "x-client-id": oldClientId,
            },
          }
        );

        const {
          access_token: accessToken,
          refresh_token: refreshToken,
          client_id: clientId,
        } = accessTokenResponse.data;

        customAxios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

        encryptTokenToLocalStorage(accessToken);
        encryptTokenToLocalStorage(refreshToken, "R");
        encryptTokenToLocalStorage(clientId, "C");

        return customAxios(originalRequest);
      } catch (e) {
        console.error("Could not get new access token : ", e);
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.replace("/join");
        return error;
      }
    }
    return Promise.reject(error);
  }
);

const authenticationService = {
  // Login the user. Persist user data in local storage
  async login({ username, password }) {
    let clientId = null;
    if (localStorage.getItem("C")) {
      clientId = decodeTokenFromLocalStorage(localStorage.getItem("C"));
    }
    try {
      const res = await axios
        .post(
          `${RESOLVED_BASE_URL}/token`,
          { username, password, ...(clientId ? { client_id: clientId } : {}) },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => response.data);

      customAxios.defaults.headers.common.Authorization = `Bearer ${res.access_token}`;

      encryptTokenToLocalStorage(res.access_token);
      // Save the refresh token and client_id too
      encryptTokenToLocalStorage(res.refresh_token, "R");
      encryptTokenToLocalStorage(res.client_id, "C");

      const merchantId = res.merchant_id;

      localStorage.setItem("T", merchantId);

      this.updateMerchantId(merchantId);

      // Set the list of merchant accounts the user has access to
      const { merchants } = res;
      if (merchants?.items?.length > 1) {
        localStorage.setItem("merchants", JSON.stringify(merchants));
      } else {
        localStorage.setItem("merchants", null);
      }

      const userData = await customAxios
        .get(`${RESOLVED_BASE_URL}/merchants/${merchantId}`)
        .then((response) => response.data);

      const returnPayload = {
        userData,
        merchants,
      };
      return returnPayload;
    } catch (e) {
      console.error("Could not log user in : ", e);
      return Promise.reject(e);
    }
  },

  async switchAccount({ merchantId, refreshToken }) {
    let token = localStorage.getItem("A");
    if (!token) {
      return Promise.reject(Error("User is not logged in"));
    }

    if (token) {
      token = decodeTokenFromLocalStorage(token);
    }
    try {
      return axios
        .post(
          `${RESOLVED_BASE_URL}/token/merchant?merchant_id=${merchantId}`,
          null,
          {
            headers: {
              "x-refresh-token": refreshToken,
              Authorization: `Bearer ${token}`,
              "x-dymm": "DYMM",
            },
          }
        )
        .then(async (response) => {
          localStorage.clear();
          sessionStorage.clear();
          const res = response.data;
          customAxios.defaults.headers.common.Authorization = `Bearer ${res.access_token}`;

          encryptTokenToLocalStorage(res.access_token);
          // Save the refresh token and client_id too
          encryptTokenToLocalStorage(res.refresh_token, "R");
          encryptTokenToLocalStorage(res.client_id, "C");

          localStorage.setItem("T", merchantId);

          this.updateMerchantId(merchantId);

          // Set the list of merchant accounts the user has access to
          const { merchants } = res;
          if (merchants?.items?.length > 1) {
            localStorage.setItem("merchants", JSON.stringify(merchants));
          } else {
            localStorage.setItem("merchants", null);
          }

          const userData = await customAxios
            .get(`${RESOLVED_BASE_URL}/merchants/${merchantId}`)
            .then((merchantResponse) => merchantResponse.data);

          const returnPayload = {
            userData,
            merchants,
          };
          return returnPayload;
        })
        .catch((error) => {
          console.error("Could not switch merchant accounts: ", error);
          return Promise.reject(error);
        });
    } catch (error) {
      console.error("Could not switch merchant accounts: ", error);
      return Promise.reject(error);
    }
  },

  // Clear local storage to log out user
  logout() {
    // Persist the clientId
    let clientId = null;
    if (localStorage.getItem("C")) {
      clientId = decodeTokenFromLocalStorage(localStorage.getItem("C"));
    }
    localStorage.clear();
    sessionStorage.clear();
    delete customAxios.defaults.headers.common.Authorization;

    if (clientId) {
      encryptTokenToLocalStorage(clientId, "C");
    }
  },

  // Check if user is logged in and return their data
  async getCurrentUser() {
    const merchantId = localStorage.getItem("T");

    if (!merchantId) {
      return null;
    }

    if (merchantId) {
      try {
        return customAxios
          .get(`${RESOLVED_BASE_URL}/merchants/${merchantId}`)
          .then((response) => response.data);
      } catch (error) {
        return null;
      }
    }
    return null;
  },

  getRefreshToken() {
    const encryptedToken = localStorage.getItem("R");
    if (encryptedToken) {
      const token = decodeTokenFromLocalStorage(encryptedToken);
      return token;
    }
    return null;
  },

  getClientId() {
    const encryptedClientId = localStorage.getItem("C");
    if (encryptedClientId) {
      const id = decodeTokenFromLocalStorage(encryptedClientId);
      return id;
    }
    return null;
  },

  // Register a new user
  async register(userData) {
    try {
      const res = axios.post(`${RESOLVED_BASE_URL}/merchants`, userData);
      console.log(res);
      return res;
    } catch (e) {
      console.error("Could not register user : ", e);
      return Promise.reject(e);
    }
  },

  // Update merchantId in localStorage
  async updateMerchantId(userId) {
    localStorage.setItem("T", userId);
  },
};

export { customAxios, RESOLVED_BASE_URL };

export default authenticationService;
