import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  getMerchantProductPhotos,
  createMerchantProductPhotos,
  deleteMerchantProductPhotos,
} from "../../requests-v2";
import { useAuth } from "./AuthenticationContext";

const ProductPhotosContext = createContext(null);

function useProductPhotosContext() {
  return useContext(ProductPhotosContext);
}

// The ProductPhotosProvider is used to store the business context, eg countryCode, name, logo, etc
function ProductPhotosProvider({ children }) {
  const { isAuthenticated, merchantId } = useAuth();

  const [productPhotos, setProductPhotos] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [businessPhoto, setBusinessPhoto] = useState(null);
  const [title, setTitle] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showUploadConfirmation, setShowUploadConfirmation] = useState(false);

  const updateProductPhotos = (data) => {
    setProductPhotos(data);
  };

  const query = new URLSearchParams({ page: 1, size: 24 });

  const queryClient = useQueryClient();

  const { data: products } = useQuery(
    ["/product-photos", query.toString(), merchantId],
    () => getMerchantProductPhotos({ query }),
    { enabled: !!isAuthenticated }
  );

  const { mutateAsync: mutateCreateProductPhoto } = useMutation(
    (data) => createMerchantProductPhotos({ data }),
    {
      onSuccess: async (res) => {
        queryClient.invalidateQueries({
          queryKey: ["/product-photos"],
          type: "all",
        });
        setIsLoading(false);
        setShowUploadConfirmation(true);
        setBusinessPhoto(null);
        Promise.resolve(res);
      },
      onError: (error) => {
        setIsLoading(false);
        Promise.reject(error);
      },
    }
  );

  // Get merchant photos ... This should only run if we have user data
  useEffect(() => {
    updateProductPhotos(products);
  }, [products, merchantId]);

  const showDeleteConfirmationHandler = () => {
    setShowDeleteConfirmation(true);
  };

  const hideDeleteConfirmationHandler = () => {
    setShowDeleteConfirmation(false);
  };

  const { mutateAsync: mutateDeleteProductPhoto } = useMutation(
    (id) => deleteMerchantProductPhotos({ id }),
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries({
          queryKey: ["/product-photos"],
          type: "all",
        });
        setIsLoading(false);
        hideDeleteConfirmationHandler();
        Promise.resolve(res);
      },
      onError: (error) => {
        setIsLoading(false);
        Promise.reject(error);
      },
    }
  );

  const uploadProductPhoto = async () => {
    if (businessPhoto && title) {
      setIsLoading(true);

      await mutateCreateProductPhoto({
        images: businessPhoto,
        product_name: title,
      });
    } else {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 4000);
    }
  };

  const deleteProductPhoto = async (photoId) => {
    setIsLoading(true);

    await mutateDeleteProductPhoto(photoId);
  };

  const value = useMemo(() => ({
    updateProductPhotos,
    productPhotos,
    isLoading,
    deleteProductPhoto,
    showDeleteConfirmationHandler,
    hideDeleteConfirmationHandler,
    showDeleteConfirmation,
    uploadProductPhoto,
    setTitle,
    setBusinessPhoto,
    businessPhoto,
    showError,
    showUploadConfirmation,
    setShowUploadConfirmation,
  }));

  return (
    <ProductPhotosContext.Provider value={value}>
      {children}
    </ProductPhotosContext.Provider>
  );
}

export { useProductPhotosContext, ProductPhotosProvider };

ProductPhotosProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
