import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import CustomPhoneInput from "../PhoneInput";
import { validatePhoneNumber } from "../../utils/validators";
import Button from "../forms/Button";
import { getMerchantOTP } from "../../utils/requests-v2";
import { useAppContext } from "../../utils/Contexts/AppContext";
import { useAuth } from "../../utils/Contexts/V2/AuthenticationContext";

export default function LoginForm({
  phoneNumber,
  setPhoneNumber,
  updateCountryCode,
}) {
  const navigate = useNavigate();
  const appContext = useAppContext();

  const [phoneInputErrors, setPhoneInputErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { updateMerchantId } = useAuth();

  async function handleSubmit(event) {
    event.preventDefault();

    if (!phoneNumber) {
      setPhoneInputErrors({ phoneNumber: "Phone number is required" });
      return;
    }

    if (!validatePhoneNumber(phoneNumber, appContext.country)) {
      setPhoneInputErrors({ phoneNumber: "Invalid phone number" });
      return;
    }

    try {
      setIsLoading(true);
      const res = await getMerchantOTP({ phoneNumber: phoneNumber.slice(1) });

      updateMerchantId(res.merchant_id);

      setIsLoading(false);
      navigate("/join/otp");
    } catch (error) {
      setPhoneInputErrors({
        phoneNumber: error?.response?.data?.detail || "Merchant not found",
      });
      setIsLoading(false);
    }
  }

  return (
    <div className="flex h-full w-full flex-col items-center justify-start space-y-[3rem] lg:p-[2rem]">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col lg:h-[40%] h-full w-full lg:w-[60%] mt-[4rem] space-y-10"
      >
        <div className="flex w-full flex-col items-center justify-center space-y-[1.25rem]">
          <p className="text-[1.5rem] font-extrabold text-[#000021]">
            Log in to your account
          </p>
          <p className="font-graphik-regular text-[1.125rem] text-[#000021] text-center">
            Enter your registered phone number to continue.
          </p>
        </div>

        <div className="mb-6 grow">
          <CustomPhoneInput
            errors={phoneInputErrors}
            setErrors={setPhoneInputErrors}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            selectedCountryCode={appContext.country}
            setSelectedCountryCode={updateCountryCode}
            countries={appContext.SUPPORTED_COUNTRIES}
            className="md"
          />
        </div>

        <div className="text-center lg:text-left">
          <Button type="submit" isLoading={isLoading} disabled={isLoading}>
            Login
          </Button>
        </div>
      </form>
    </div>
  );
}

LoginForm.propTypes = {
  phoneNumber: PropTypes.string,
  setPhoneNumber: PropTypes.func.isRequired,
  updateCountryCode: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {
  phoneNumber: "",
};
