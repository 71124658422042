import React from "react";
import { useNavigate } from "react-router-dom";
import { useConnectAccount } from "../../../utils/Contexts/V2/ConnectAccountContext";

function SuccessfulSubmitId() {
  const navigate = useNavigate();

  const { setShowSuccessPrompt } = useConnectAccount();

  return (
    <div className="relative w-full">
      <div className="relative -bottom-16  h-screen flex bg-blur" />
      <div className="relative bottom-0 flex flex-col justify-between items-center space-y-5 w-full h-[27rem] px-4 pt-[1.7rem] pb-6 bg-white rounded-t-2xl overflow-hidden">
        <div className="text-center flex flex-col space-y-4">
          <p className="text-base font-semibold">Submitted!</p>
          <p className="text-base font-normal">
            Your Facebook page ID has been submitted successfully. An email will
            be sent to you soon.
          </p>
        </div>
        <div className="flex flex-col space-y-3 w-full">
          <button
            type="button"
            className="w-full  h-12 text-white text-base font-semibold rounded-xl bg-primary-50 thryve:text-base"
            onClick={() => {
              setShowSuccessPrompt(false);
              navigate("/account/connected-accounts");
            }}
          >
            Great
          </button>
        </div>
      </div>
    </div>
  );
}

export default SuccessfulSubmitId;
